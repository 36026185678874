import React, { useState } from "react";
import axios from "axios";
import Axios from "axios";

import { Switch, Route, useHistory } from "react-router-dom";
import MainCropper from "./Cropper/MainCropper";
import HomeAnalyzer from "../Home/HomeAnalyzer";
import { useEffect } from "react";

export default function VideoVerificator(props) {
  const history = useHistory();
  const [timer, setTimer] = useState(0);
  const [payment, setPayment] = useState({});
  const handleTimer = (number) => {
    setTimer(number);
  };

  const getAuthToken = async () => {
    const authToken = await props.checkLoggedIn();
    if (!authToken) {
      props.history.push("/");
      return;
    }
    return authToken;
  };

  useEffect(() => {
    getPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    infoToPost,
    framesToPost,
    videoId,
    infractionId
  ) => {
    infoToPost = { ...infoToPost, timer };
    const authToken = await getAuthToken();
    const headers = {
      "auth-token": authToken,
    };
    axios
      .post(
        `/api/user/video/${videoId}/approval/${infractionId}`,
        { approval: "imputable", videoInfo: infoToPost },
        { headers: headers }
      )
      .then(async (res) => {
        let iiD = res.data.infractionId;

        const data = new FormData();
        for (var x = 0; x < framesToPost.length; x++) {
          const res = await fetch(framesToPost[x]);
          data.append("images", await res.blob(), `0${x + 1}`);
        }
        axios
          .post(`/api/user/video/${videoId}/infraction/${iiD}/frames`, data, {
            headers: headers,
          })
          .then((res) => {
            setTimer(0);
          });
      })
      .catch((err) => {
        if (err.response.status === 408) {
          // If video timed out
          history.push("/home");
        }
      });
  };

  const handleNoInfraction = async (videoId, infractionId) => {
    const authToken = await getAuthToken();
    const headers = {
      "auth-token": authToken,
    };
    await axios
      .post(
        `/api/user/video/${videoId}/approval/${infractionId}`,
        { approval: "no-infraction" },
        {
          headers: headers,
        }
      )
      .then((res) => {
        // addOneToNoInfraction();
        setTimer(0);
      })
      .catch((err) => {
        setTimer(0);
      });
  };

  const handleDiscard = async (infoToPost, videoId, infractionId) => {
    infoToPost = { ...infoToPost, timer };

    const authToken = await getAuthToken();
    const headers = {
      "auth-token": authToken,
    };
    const data = { ...infoToPost, approval: "non-imputable" };
    await axios
      .post(`/api/user/video/${videoId}/approval/${infractionId}`, data, {
        headers: headers,
      })
      .then((res) => {
        //  addOneToInimputableInfractions();
        setTimer(0);
      });
  };

  const addOneToAprovedInfractions = () => {
    refreshLabel("cantAprobadas");
    refreshPayment(payment.approval);
    refreshVideoAmount();
  };

  const addOneToInimputableInfractions = () => {
    refreshLabel("cantInimputables");
    refreshPayment(payment.noImputable);
    refreshVideoAmount();
  };

  const addOneToNoInfraction = () => {
    refreshLabel("cantDescartadas");
    refreshPayment(payment.noInfraction);
    refreshVideoAmount();
  };

  const refreshLabel = (id) => {
    let ca = document.getElementById(id).innerText;
    ca = parseInt(ca) + 1;
    document.getElementById(id).innerText = ca;
  };

  const refreshPayment = (amount) => {
    let cd = document.getElementById("plataGanada").innerText;
    cd = parseInt(cd) + amount;
    document.getElementById("plataGanada").innerText = cd;
  };

  const refreshVideoAmount = () => {
    let cd = document.getElementById("cantVideos").innerText;
    cd = parseInt(cd) - 1;
    document.getElementById("cantVideos").innerText = cd;
  };

  async function getPayment() {
    const authToken = await getAuthToken();
    await Axios.get("/api/payment", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setPayment({
        approval: response.data.amounts[0].amountPerApproval,
        noInfraction: response.data.amounts[0].amountPerDisapproval,
        noImputable: response.data.amounts[0].amountPerDiscard,
      });
    });
  }

  return (
    <Switch>
      <Route
        exact
        path={"/verificacion"}
        render={() => (
          <MainCropper
            {...props}
            setTimer={handleTimer}
            userData={props.userData}
            initialTime={timer}
            checkLoggedIn={props.checkLoggedIn}
            handleNoInfraction={handleNoInfraction}
            handleDiscard={handleDiscard}
            handleSubmit={handleSubmit}
            usesExternalURLs={false}
          />
        )}
      />
      <Route
        exact
        path={"/verificacion"}
        render={() => (
          <MainCropper
            {...props}
            setTimer={handleTimer}
            userData={props.userData}
            initialTime={timer}
            checkLoggedIn={props.checkLoggedIn}
            handleNoInfraction={handleNoInfraction}
            handleDiscard={handleDiscard}
            handleSubmit={handleSubmit}
            usesExternalURLs={false}
          />
        )}
      />
    </Switch>
  );
}
