import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Reports.scss";
import MaterialTable from "material-table";
import { Button, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Icon } from "@material-ui/core";
import Moment from "moment";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Dropdown, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import REPTable from "./REPTables";
import ResumenTable from "./REPTables/ResumenTable";
export default function ReportsAdmin(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResumen, setIsLoadingResumen] = useState(false);
  const [isLoadingProcesados, setIsLoadingProcesados] = useState(false);
  const [isLoadingProduccion, setIsLoadingProduccion] = useState(false);
  const [isLoadingInteligencia, setIsLoadingInteligencia] = useState(false);
  const [isLoadingEdicion, setIsLoadingEdicion] = useState(false);
  const [isLoadingEnriquecimiento, setIsLoadingEnriquecimiento] = useState(false);

  const { checkLoggedIn } = props;
  const [data, setData] = useState([]);
  const [errorDate, setErrorDate] = useState(false);
  const [verPendientesPorcentajes, setVerPendientesPorcentajes] = useState(false);
  const [verPendientesPorMunicipio, setVerPendientesPorMunicipio] = useState(false);
  const [verPendientesPorAnioMes, setVerPendientesPorAnioMes] = useState(false);
  const [verPendientesPorLomas, setVerPendientesPorLomas] = useState(false);
  const [verProcesadosPorcentajes, setVerProcesadosPorcentajes] = useState(false);
  const [verProcesadosPorMunicipio, setVerProcesadosPorMunicipio] = useState(false);
  const [verProcesadosPorAnioMes, setVerProcesadosPorAnioMes] = useState(false);
  const [verProcesadosPorLomas, setVerProcesadosPorLomas] = useState(false);
  const [verProdPorcentajes, setVerProdPorcentajes] = useState(false);
  const [verProdPorMunicipio, setVerProdPorMunicipio] = useState(false);
  const [verProdPorTipoEquipo, setVerProdPorTipoEquipo] = useState(false);
  const [verProdPorLomas, setVerProdPorLomas] = useState(false);
  const [verProdPorAnioMes, setVerProdPorAnioMes] = useState(false);
  const [verInteligenciaPorcentajes, setVerInteligenciaPorcentajes] = useState(false);
  const [verInteligenciaPorMunicipio, setVerInteligenciaPorMunicipio] = useState(false);
  const [verInteligenciaPorTipoEquipo, setVerInteligenciaPorTipoEquipo] = useState(false);
  const [verInteligenciaPorLomas, setVerInteligenciaPorLomas] = useState(false);
  const [verInteligenciaPorAnioMes, setVerInteligenciaPorAnioMes] = useState(false);
  const [verEdicionPorcentajes, setVerEdicionPorcentajes] = useState(false);
  const [verEdicionPorMunicipio, setVerEdicionPorMunicipio] = useState(false);
  const [verEdicionPorLomas, setVerEdicionPorLomas] = useState(false);
  const [verEdicionPorAnioMes, setVerEdicionPorAnioMes] = useState(false);
  const [verEnriquecimientoPorcentajes, setVerEnriquecimientoPorcentajes] = useState(false);
  const [verEnriquecimientoPorMunicipio, setVerEnriquecimientoPorMunicipio] = useState(false);
  const [verEnriquecimientoPorTipoEquipo, setVerEnriquecimientoPorTipoEquipo] = useState(false);
  const [verEnriquecimientoPorLomas, setVerEnriquecimientoPorLomas] = useState(false);
  const [verEnriquecimientoPorAnioMes, setVerEnriquecimientoPorAnioMes] = useState(false);
  const [esPrimeraCarga, setEsPrimeraCarga] = useState(true);

  const dateFromBiggerThanDateTo = () => {
    var isBigger = false;
    const dt = new Date(date.dateTo);
    const df = new Date(date.dateFrom);
    if (df > dt) {
      isBigger = true;
    }
    return isBigger;
  };

  const history = useHistory();

  const [date, setDate] = useState({
    dateFrom: "2019-01-01",
    dateTo: Moment(Date.now()).format("YYYY-MM-DD"),
  });

  const handleSubmit = (event) => {
    setErrorDate(false);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();

    if (dateFromBiggerThanDateTo()) {
      event.preventDefault();
      event.stopPropagation();
      setErrorDate(true);
      return;
    }
    getData();
    getDataProduccion();
    getDataInteligencia();
    getDataEdicion();
    getDataEnriquecimiento();
  };

  const handleChange = (event) => {
    setDate({
      ...date,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (!esPrimeraCarga){
      getDataPendientes();
    }
  }, [verPendientesPorAnioMes, verPendientesPorLomas, verPendientesPorMunicipio]);

  useEffect(() => {
    if (!esPrimeraCarga){
      getDataProcesados();
    }
  }, [verProcesadosPorAnioMes, verProcesadosPorLomas, verProcesadosPorMunicipio]);

  useEffect(() => {
    if (!esPrimeraCarga) {
      getDataProduccion();
    }
  }, [verProdPorMunicipio,verProdPorTipoEquipo,verProdPorLomas,verProdPorAnioMes]);

  useEffect(() => {
    if (!esPrimeraCarga) {
      getDataInteligencia();
    }
  },[verInteligenciaPorMunicipio,verInteligenciaPorTipoEquipo,verInteligenciaPorLomas,verInteligenciaPorAnioMes]);

  useEffect(() => {
    if (!esPrimeraCarga) {
      getDataEdicion();
    }
  }, [verEdicionPorMunicipio,verEdicionPorLomas,verEdicionPorAnioMes]);

  useEffect(() => {
    if (!esPrimeraCarga) {
      getDataEnriquecimiento();
    }
  }, [verEnriquecimientoPorLomas,verEnriquecimientoPorAnioMes, verEnriquecimientoPorMunicipio]);

  const handleProdPorMunicipio = (event) => {
    /*if (document.getElementById("chkProduccionLomas").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkProduccionLomas").checked = !event.target.checked;
    }*/
    setVerProdPorMunicipio(event.target.checked);
  }

  const handleProdLomas = (event) => {
    /*if (document.getElementById("chkProduccionPorMunicipio").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkProduccionPorMunicipio").checked = !event.target.checked;
    }*/
    setVerProdPorLomas(event.target.checked);
  }

  const handleProdAnioMes = (event) => {
    setVerProdPorAnioMes(event.target.checked);
  }

  const handleInteligenciaPorMunicipio = (event) => {
    /*if (document.getElementById("chkInteligenciaLomas").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkInteligenciaLomas").checked = !event.target.checked;
    }*/
    setVerInteligenciaPorMunicipio(event.target.checked);
  }

  const handleInteligenciaPorLomas = (event) => {
    /*if (document.getElementById("chkInteligenciaPorMunicipio").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkInteligenciaPorMunicipio").checked = !event.target.checked;
    }*/
    setVerInteligenciaPorLomas(event.target.checked);
  }

  const handleInteligenciaPorAnioMes = (event) => {
    setVerInteligenciaPorAnioMes(event.target.checked);
  }

  const handleEdicionPorMunicipio = (event) => {
    /*if (document.getElementById("chkEdicionLomas").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkEdicionLomas").checked = !event.target.checked;
    }*/
    setVerEdicionPorMunicipio(event.target.checked);
  }

  const handleEdicionLomas = (event) => {
    /*if (document.getElementById("chkEdicionPorMunicipio").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkEdicionPorMunicipio").checked = !event.target.checked;
    }*/
    setVerEdicionPorLomas(event.target.checked);
  }

  const handleEdicionPorAnioMes = (event) => {
    setVerEdicionPorAnioMes(event.target.checked);
  }

  const handleEnriquecimientoLomas = (event) => {
    /*if (document.getElementById("chkEnriquecimientoPorMunicipio").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkEnriquecimientoPorMunicipio").checked = !event.target.checked;
    }*/
    setVerEnriquecimientoPorLomas(event.target.checked);
  }

  const handleEnriquecimientoPorAnioMes = (event) => {
    setVerEnriquecimientoPorAnioMes(event.target.checked);
  }

  const handleEnriquecimientoPorMunicipio = (event) => {
    /*if (document.getElementById("chkEnriquecimientoLomas").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkEnriquecimientoLomas").checked = !event.target.checked;
    }*/
    setVerEnriquecimientoPorMunicipio(event.target.checked);
  }

  const handleChangeEdicionPorcentajes = (event) => {
    setVerEdicionPorcentajes(event.target.checked);
  }

  const handleChangePendientesPorcentajes = (event) => {
    setVerPendientesPorcentajes(event.target.checked);
  }

  const handlePendientesPorMunicipio = (event) => {
    /*document.getElementById("chkPendientesPorcentajes").disabled = true;
    document.getElementById("chkPendientesLomas").disabled = true;
    document.getElementById("chkPendientesAnioMes").disabled = true;
    if (document.getElementById("chkPendientesLomas").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkPendientesLomas").checked = !event.target.checked;
    }*/
    setVerPendientesPorMunicipio(event.target.checked);
  }

  const handlePendientesPorAnioMes = (event) => {
    setVerPendientesPorAnioMes(event.target.checked);
  }

  const handlePendientesPorLomas = (event) => {
    /*if (document.getElementById("chkPendientesPorMunicipio").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkPendientesPorMunicipio").checked = !event.target.checked;
    }*/
    setVerPendientesPorLomas(event.target.checked);
  }

  const handleChangeProcesadosPorcentajes = (event) => {
    setVerProcesadosPorcentajes(event.target.checked);
  }

  const handleProcesadosPorMunicipio = (event) => {
    /*if (document.getElementById("chkProcesadosLomas").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkProcesadosLomas").checked = !event.target.checked;
    }*/
    setVerProcesadosPorMunicipio(event.target.checked);
  }

  const handleProcesadosPorAnioMes = (event) => {
    setVerProcesadosPorAnioMes(event.target.checked);
  }

  const handleProcesadosPorLomas = (event) => {
    /*if (document.getElementById("chkProcesadosPorMunicipio").checked == true) {
      setVerProdPorLomas(!event.target.checked);
      document.getElementById("chkProcesadosPorMunicipio").checked = !event.target.checked;
    }*/
    setVerProcesadosPorLomas(event.target.checked);
  }

  const handleChangeProdPorcentajes = (event) => {
    setVerProdPorcentajes(event.target.checked)
  }

  const handleChangeInteligenciaPorcentajes = (event) => {
    setVerInteligenciaPorcentajes(event.target.checked);
  }

  const handleChangeEnriquecimientoPorcentajes = (event) => {
    setVerEnriquecimientoPorcentajes(event.target.checked);
  }

  function columnasPendientes(){
    var arr = [];
    arr.push({
      title: "Tipo de equipo",
      field: "TipoEquipo",
      filtering: true,
    });
    if (verPendientesPorMunicipio || verPendientesPorLomas) {
      arr.push({
        title: "Municipalidad",
        field: "Municipio",
        filtering: true,
      });
    }
    if (verPendientesPorAnioMes) {
      arr.push({
        title: "Año",
        field: "Anio",
      });
      arr.push({
        title: "Mes",
        field: "Mes",
      });  
    }
    arr.push({
      title: "Material Sin Procesar (IA)",
      field: "MaterialSinProcesar",
    });
    if (verPendientesPorcentajes) {
      arr.push({
        title: "En proceso (IA) [%]",
        field: "EnProcesoIAPorc",
      });
      arr.push({
        title: "Pendiente de Edicion [%]",
        field: "PendienteEdicionPorc",
      });
      arr.push({
        title: "Infracciones para Enviar [%]",
        field: "InfraccionesEnviarPorc",
      });
    }
    if (!verPendientesPorcentajes) {
      arr.push({
        title: "En Proceso (IA)",
        field: "EnProcesoARGIA",
      });
      arr.push({
        title: "Pendiente de Edición",
        field: "PendienteDeEdicion",
      });
      arr.push({
        title: "Presunciones para Enviar",
        field: "InfraccionesParaEnviar",
      });
    }
    return arr;
  }

  function columnasProcesados(){
    var arr = [];
    arr.push({
      title: "Tipo de equipo",
      field: "TipoEquipo",
      filtering: true,
    });
    if ((verProcesadosPorcentajes && (verProcesadosPorMunicipio || verProcesadosPorLomas)) ||
      !verProcesadosPorcentajes && (verProcesadosPorMunicipio || verProcesadosPorLomas) ||
      !verProcesadosPorcentajes && (verProcesadosPorMunicipio || verProcesadosPorLomas)) {
      arr.push({
        title: "Municipalidad",
        field: "Municipio",
        filtering: true,
      });
    }
    if (verProcesadosPorAnioMes) {
      arr.push({
        title: "Año",
        field: "Anio",
      });
      arr.push({
        title: "Mes",
        field: "Mes",
      });  
    }
    arr.push({
      title: "Registrado",
      field: "Registrados",
    });
    if (verProcesadosPorcentajes) {
      arr.push({
        title: "Entregado a IA [%]",
        field: "EntregadoIAPorc",
      });
      arr.push({
        title: "Descartados IA [%]",
        field: "DescartadosIAPorc",
      });
      arr.push({
        title: "Aprobados por IA [%]",
        field: "AprobadosIAPorc",
      });
      arr.push({
        title: "Editados [%]",
        field: "EditadosPorc",
      });
      arr.push({
        title: "Enviado EMP SAT [%]",
        field: "EnviadosEMPSATPorc",
      });
    }
    if (!verProcesadosPorcentajes) {
      arr.push({
        title: "Entregado a IA",
        field: "EntregadoIA",
      });
      arr.push({
        title: "Descartados IA",
        field: "DescartadosARGIA",
      });
      arr.push({
        title: "Aprobados por IA",
        field: "AprobadosARGIA",
      });
      arr.push({
        title: "Editados",
        field: "Editados",
      });
      arr.push({
        title: "Enviado EMP SAT",
        field: "EnviadoSPI",
      });
    }
    return arr;
  }

  function columnasProduccion(){
    var arr = [];
    arr.push({
      title: "Tipo de Equipo",
      field: "TipoEquipo",
      filtering: true,
    });
    if (verProdPorMunicipio || verProdPorLomas) {
      arr.push({
        title: "Municipalidad",
        field: "Municipio",
        filtering: true,
      });
    }
    if (verProdPorAnioMes) {
      arr.push({
        title: "Año",
        field: "Anio",
      });
      arr.push({
        title: "Mes",
        field: "Mes",
      });  
    }
    arr.push({
      title: "Material Registrado",
      field: "Registrados",
    });
    if (verProdPorcentajes) {
      arr.push({
        title: "Presunciones [%]",
        field: "PresuncionesEncontradasPorc",
      });
      arr.push({
        title: "Presunciones Imputables [%]",
        field: "ImputablesPorc",
      });
    }
    if (!verProdPorcentajes) {
      arr.push({
        title: "Presunciones",
        field: "PresuncionesEncontradas",
      });
      arr.push({
        title: "Presunciones Imputables",
        field: "Imputables",
      });
    }
    return arr;
  }

  function columnasInteligencia(){
    var arr = [];
    arr.push({
      title: "Tipo de equipo",
      field: "TipoEquipo",
      filtering: true,
    });
    if (verInteligenciaPorMunicipio || verInteligenciaPorLomas) {
      arr.push({
        title: "Municipalidad",
        field: "Municipio",
        filtering: true,
      });
    }
    if (verInteligenciaPorAnioMes) {
      arr.push({
        title: "Año",
        field: "Anio",
      });
      arr.push({
        title: "Mes",
        field: "Mes",
      });  
    }
    arr.push({
      title: "Material Registrado",
      field: "Registrados",
    });
    if (verInteligenciaPorcentajes) {
      arr.push({
        title: "Entregado IA [%]",
        field: "EntregadoIAPorc",
      });
      arr.push({
        title: "Cantidad Procesada IA [%]",
        field: "CantidadProcesadosIAPorc",
      });
      arr.push({
        title: "Aprobados IA [%]",
        field: "AprobadosIAPorc",
      });
    }
    if (!verInteligenciaPorcentajes) {
      arr.push({
        title: "Entregado a IA",
        field: "EntregadoIA",
      });
      arr.push({
        title: "Cantidad procesada IA",
        field: "ProcesadoIA",
      });
      arr.push({
        title: "Aprobados IA",
        field: "AprobadosARGIA",
      });
    }
    return arr;
  }

  function columnasEdicion(){
    var arr = [];
    arr.push({
      title: "Tipo de equipo",
      field: "TipoEquipo",
      filtering: true,
    });
    if (verEdicionPorMunicipio || verEdicionPorLomas) {
      arr.push({
        title: "Municipalidad",
        field: "Municipio",
        filtering: true,
      });
    }
    if (verEdicionPorAnioMes) {
      arr.push({
        title: "Año",
        field: "Anio",
      });
      arr.push({
        title: "Mes",
        field: "Mes",
      });  
    }
    arr.push({
      title: "Stock de Edición",
      field: "AprobadosARGIA",
    });
    if (verEdicionPorcentajes) {
      arr.push({
        title: "Pendiente de Edicion [%]",
        field: "PendientesEdicionPorc",
      });
      arr.push({
        title: "Imputables [%]",
        field: "ImputablesPorc",
      });
      arr.push({
        title: "No Imputables [%]",
        field: "NoImputablesPorc",
      });
      arr.push({
        title: "Descartados [%]",
        field: "DescartadosPorc",
      });
    }
    if (!verEdicionPorcentajes) {
      arr.push({
        title: "Pendiente de Edición",
        field: "PendienteDeEdicion",
      });
      arr.push({
        title: "Imputables",
        field: "Imputables",
      });
      arr.push({
        title: "No Imputables",
        field: "NoImputables",
      });
      arr.push({
        title: "Descartados",
        field: "Descartado",
      });
    }
    return arr;
  }

  function columnasEnriquecimiento(){
    var arr = [];
    arr.push({
      title: "Tipo de equipo",
      field: "TipoEquipo",
      filtering: true,
    });
    if (verEnriquecimientoPorMunicipio || verEnriquecimientoPorLomas) {
      arr.push({
        title: "Municipalidad",
        field: "Municipio",
        filtering: true,
      });
    }
    if (verEnriquecimientoPorAnioMes) {
      arr.push({
        title: "Año",
        field: "Anio",
      });
      arr.push({
        title: "Mes",
        field: "Mes",
      });  
    }
    arr.push({
      title: "Presunciones Aprobadas por Edición",
      field: "InfraccionesAprobadas",
    });
    if (verEnriquecimientoPorcentajes) {
      arr.push({
        title: "Para enviar EMP SAT [%]",
        field: "ParaEnviarEMPSATPorc",
      });
      arr.push({
        title: "Enviado EMP SAT [%]",
        field: "EnviadoEMPSATPorc",
      });
      arr.push({
        title: "Aprobados EMP SAT [%]",
        field: "AprobadosEMPSATPorc",
      });
      arr.push({
        title: "Aprobados SACIT [%]",
        field: "AprobadosSACITPorc",
      });
      arr.push({
        title: "Aprobados LOMAS [%]",
        field: "AprobadosLOMASPorc",
      });
    }
    if (!verEnriquecimientoPorcentajes) {
      arr.push({
        title: "Para enviar EMP SAT",
        field: "EnviarEMPSAT",
      });
      arr.push({
        title: "Enviado EMP SAT",
        field: "EnviadoEMPSAT",
      });
      arr.push({
        title: "Aprobados EMP SAT",
        field: "AprobadosEMPSAT",
      });
      arr.push({
        title: "Aprobados SACIT",
        field: "AprobadoSACIT",
      });
      arr.push({
        title: "Aprobados Lomas",
        field: "AprobadoLOMAS",
      });
    }
    return arr;
  }

  const getData = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    setIsLoading(true);
    await Axios.get("/api/statistics/reports/allEquipments", {
      headers: { "auth-token": authToken },
      params: {
        fechaDesde: date.dateFrom,
        fechaHasta: date.dateTo,
        chkPendientesPorAnioMes: verPendientesPorAnioMes,
        chkPendientesPorLomas: verPendientesPorLomas,
        chkPendientesPorMunicipio: verPendientesPorMunicipio,
        chkProcesadosPorAnioMes: verProcesadosPorAnioMes,
        chkProcesadosPorLomas: verProcesadosPorLomas,
        chkProcesadosPorMunicipio: verProcesadosPorMunicipio,
      },
    }).then((response) => {
      console.log(response);
      var datatmp = data;
      datatmp.resumen = response.data.resumen;
      datatmp.procesados = response.data.procesados;
      setData(datatmp);
      setIsLoading(false)
      setIsLoadingResumen(false);
      setIsLoadingProcesados(false);
      setIsLoadingEnriquecimiento(false);
      if (esPrimeraCarga && !isLoadingResumen && !isLoadingProcesados && !isLoadingProduccion && !isLoadingInteligencia && !isLoadingEdicion && !isLoadingEnriquecimiento) {
        setEsPrimeraCarga(false);
      }
    });
  };

  const getDataPendientes = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    setIsLoadingResumen(true);
    await Axios.get("/api/statistics/reports/reportePendientes", {
      headers: { "auth-token": authToken },
      params: {
        fechaDesde: date.dateFrom,
        fechaHasta: date.dateTo,
        chkPendientesPorMunicipio: verPendientesPorMunicipio,
        chkPendientesPorLomas: verPendientesPorLomas,
        chkPendientesPorAnioMes: verPendientesPorAnioMes,
      },
    }).then((response) => {
      console.log(response);
      var datatmp = data;
      datatmp.pendientes = response.data.pendientes;
      setData(datatmp);
      setIsLoadingResumen(false);
      /*document.getElementById("chkPendientesPorcentajes").disabled = false;
      document.getElementById("chkPendientesLomas").disabled = false;
      document.getElementById("chkPendientesAnioMes").disabled = false;
      document.getElementById("chkPendientesPorMunicipio").disabled = false;*/
      if (esPrimeraCarga && !isLoadingResumen && !isLoadingProcesados && !isLoadingProduccion && !isLoadingInteligencia && !isLoadingEdicion && !isLoadingEnriquecimiento) {
        setEsPrimeraCarga(false);
      }
    });
  };

  const getDataProcesados = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    setIsLoadingProcesados(true);
    await Axios.get("/api/statistics/reports/reporteProcesados", {
      headers: { "auth-token": authToken },
      params: {
        fechaDesde: date.dateFrom,
        fechaHasta: date.dateTo,
        chkProcesadosPorMunicipio: verProcesadosPorMunicipio,
        chkProcesadosPorLomas: verProcesadosPorLomas,
        chkProcesadosPorAnioMes: verProcesadosPorAnioMes,
      },
    }).then((response) => {
      console.log(response);
      var datatmp = data;
      datatmp.procesados = response.data.procesados;
      setData(datatmp);
      setIsLoadingProcesados(false);
      if (esPrimeraCarga && !isLoadingResumen && !isLoadingProcesados && !isLoadingProduccion && !isLoadingInteligencia && !isLoadingEdicion && !isLoadingEnriquecimiento) {
        setEsPrimeraCarga(false);
      }
    });
  };

  const getDataProduccion = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    setIsLoadingProduccion(true);
    await Axios.get("/api/statistics/reports/reporteProduccion", {
      headers: { "auth-token": authToken },
      params: {
        fechaDesde: date.dateFrom,
        fechaHasta: date.dateTo,
        chkProduccionPorMunicipio: verProdPorMunicipio,
        chkProduccionPorTipoEquipo: verProdPorTipoEquipo,
        chkProduccionPorLomas: verProdPorLomas,
        chkProduccionPorAnioMes: verProdPorAnioMes,
      },
    }).then((response) => {
      console.log(response);
      var datatmp = data;
      datatmp.produccion = response.data.produccion;
      setData(datatmp);
      setIsLoadingProduccion(false);
      if (esPrimeraCarga && !isLoadingResumen && !isLoadingProcesados && !isLoadingProduccion && !isLoadingInteligencia && !isLoadingEdicion && !isLoadingEnriquecimiento) {
        setEsPrimeraCarga(false);
      }
    });
  };

  const getDataInteligencia = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    setIsLoadingInteligencia(true);
    await Axios.get("/api/statistics/reports/reporteInteligencia", {
      headers: { "auth-token": authToken },
      params: {
        fechaDesde: date.dateFrom,
        fechaHasta: date.dateTo,
        chkInteligenciaPorMunicipio: verInteligenciaPorMunicipio,
        chkInteligenciaPorTipoEquipo: verInteligenciaPorTipoEquipo,
        chkInteligenciaPorLomas: verInteligenciaPorLomas,
        chkInteligenciaPorAnioMes: verInteligenciaPorAnioMes,
      },
    }).then((response) => {
      console.log(response);
      var datatmp = data;
      datatmp.inteligencia = response.data.inteligencia;
      setData(datatmp);
      setIsLoadingInteligencia(false);
      if (esPrimeraCarga && !isLoadingResumen && !isLoadingProcesados && !isLoadingProduccion && !isLoadingInteligencia && !isLoadingEdicion && !isLoadingEnriquecimiento) {
        setEsPrimeraCarga(false);
      }
    });
  };

  const getDataEdicion = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    setIsLoadingEdicion(true);
    await Axios.get("/api/statistics/reports/reporteEdicion", {
      headers: { "auth-token": authToken },
      params: {
        fechaDesde: date.dateFrom,
        fechaHasta: date.dateTo,
        chkEdicionPorMunicipio: verEdicionPorMunicipio,
        chkEdicionPorLomas: verEdicionPorLomas,
        chkEdicionPorAnioMes: verEdicionPorAnioMes,
        
      },
    }).then((response) => {
      console.log(response);
      var datatmp = data;
      datatmp.edicion = response.data.edicion;
      setData(datatmp);
      setIsLoadingEdicion(false);
      if (esPrimeraCarga && !isLoadingResumen && !isLoadingProcesados && !isLoadingProduccion && !isLoadingInteligencia && !isLoadingEdicion && !isLoadingEnriquecimiento) {
        setEsPrimeraCarga(false);
      }
    });
  };

  const getDataEnriquecimiento = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    setIsLoadingEnriquecimiento(true);
    await Axios.get("/api/statistics/reports/reporteEnriquecimiento", {
      headers: { "auth-token": authToken },
      params: {
        fechaDesde: date.dateFrom,
        fechaHasta: date.dateTo,
        chkEnriquecimientoPorMunicipio: verEnriquecimientoPorMunicipio,
        chkEnriquecimientoPorTipoEquipo: verEnriquecimientoPorTipoEquipo,
        chkEnriquecimientoPorLomas: verEnriquecimientoPorLomas,
        chkEnriquecimientoPorAnioMes: verEnriquecimientoPorAnioMes,
      },
    }).then((response) => {
      console.log(response);
      var datatmp = data;
      datatmp.enriquecimiento = response.data.enriquecimiento;
      setData(datatmp);
      setIsLoadingEnriquecimiento(false);
      if (esPrimeraCarga && !isLoadingResumen && !isLoadingProcesados && !isLoadingProduccion && !isLoadingInteligencia && !isLoadingEdicion && !isLoadingEnriquecimiento) {
        setEsPrimeraCarga(false);
      }
    });
  };

  function getTotalesPendientes(data) {
    if (data) {
      var MaterialSinProcesar = 0;
      var EnProcesoARGIA = 0;
      var EnProcesoIAPorc = 0;
      var PendienteDeEdicion = 0;
      var PendienteEdicionPorc = 0;
      var InfraccionesParaEnviar = 0;
      var InfraccionesEnviarPorc = 0;
      for (let fila in data) {
        console.log(fila);
        MaterialSinProcesar = Number(MaterialSinProcesar) + Number(data[fila].MaterialSinProcesar);
        EnProcesoARGIA = Number(EnProcesoARGIA) + Number(data[fila].EnProcesoARGIA);
        EnProcesoIAPorc = Number(EnProcesoIAPorc) + Number(data[fila].EnProcesoIAPorc);
        PendienteDeEdicion = Number(PendienteDeEdicion) + Number(data[fila].PendienteDeEdicion);
        PendienteEdicionPorc = Number(PendienteEdicionPorc) + Number(data[fila].PendienteEdicionPorc);
        InfraccionesParaEnviar = Number(InfraccionesParaEnviar) + Number(data[fila].InfraccionesParaEnviar);
        InfraccionesEnviarPorc = Number(InfraccionesEnviarPorc) + Number(data[fila].InfraccionesEnviarPorc);
      }
      return ["<div> Total Material Sin Procesar: " + MaterialSinProcesar + "</div>"
      + "<div> Total En Proceso (IA): " + EnProcesoARGIA + "</div>"
      + "<div> Total Pendientes de Edición: " + PendienteDeEdicion + "</div>"
      + "<div> Total Presunciones para Enviar: " + InfraccionesParaEnviar + "</div>"
      // + "<div> Promedio En Proceso (IA) [%]: " + (EnProcesoIAPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Descartados IA [%]: " + (PendienteEdicionPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Infracciones para Enviar [%]: " + (InfraccionesEnviarPorc/data.length).toFixed(2) + "</div>"
    ];
    }
    else {
      return "";
    }
  }

  function getTotalesProcesados(data) {
    if (data) {
      var Registrados = 0;
      var EntregadoIA = 0;
      var EntregadoIAPorc = 0;
      var DescartadosARGIA = 0;
      var DescartadosIAPorc = 0;
      var AprobadosARGIA = 0;
      var AprobadosIAPorc = 0;
      var Editados = 0;
      var EditadosPorc = 0;
      var EnviadoSPI = 0;
      var EnviadosEMPSATPorc = 0;
      for (let fila in data) {
        console.log(fila);
        Registrados = Number(Registrados) + Number(data[fila].Registrados);
        EntregadoIA = Number(EntregadoIA) + Number(data[fila].EntregadoIA);
        EntregadoIAPorc = Number(EntregadoIAPorc) + Number(data[fila].EntregadoIAPorc);
        DescartadosARGIA = Number(DescartadosARGIA) + Number(data[fila].DescartadosARGIA);
        DescartadosIAPorc = Number(DescartadosIAPorc) + Number(data[fila].DescartadosIAPorc);
        AprobadosARGIA = Number(AprobadosARGIA) + Number(data[fila].AprobadosARGIA);
        AprobadosIAPorc = Number(AprobadosIAPorc) + Number(data[fila].AprobadosIAPorc);
        Editados = Number(Editados) + Number(data[fila].Editados);
        EditadosPorc = Number(EditadosPorc) + Number(data[fila].EditadosPorc);
        EnviadoSPI = Number(EnviadoSPI) + Number(data[fila].EnviadoSPI);
        EnviadosEMPSATPorc = Number(EnviadosEMPSATPorc) + Number(data[fila].EnviadosEMPSATPorc);
      }
      return ["<div> Total Registrados: " + Registrados + "</div>"
      + "<div> Total Entregado a IA: " + EntregadoIA + "</div>"
      + "<div> Total Descartados IA: " + DescartadosARGIA + "</div>"
      + "<div> Total Aprobados por IA: " + AprobadosARGIA + "</div>"
      + "<div> Total Editados: " + Editados + "</div>"
      + "<div> Total Enviado EMP SAT: " + EnviadoSPI + "</div>"
      // + "<div> Promedio Entregado a IA [%]: " + (EntregadoIAPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Descartados IA [%]: " + (DescartadosIAPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Aprobados por IA [%]: " + (AprobadosIAPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Editado [%]: " + (EditadosPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Enviado EMP SAT [%]: " + (EnviadosEMPSATPorc/data.length).toFixed(2) + "</div>"
    ];
    }
    else {
      return "";
    }
  }

  function getTotalesProduccion(data) {
    if (data) {
      var registrados = 0;
      var presunciones = 0;
      var presuncionesPorc = 0;
      var imputables = 0;
      var imputablesPorc = 0;
      for (let fila in data) {
        console.log(fila);
        registrados = Number(registrados) + Number(data[fila].Registrados);
        presunciones = Number(presunciones) + Number(data[fila].PresuncionesEncontradas);
        presuncionesPorc = Number(presuncionesPorc) + Number(data[fila].PresuncionesEncontradasPorc);
        imputables = Number(imputables) + Number(data[fila].Imputables);
        imputablesPorc = Number(imputablesPorc) + Number(data[fila].ImputablesPorc);
      }
      return ["<div> Total Material Registrados: " + registrados + "</div>"
      + "<div> Total Presunciones: " + presunciones + "</div>"
      + "<div> Total Presunciones Imputables: " + imputables + "</div>"
      // + "<div> Promedio Presunciones [%]: " + (presuncionesPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Presunciones Imputables [%]: " + (imputablesPorc/data.length).toFixed(2) + "</div>"
    ];
    }
    else {
      return "";
    }
  }

  function getTotalesInteligencia(data) {
    if (data) {
      var registrados = 0;
      var entregadoIA = 0;
      var entregadoIAPorc = 0;
      var cantidadProcesadosIA = 0;
      var cantidadProcesadosIAPorc = 0;
      var aprobadosIA = 0;
      var aprobadosIAPorc = 0;
      for (let fila in data) {
        console.log(fila);
        registrados = Number(registrados) + Number(data[fila].Registrados);
        entregadoIA = Number(entregadoIA) + Number(data[fila].EntregadoIA);
        entregadoIAPorc = Number(entregadoIAPorc) + Number(data[fila].EntregadoIAPorc);
        cantidadProcesadosIA = Number(cantidadProcesadosIA) + Number(data[fila].ProcesadoIA);
        cantidadProcesadosIAPorc = Number(cantidadProcesadosIAPorc) + Number(data[fila].CantidadProcesadosIAPorc);
        aprobadosIA = Number(aprobadosIA) + Number(data[fila].AprobadosARGIA);
        aprobadosIAPorc = Number(aprobadosIAPorc) + Number(data[fila].AprobadosIAPorc);
      }
      return ["<div> Total Material Registrados: " + registrados + "</div>"
      + "<div> Total Entregado IA: " + entregadoIA + "</div>"
      + "<div> Total Cantidad Procesada IA: " + cantidadProcesadosIA + "</div>"
      + "<div> Total Aprobados IA: " + aprobadosIA + "</div>"
      // + "<div> Promedio Entregaod IA [%]: " + (entregadoIAPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Cantidad Procesada IA [%]: " + (cantidadProcesadosIAPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Aprobados IA [%]: " + (aprobadosIAPorc/data.length).toFixed(2) + "</div>"
    ];
    }
    else {
      return "";
    }
  }

  function getTotalesEdicion(data) {
    if (data) {
      var stockEdicion = 0;
      var PendienteDeEdicion = 0;
      var PendienteDeEdicionPorc = 0;
      var Imputables = 0;
      var ImputablesPorc = 0;
      var NoImputables = 0;
      var NoImputablesPorc = 0;
      var Descartado = 0;
      var DescartadoPorc = 0;
      for (let fila in data) {
        console.log(fila);
        stockEdicion = Number(stockEdicion) + Number(data[fila].AprobadosARGIA);
        PendienteDeEdicion = Number(PendienteDeEdicion) + Number(data[fila].PendienteDeEdicion);
        PendienteDeEdicionPorc = Number(PendienteDeEdicionPorc) + Number(data[fila].PendientesEdicionPorc);
        Imputables = Number(Imputables) + Number(data[fila].Imputables);
        ImputablesPorc = Number(ImputablesPorc) + Number(data[fila].ImputablesPorc);
        NoImputables = Number(NoImputables) + Number(data[fila].NoImputables);
        NoImputablesPorc = Number(NoImputablesPorc) + Number(data[fila].NoImputablesPorc);
        Descartado = Number(Descartado) + Number(data[fila].Descartado);
        DescartadoPorc = Number(DescartadoPorc) + Number(data[fila].DescartadosPorc);
      }
      return ["<div> Total Stock de Edicion: " + stockEdicion + "</div>"
      + "<div> Total Pendiente de Edición: " + PendienteDeEdicion + "</div>"
      + "<div> Total Imputables: " + Imputables + "</div>"
      + "<div> Total No Imputables: " + NoImputables + "</div>"
      + "<div> Total Descartados: " + Descartado + "</div>"
      // + "<div> Promedio Pendiente de Edición [%]: " + (PendienteDeEdicionPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Imputables [%]: " + (ImputablesPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio No Imputables [%]: " + (NoImputablesPorc/data.length).toFixed(2) + "</div>"
      // + "<div> Promedio Descartados [%]: " + (DescartadoPorc/data.length).toFixed(2) + "</div>"
    ];
    }
    else {
      return "";
    }
  }

  function getTotalesEriquecimiento(data) {
    if (data) {
      var InfraccionesAprobadas = 0;
      var EnviarEMPSAT = 0;
      var ParaEnviarEMPSATPorc = 0;
      var EnviadoEMPSAT = 0;
      var EnviadoEMPSATPorc = 0;
      var AprobadosEMPSAT = 0;
      var AprobadosEMPSATPorc = 0;
      var AprobadosSACIT = 0;
      var AprobadosSACITPorc = 0;
      var AprobadoLOMAS = 0;
      var AprobadosLOMASPorc = 0;
      for (let fila in data) {
        InfraccionesAprobadas = Number(InfraccionesAprobadas) + Number(data[fila].InfraccionesAprobadas);
        EnviarEMPSAT = Number(EnviarEMPSAT) + Number(data[fila].EnviarEMPSAT);
        EnviadoEMPSAT = Number(EnviadoEMPSAT) + Number(data[fila].EnviadoEMPSAT);
        var aprobadosEMPSATtmp = 0;
        if (!isNaN()){
          aprobadosEMPSATtmp = Number(data[fila].AprobadosEMPSAT);
        }
        AprobadosEMPSAT = Number(AprobadosEMPSAT) + aprobadosEMPSATtmp;
        var aprobadosSACITtmp = 0;
        if (!isNaN()){
          aprobadosSACITtmp = Number(data[fila].AprobadosSACIT);
        }
        AprobadosSACIT = Number(AprobadosSACIT) + aprobadosSACITtmp;
        var aprobadosLOMAStmp = 0;
        if (!isNaN()){
          aprobadosLOMAStmp = Number(data[fila].AprobadosLOMAS);
        }
        AprobadoLOMAS = Number(AprobadoLOMAS) + aprobadosLOMAStmp;
      }
      return ["<div> Total Stock de Edicion: " + InfraccionesAprobadas + "</div>"
      + "<div> Total Para enviar EMP SAT: " + EnviarEMPSAT + "</div>"
      + "<div> Total Enviado EMP SAT: " + EnviadoEMPSAT + "</div>"
      + "<div> Total Aprobados EMP SAT: " + AprobadosEMPSAT + "</div>"
      + "<div> Total Aprobados SACIT: " + AprobadosSACIT + "</div>"
      + "<div> Total Aprobados Lomas: " + AprobadoLOMAS + "</div>"
    ];
    }
    else {
      return "";
    }
  }

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingProduccion(true);
    setIsLoadingInteligencia(true);
    setIsLoadingEdicion(true);
    setIsLoading(true);
    //getData();
    getDataPendientes();
    getDataProcesados();
    getDataProduccion();
    getDataInteligencia();
    getDataEdicion();
    getDataEnriquecimiento();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="statistics-container">
      <div className="statistics">
        <div className="header-user">
          <div className="text-header">
            <h1>Reportes</h1>
          </div>
        </div>

        <Accordion style={{ marginBottom: "2rem", marginTop: "2rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="header">
              <Icon className="filter-icon">search</Icon>
              <h2> Filtros</h2>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Form id="form" onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Fecha desde: </Form.Label>
                  <Form.Control
                    name="dateFrom"
                    type="date"
                    required
                    value={date.dateFrom}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Fecha hasta: </Form.Label>

                  <Form.Control
                    name="dateTo"
                    type="date"
                    required
                    value={date.dateTo}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              {errorDate ? (
                <span className="error">
                  'Fecha desde' no puede ser mayor a 'Fecha hasta'.
                </span>
              ) : null}
              <Button className="btn-celeste" type="submit">
                Buscar
              </Button>
            </Form>
          </AccordionDetails>
        </Accordion>

        <Tabs
          defaultActiveKey="pendientes"
          id="uncontrolled-tab-example"
          className="mb-3">
          <Tab eventKey="pendientes" title="Pendientes">
            <Form>
              <Form.Row className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded checkboxs" style={{paddingLeft:"10px", marginBottom: "5px", marginLeft: "0px", marginRight: "0px",}}>
                <Form.Group>
                  <Form.Check type="checkbox" name="chkPendientesPorcentajes" id="chkPendientesPorcentajes" label="Porcentajes" value="false" onChange={handleChangePendientesPorcentajes}></Form.Check> 
                  <Form.Check type="checkbox" name="chkPendientesPorMunicipio" id="chkPendientesPorMunicipio" label="Ver por municipalidad" value="false" onChange={handlePendientesPorMunicipio}></Form.Check>
                  <Form.Check type="checkbox" name="chkPendientesLomas" id="chkPendientesLomas" label="Lomas de Zamora" value="false" onChange={handlePendientesPorLomas}></Form.Check>
                  <Form.Check type="checkbox" name="chkPendientesAnioMes" id="chkPendientesAnioMes" label="Año y mes" value="false" onChange={handlePendientesPorAnioMes}></Form.Check>
                </Form.Group>
              </Form.Row>
            </Form>
            <ResumenTable
              data={data?.pendientes}
              date={date}
              isLoading={isLoadingResumen}
              columns={columnasPendientes()}
              totales={getTotalesPendientes(data?.pendientes)}
            ></ResumenTable>
          </Tab>
          <Tab eventKey="procesados" title="Procesados">
            <Form>
              <Form.Row className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded checkboxs" style={{paddingLeft:"10px", marginBottom: "5px", marginLeft: "0px", marginRight: "0px",}}>
                <Form.Group>
                  <Form.Check type="checkbox" name="chkProcesadosPorcentajes" id="chkProcesadosPorcentajes" label="Porcentajes" value="false" onChange={handleChangeProcesadosPorcentajes}></Form.Check> 
                  <Form.Check type="checkbox" name="chkProcesadosPorMunicipio" id="chkProcesadosPorMunicipio" label="Ver por municipalidad" value="false" onChange={handleProcesadosPorMunicipio}></Form.Check>
                  <Form.Check type="checkbox" name="chkProcesadosLomas" id="chkProcesadosLomas" label="Lomas de Zamora" value="false" onChange={handleProcesadosPorLomas}></Form.Check>
                  <Form.Check type="checkbox" name="chkProcesadosAnioMes" id="chkProcesadosAnioMes" label="Año y mes" value="false" onChange={handleProcesadosPorAnioMes}></Form.Check>
                </Form.Group>
              </Form.Row>
            </Form>
            <ResumenTable
              data={data?.procesados}
              date={date}
              isLoading={isLoadingProcesados}
              columns={columnasProcesados()}
              totales={getTotalesProcesados(data?.procesados)}
            ></ResumenTable>
          </Tab>
          <Tab eventKey="produccion" title="Producción">
            <Form>
              <Form.Row className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded checkboxs" style={{paddingLeft:"10px", marginBottom: "5px", marginLeft: "0px", marginRight: "0px",}}>
                <Form.Group>
                  <Form.Check type="checkbox" name="chkProduccionPorcentaje" id="chkProduccionPorcentaje" label="Porcentajes" value="false" onChange={handleChangeProdPorcentajes}></Form.Check>
                  <Form.Check type="checkbox" name="chkProduccionPorMunicipio" id="chkProduccionPorMunicipio" label="Ver por municipalidad" value="false" onChange={handleProdPorMunicipio}></Form.Check>
                  {/* <Form.Check type="checkbox" name="chkProduccionPorTipoEquipo" label="Ver por tipo de equipo" value="false" onChange={handleProdPorTipoEquipo}></Form.Check> */}
                  <Form.Check type="checkbox" name="chkProduccionLomas" id="chkProduccionLomas" label="Lomas de Zamora" value="false" onChange={handleProdLomas}></Form.Check>
                  <Form.Check type="checkbox" name="chkProduccionAnioMes" id="chkProduccionAnioMes" label="Año y mes" value="false" onChange={handleProdAnioMes}></Form.Check>
                </Form.Group>
              </Form.Row>
            </Form>
            <REPTable
              data={data?.produccion}
              date={date}
              isLoading={isLoadingProduccion}
              nombre_tabla={"Produccion"}
              columns={columnasProduccion()}
              totales={getTotalesProduccion(data?.produccion)}
            ></REPTable>
          </Tab>
          <Tab eventKey="inteligencia" title="Inteligencia">
            <Form>
              <Form.Row className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded checkboxs" style={{paddingLeft:"10px", marginBottom: "5px", marginLeft: "0px", marginRight: "0px",}}>
                <Form.Group>
                  <Form.Check type="checkbox" name="chkInteligenciaPorcentajes" id="chkInteligenciaPorcentajes" label="Porcentajes" value="false" onChange={handleChangeInteligenciaPorcentajes}></Form.Check>
                  <Form.Check type="checkbox" name="chkInteligenciaPorMunicipio" id="chkInteligenciaPorMunicipio" label="Ver por municipalidad" value="false" onChange={handleInteligenciaPorMunicipio}></Form.Check>
                  <Form.Check type="checkbox" name="chkInteligenciaLomas" id="chkInteligenciaLomas" label="Lomas de Zamora" value="false" onChange={handleInteligenciaPorLomas}></Form.Check>
                  <Form.Check type="checkbox" name="chkInteligenciaAnioMes" id="chkInteligenciaAnioMes" label="Año y mes" value="false" onChange={handleInteligenciaPorAnioMes}></Form.Check>
                </Form.Group>
              </Form.Row>
            </Form>
            <REPTable
              data={data?.inteligencia?.filter((x) => x.TipoEquipo === "Semaforo")}
              date={date}
              isLoading={isLoadingInteligencia}
              nombre_tabla={"ARGIA"}
              columns={columnasInteligencia()}
              totales={getTotalesInteligencia(data?.inteligencia?.filter((x) => x.TipoEquipo === "Semaforo"))}
            ></REPTable>
          </Tab>
          <Tab eventKey="edicion" title="Edición">
            <Form>
              <Form.Row className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded checkboxs" style={{paddingLeft:"10px", marginBottom: "5px", marginLeft: "0px", marginRight: "0px",}}>
                <Form.Group>
                  <Form.Check type="checkbox" name="chkEdicionPorcentajes" id="chkEdicionPorcentajes" label="Porcentajes" value="false" onChange={handleChangeEdicionPorcentajes}></Form.Check>
                  <Form.Check type="checkbox" name="chkEdicionPorMunicipio" id="chkEdicionPorMunicipio" label="Ver por municipalidad" value="false" onChange={handleEdicionPorMunicipio}></Form.Check>
                  <Form.Check type="checkbox" name="chkEdicionLomas" id="chkEdicionLomas" label="Lomas de Zamora" value="false" onChange={handleEdicionLomas}></Form.Check>
                  <Form.Check type="checkbox" name="chkEdicionAnioMes" id="chkEdicionAnioMes" label="Año y mes" value="false" onChange={handleEdicionPorAnioMes}></Form.Check>
                </Form.Group>
              </Form.Row>
            </Form>
            <REPTable
              data={data?.edicion?.filter((x) => x.TipoEquipo === "Semaforo")}
              date={date}
              isLoading={isLoadingEdicion}
              nombre_tabla={"Edicion"}
              columns={columnasEdicion()}
              totales={getTotalesEdicion(data?.edicion?.filter((x) => x.TipoEquipo === "Semaforo"))}
            ></REPTable>
          </Tab>
          <Tab eventKey="enriquecimiento" title="Enriquecimiento">
            <Form>
              <Form.Row className="MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded checkboxs" style={{paddingLeft:"10px", marginBottom: "5px", marginLeft: "0px", marginRight: "0px",}}>
                <Form.Group>
                  <Form.Check type="checkbox" name="chkEnriquecimientoPorcentajes" id="chkEnriquecimientoPorcentajes" label="Porcentajes" value="false" onChange={handleChangeEnriquecimientoPorcentajes}></Form.Check>
                  <Form.Check type="checkbox" name="chkEnriquecimientoPorMunicipio" id="chkEnriquecimientoPorMunicipio" label="Ver por municipalidad" value="false" onChange={handleEnriquecimientoPorMunicipio}></Form.Check>
                  <Form.Check type="checkbox" name="chkEnriquecimientoLomas" id="chkEnriquecimientoLomas" label="Lomas de Zamora" value="false" onChange={handleEnriquecimientoLomas}></Form.Check>
                  <Form.Check type="checkbox" name="chkEnriquecimientoAnioMes" id="chkEnriquecimientoAnioMes" label="Año y mes" value="false" onChange={handleEnriquecimientoPorAnioMes}></Form.Check>
                </Form.Group>
              </Form.Row>
            </Form>
            <REPTable
              data={data?.enriquecimiento}
              date={date}
              isLoading={isLoadingEnriquecimiento}
              columns={columnasEnriquecimiento()}
              nombre_tabla={"Otro"}
              totales={getTotalesEriquecimiento(data?.enriquecimiento)}
            ></REPTable>
          </Tab>
        </Tabs>
      </div>
      <img
        className="wave"
        src={require("../../../assets/images/waves.png")}
        alt=""
      />
      <img
        className="background-top"
        src={require("../../../assets/images/usuariosFondo.png")}
        alt=""
      />
    </div>
  );
}
