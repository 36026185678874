import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';


export class MapContainer extends Component {
    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
    };

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
        this.props.setEquipmentSelected(parseInt(props.title,10))
    }
    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };
    render() {
        return (
            <Map
                google={this.props.google}
                zoom={this.props.zoom}
                style={{width: this.props.width,
                        height: '100%'}}

                initialCenter={
                    this.props.markers[0] ?
                        {
                            lat: this.props.markers[0].lat,
                            lng: this.props.markers[0].long
                        }
                        :
                        {
                            lat: -34.7128887,
                            lng: -58.3637109
                        }
                }

                onClick={this.onMapClicked}>
                {this.props.markers ?
                    this.props.markers.map((marker, index) => (
                        <Marker key={index}
                            title={marker.title.toString()}
                            position={{ lat: marker.lat, lng: marker.long }}
                            onClick={this.onMarkerClick}>
                            <InfoWindow
                                marker={this.state.activeMarker}
                                visible={this.state.showingInfoWindow}>
                                <div>
                                    <h1>{marker.title}</h1>
                                </div>
                            </InfoWindow>

                        </Marker>
                    )) : null}

            </Map>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBRxPuWSl8F-H73SDJnQFdKRsIE8by4zS8'
})(MapContainer);