import React, { useEffect, useState } from "react";
import Axios from "axios";
import Moment from "moment";
import { useCallback } from "react";
import Loader from "../../../utils/Loader/Loader";
import { Button, Icon } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pie } from "react-chartjs-2";

export default function AnalyzerStatistics(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState({
    dateFrom: "2020-07-01",
    dateTo: Moment(Date.now()).format("YYYY-MM-DD"),
  });
  const options = {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      position: "right",
      labels: {
        boxWidth: 10,
      },
    },
  };

  const [dailyData, setDailyData] = useState(null);
  const [dataChartInfractions, setDataChartInfractions] = useState(null);
  const [user, setUser] = useState({});
  const { checkLoggedIn, history } = props;
  const [errorDate, setErrorDate] = useState(false);

  const setDataTable = useCallback((response) => {
    setDailyData(null);
    setDataChartInfractions(null);
    setUser({
      name: response.user.name + " " + response.user.lastname,
    });
    if (
      response.statistics.infractions[0].userId ||
      response.statistics.disapproval[0].userId
    ) {
      let totalDisapproval = 0,
        totalInfractions = 0,
        imputables = 0,
        notImputables = 0,
        disapproval = 0,
        timeAverage = 0,
        minTime = 0,
        maxTime = 0;

      //Si el pago es != de null
      if (response.payment.disapprovalAmount[0].total)
        totalDisapproval = response.payment.disapprovalAmount[0].total;
      if (response.payment.infractionsAmount[0].total)
        totalInfractions = response.payment.infractionsAmount[0].total;

      //Si hay infracciones
      if (response.statistics.infractions.length !== 0) {
        imputables = response.statistics.infractions[0].howManyImputable;
        notImputables = response.statistics.infractions[0].howManyNotImputable;
        timeAverage = response.statistics.infractions[0].avgTime;
        minTime = response.statistics.infractions[0].minTime;
        maxTime = response.statistics.infractions[0].maxTime;
      }

      //Si hay presunciones desaprobadas
      if (response.statistics.disapproval.length !== 0) {
        disapproval = response.statistics.disapproval[0].howManyDisapproval;
      }

      if (minTime > 60) {
        minTime = Math.round(minTime / 60).toFixed(0) + " min";
      } else {
        minTime = Math.round(minTime).toFixed(0) + " seg";
      }

      if (maxTime > 60) {
        maxTime = Math.round(maxTime / 60).toFixed(0) + " min";
      } else {
        maxTime = Math.round(maxTime).toFixed(0) + " seg";
      }

      if (timeAverage > 60) {
        timeAverage = Math.round(timeAverage / 60).toFixed(0) + " min";
      } else {
        timeAverage = Math.round(timeAverage).toFixed(0) + " seg";
      }

      setDailyData({
        imputables: imputables,
        notImputables: notImputables,
        disapproval: disapproval,
        timeAverage: timeAverage,
        money: Math.round(totalDisapproval + totalInfractions).toFixed(0),
        minTime: minTime,
        maxTime: maxTime,
      });

      let array = [];
      array.push(imputables);
      array.push(notImputables);
      array.push(disapproval);
      setDataChartInfractions({
        labels: ["Imputables", "No Imputables", "Presunciones desaprobadas"],
        datasets: [
          {
            data: array,
            backgroundColor: ["#E53935", "#FFB74D", "#AB47BC"],
            hoverBackgroundColor: ["#E53935", "#FFB74D", "#AB47BC"],
          },
        ],
      });
    }
  }, []);

  const getInfractions = async () => {
    const id = props.match.params.userId;
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/statistics/user/${id}`, {
      params: {
        dateFrom: date.dateFrom,
        dateTo: date.dateTo,
      },
      headers: { "auth-token": authToken },
    }).then((response) => {
      setIsLoading(false);
      setDataTable(response.data);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getInfractions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    setErrorDate(false);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();

    if (dateFromBiggerThanDateTo()) {
      event.preventDefault();
      event.stopPropagation();
      setErrorDate(true);
      return;
    }
    search();
  };

  const dateFromBiggerThanDateTo = () => {
    var isBigger = false;
    const dt = new Date(date.dateTo);
    const df = new Date(date.dateFrom);
    if (df > dt) {
      isBigger = true;
    }
    return isBigger;
  };

  const search = () => {
    const id = props.match.params.userId;
    const searchRecord = async () => {
      const authToken = await checkLoggedIn();
      if (!authToken) {
        return history.push("/");
      }
      await Axios.get(`/api/statistics/user/${id}`, {
        params: {
          dateFrom: date.dateFrom,
          dateTo: date.dateTo,
        },
        headers: { "auth-token": authToken },
      }).then((response) => {
        setIsLoading(false);
        setDataTable(response.data);
      });
    };
    setIsLoading(true);
    searchRecord();
  };

  const handleChange = (event) => {
    setDate({
      ...date,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="statistics-container">
      <Loader show={isLoading} />

      <div className="statistics">
        <div className="header-user">
          <div className="text-header">
            <h1>Estadísticas de {user.name}</h1>
            <h2>
              Acá podes ver la cantidad de infracciones imputables, no
              imputables y desaprobadas de este usuario y su tiempo promedio.
            </h2>
          </div>
          <div className="button-header">
            <Button className="btn-gris" onClick={() => props.history.goBack()}>
              Volver
            </Button>
          </div>
        </div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="header">
              <Icon className="filter-icon">search</Icon>
              <h2> Filtros</h2>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Form id="form" onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Fecha desde: </Form.Label>
                  <Form.Control
                    name="dateFrom"
                    type="date"
                    required
                    value={date.dateFrom}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Fecha hasta: </Form.Label>

                  <Form.Control
                    name="dateTo"
                    type="date"
                    required
                    value={date.dateTo}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              {errorDate ? (
                <span className="error">
                  'Fecha desde' no puede ser mayor a 'Fecha hasta'.
                </span>
              ) : null}
              <Button className="btn-celeste" type="submit">
                Buscar
              </Button>
            </Form>
          </AccordionDetails>
        </Accordion>

        {dailyData !== null ? (
          <div className="cards" style={{ marginTop: "2rem" }}>
            <div className="card">
              <h5>Infracciones</h5>

              <Pie data={dataChartInfractions} width={300} options={options} />
            </div>
            <div className="card">
              <h5>DINERO GANADO</h5>
              <div className="money-card">
                <img
                  src={require("../../../../assets/images/analyzer/money.png")}
                  alt="money"
                />
                <div className="money-description">
                  <h5> $ {dailyData.money}</h5>
                  <div className="desc">
                    <p>{dailyData.imputables} imputables </p>
                    <p>{dailyData.notImputables} no imputables </p>
                    <p>{dailyData.disapproval} desaprobadas </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card ">
              <h5>Tiempo promedio de análisis</h5>
              <div className="timer-card" style={{ marginTop: "1rem " }}>
                <img
                  className="time"
                  src={require("../../../../assets/images/analyzer/time.png")}
                  alt="time"
                  style={{ height: "66%", width: "31%" }}
                />
                <div className="money-description">
                  <h5> {dailyData.timeAverage}</h5>
                  <div className="desc">
                    <p>Su menor tiempo: {dailyData.minTime} </p>
                    <p>Su mayor tiempo: {dailyData.maxTime}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="missing-info">
            <img
              style={{ width: "11rem", margin: "auto" }}
              src={require("../../../../assets/images/admin/sadface.png")}
              alt=""
            />
            No hay información sobre este período de tiempo. Por favor, cambie
            los criterios de búsqueda.
          </div>
        )}
      </div>

      <img
        className="wave"
        src={require("../../../../assets/images/waves.png")}
        alt=""
      />
      <img
        className="background-us"
        src={require("../../../../assets/images/userStatistics.png")}
        alt=""
      />
    </div>
  );
}
