import React, { useState } from "react";
import Backdrop from "./Backdrop/Backdrop";
import SideDrawer from "./SideDrawer/SideDrawer";
import Toolbar from "./Toolbar/Toolbar";

export default function Layout(props) {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const backdropClickHandler = () => {
    setSideBarOpen(false);
  };

  return (
    <div>
      <Toolbar
        drawerClickHandler={drawerToggleClickHandler}
        checkLoggedIn={props.checkLoggedIn}
        handleLogout={props.handleLogout}
        userData={props.userData}
      />
      <SideDrawer
        show={sideBarOpen}
        userData={props.userData}
        handleLogout={props.handleLogout}
      />
      {sideBarOpen ? <Backdrop click={backdropClickHandler} /> : null}
    </div>
  );
}
