import React from 'react';
import "./Loader.scss";
import Spinner from 'react-bootstrap/Spinner';

export default function LoaderModal(props) {

    let drawerClass = "fondo-modal"; 
    if (!props.show) {
        drawerClass = "fondo-modal hidden";
    }

    return (
        <div className={drawerClass} >
            <Spinner animation="border" variant="danger" className="spinner-custom" value={props.progress} />
        </div>

    );

}