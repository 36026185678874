const Roles = {
  ADMIN: {
    id: 1,
    descripcion: "Administrador",
  },
  ANALIZADOR: {
    id: 2,
    descripcion: "Analizador",
  },
  SUPERVISOR_ANALIZADORES: {
    id: 3,
    descripcion: "Supervisor de analizadores",
  },
  TECNICO_CALLE: {
    id: 4,
    descripcion: "Técnico de calle",
  },
  SUPERVISOR_TECNICO_CALLE: {
    id: 5,
    descripcion: "Supervidor de técnico de calle",
  },
  VISUALIZADOR_REPORTES: {
    id: 6,
    descripcion: "Visualizador de reportes",
  },
  VERIFICADOR_VIDEOS: {
    id: 7,
    descripcion: "Verificador de videos",
  },
};

export const RolesSelect = [
  Roles.ADMIN,
  Roles.ANALIZADOR,
  Roles.SUPERVISOR_ANALIZADORES,
  Roles.SUPERVISOR_TECNICO_CALLE,
  Roles.TECNICO_CALLE,
  Roles.VERIFICADOR_VIDEOS,
  Roles.VISUALIZADOR_REPORTES,
];
export default Roles;
