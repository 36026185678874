import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Component } from "react";

export class ErrorModal extends Component {
    render() {
        const { handleConfirmation, ...rest } = this.props;
        return (
            <div>
                <Modal
                    {...rest}

                    centered
                    aria-labelledby='contained-modal-title-vcenter'
                >
                    <Modal.Body>
                        <div className='confirmacion-exitosa'>
                            <img
                                src={require("../../../../src/assets/images/error.png")}
                                alt='success'
                            />
                            <p>Ha ocurrido un error</p>
                            <p>{this.props.text}</p>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='primary'
                            className='btn-azul'
                            onClick={this.props.onHide}
                        >
                            Aceptar
                         </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
