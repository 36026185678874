import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Statistics.scss";
import { Pie, Bar } from "react-chartjs-2";
import Loader from "../../utils/Loader/Loader";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Icon } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Dropdown, Row } from "react-bootstrap";

export default function StatisticsAdminSup(props) {
    //Data Chart
    // const [dataEquipos, setDataEquipos] = useState({
    //     labels: ["Funcionando", "No funcionando"],
    //     datasets: [
    //         {
    //             data: [0, 0],
    //             backgroundColor: ["#EF5350", "#AB47BC"],
    //             hoverBackgroundColor: ["#EF5350", "#AB47BC"],
    //         },
    //     ],
    // });
    const [dataTimeChart, setDataTimeChart] = useState({
        labels: ["Mejor tiempo", "Tiempo promedio", "Peor tiempo"],
        datasets: [
            {
                label: "Segundos",
                data: [0, 0, 0],
                backgroundColor: ["#9C27B0", "#FFA726", "#EF5350"],
                hoverBackgroundColor: ["#9C27B0", "#FFA726", "#EF5350"],
            },
        ],
    });
    const [dataInfractionsChart, setDataInfractionsChart] = useState({
        labels: ["Imputables", "No imputables", "Descartadas"],
        datasets: [
            {
                data: [0, 0, 0],
                backgroundColor: ["#AB47BC", "#FFA726", "#EF5350"],
                hoverBackgroundColor: ["#AB47BC", "#FFA726", "#EF5350"],
            },
        ],
    });
    const options = {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            position: "right",
            labels: {
                boxWidth: 10,
            },
        },
    };
    const optionsTiempo = {
        maintainAspectRatio: true,
        responsive: true,
        legend: {
            position: "right",
            labels: {
                boxWidth: 10,
            },
        },
        scales: {
            xAxes: [
                {
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    categoryPercentage: 0.7,
                    barPercentage: 0.9,
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            yAxes: [
                {
                    gridLines: {
                        display: true,
                        drawBorder: false,
                    },
                    ticks: {
                        suggestedMin: 30,
                        suggestedMax: 100,
                    },
                },
            ],
        },
    };
    //End

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorDate, setErrorDate] = useState(false);
    const [date, setDate] = useState({
        dateFrom: '',
        dateTo: ''
    });
    const { checkLoggedIn, history } = props;

    const handleChange = (event) => {
        setDate({
            ...date,
            [event.target.name]: event.target.value,
        });
    };

    const loadInfractionsChart = (data) => {
        var array = [];
        data.amounts.forEach((amount) => {
            array.push(amount.amountImputable);
            array.push(amount.amountNotImputable);
        })
        array.push(data.amountDisapproval)

        setDataInfractionsChart({
            labels: ["Imputables", "No imputables", "Presunciones desaprobadas"],
            datasets: [
                {
                    data: array,
                    backgroundColor: ["#AB47BC", "#FFA726", "#EF5350"],
                    hoverBackgroundColor: ["#AB47BC", "#FFA726", "#EF5350"],
                },
            ],
        });
    }

    const loadTimeChart = (data) => {
        var array = [];
        data.amounts.forEach((amount) => {
            array.push(amount.minTime);
            array.push(amount.avgTime);
            array.push(amount.maxTime);
        });
        setDataTimeChart({
            labels: ["Mejor tiempo", "Tiempo promedio", "Peor tiempo"],
            datasets: [
                {
                    label: "Segundos",
                    data: array,
                    backgroundColor: ["#9C27B0", "#FFA726", "#EF5350"],
                    hoverBackgroundColor: ["#9C27B0", "#FFA726", "#EF5350"],
                },
            ],
        });
    }

    const setDataCharts = (response) => {
        loadInfractionsChart(response.data.infractions);
        loadTimeChart(response.data.infractions);
    };

    const getInfractions = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get("/api/statistics", {
            headers: { "auth-token": authToken },
        }).then((response) => {
            setIsLoading(false);
            setDataCharts(response);
        });
    }

    const getEquipments = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get("/api/statistics/equipments", {
            headers: { "auth-token": authToken },
        }).then((response) => {
            setIsLoading(false);
            setEquipments(response.data);
        });
    }

    const setEquipments = (response) => {
        // setDataEquipos({
        //     labels: ["Funcionando", "No funcionando"],
        //     datasets: [
        //         {
        //             data: [response.equipments[0].areWorking, response.equipments[0].notWorking],
        //             backgroundColor: ["#EF5350", "#AB47BC"],
        //             hoverBackgroundColor: ["#EF5350", "#AB47BC"],
        //         },
        //     ],
        // })
    }

    const getUsers = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get("/api/user/analyzers", {
            headers: { "auth-token": authToken },
        }).then((response) => {
            setIsLoading(false);
            setUsers(response.data.users)
        });

    }

    useEffect(() => {
        setIsLoading(true);
        getInfractions();
        getUsers();
        if (props.userData.roleId === 1)
            getEquipments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleSubmit = (event) => {
        setErrorDate(false);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();

        if (dateFromBiggerThanDateTo()) {
            event.preventDefault();
            event.stopPropagation();
            setErrorDate(true);
            return;
        }
        search();
    }

    const search = () => {
        const searchRecord = async () => {
            const authToken = await checkLoggedIn();
            if (!authToken) {
                return history.push("/");
            }
            await Axios.get("/api/statistics/searchStatistics", {
                params: {
                    dateFrom: date.dateFrom, dateTo: date.dateTo
                },
                headers: { "auth-token": authToken },
            }).then((response) => {
                setIsLoading(false);
                setDataCharts(response);
            });
        };
        setIsLoading(true);
        searchRecord();
    }

    const dateFromBiggerThanDateTo = () => {
        var isBigger = false;
        const dt = new Date(date.dateTo);
        const df = new Date(date.dateFrom);
        if (df > dt) {

            isBigger = true;
        }
        return isBigger;
    }

    const handleClick = (user) => {
        history.push(`/statistics/${user.id}`)
    }


    return (
        <div className='statistics-container'>
            <Loader show={isLoading} />

            <div className='statistics'>
            <div className="header-user" style={{ marginBottom: '0rem', gridTemplateColumns: '0% 1fr 10%' }}>
                    <div className="text-header">
                        <h1 style={{color: '#202020'}}>Estadísticas de los editores</h1>

                    </div> <div className="button-header">

                    <Dropdown className='display-drop' alignRight>
                                <Dropdown.Toggle id='dropdown-basic' className='drop-title'>
                                    <Icon className='info-icon'>info</Icon>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item className='drop-item'>
                                   
                                        <ul>
                                            <li className='subtitle'>
                                            En el gráfico de tiempo de análisis, podés visualizar los tiempos promedios que tardan los editores
                                            en analizar las presuntas infracciones. 
                                            </li>
                                          <li >
                                          En el gráfico de análisis, se puede comparar la cantidad de presuntas infracciones 
                                        descartadas, no imputables e imputables.
                                          </li>
                                          <li className='subtitle'>
                                         Recordá que una infracción no imputable, es cuando hay infracción pero no se puede incriminar por 
                                          (por ejemplo patente difusa).
                                          </li>
                                        </ul>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                </div>

                {users.length !== 0 ? 
           <Row>
              {users.map(user => (
                  <Col md={3} key={user.id}>
                  <div className="button-user-statistics"  onClick={() => handleClick(user)}>
                     <img src={require("../../../assets/images/supervisor/card-info.png")} alt=""></img>
                      <span>{user.name} {user.lastname}</span>
                  </div>
                  </Col>
              ))} 
           </Row>
           : null}
              

                <Accordion style={{marginBottom: '2rem', marginTop: '2rem'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >

                        <div className='header'>
                            <Icon className="filter-icon">search</Icon><h2> Filtros</h2>
                        </div>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Form id='form' onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} md={2}>
                                    <Form.Label>Fecha desde: </Form.Label>
                                    <Form.Control
                                        name='dateFrom'
                                        type='date'
                                        required
                                        onChange={handleChange}
                                    />

                                </Form.Group>
                                <Form.Group as={Col} md={2} >
                                    <Form.Label>Fecha hasta: </Form.Label>

                                    <Form.Control
                                        name='dateTo'
                                        type='date'
                                        required
                                        onChange={handleChange}
                                    />

                                </Form.Group>
                            </Form.Row>
                            {(errorDate) ? <span className='error'>'Fecha desde' no puede ser mayor a 'Fecha hasta'.</span> : null}
                            <Button className='btn-celeste' type='submit'>Buscar</Button>
                        </Form>
                    </AccordionDetails>
                </Accordion>
                <Row className="row-cards">
                    <Col md={6} sm={12} >
                    <h5>Tiempo de análisis</h5>
                    <Bar data={dataTimeChart}  options={optionsTiempo} />

                    </Col>
                    <Col md={6} sm={12} >  
                    <h5 style={{marginLeft: '4rem'}}>Infracciones</h5>

                    <Pie data={dataInfractionsChart} style={{marginLeft: '2rem'}} options={options} />

                    </Col>
                </Row>

            </div>

            <img
                className='wave'
                src={require("../../../assets/images/waves.png")}
                alt=''
            />
        </div>
    );
}
