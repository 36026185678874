import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Icon } from "@material-ui/core";

export function ReviewDetailsModal(props) {
  return (
    <div>
      <Modal
        size="lg"
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="review-details">
            <div className="header-rd">
              <h3>Equipo</h3>
            </div>
            <div className="body-rd">
              {props.review.Equipment ? (
                <div>
                  <Row>
                    <Col md={6} sm={12}>
                      <p>
                        <Icon className="icons">api</Icon>Estado:
                        {props.review.Equipment.isWorking ? (
                          <span style={{ color: "green" }}>
                            <b> Funcionando </b>
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>
                            <b> No funciona </b>
                          </span>
                        )}
                      </p>
                    </Col>
                    <Col md={6} sm={12}>
                      <p>
                        <Icon className="icons">location_on</Icon>Ubicación:
                        <b>
                          {" "}
                          {props.review.address},{" "}
                          {props.review.Equipment.Municipality?.name ?? " - "}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      <p>
                        <Icon className="icons">settings_system_daydream</Icon>
                        Tipo de sistema:
                        <b>
                          {" "}
                          {props.review.Equipment.typeOfSystem === "new"
                            ? "Nuevo"
                            : "Híbrido"}
                        </b>
                      </p>
                    </Col>
                    <Col md={6} sm={12}>
                      <p>
                        <Icon className="icons">nfc</Icon>Tipo de equipo:
                        <b>
                          {" "}
                          {props.review.Equipment.EquipmentType?.description}
                        </b>
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} sm={12}>
                      <p>
                        <Icon className="icons">wb_auto</Icon>IP:
                        <b> {props.review.Equipment.ip}</b>
                      </p>
                    </Col>
                    <Col md={6} sm={12}>
                      <p>
                        <Icon className="icons">broken_image</Icon>Número de
                        serie:
                        <b> {props.review.Equipment.serialNumber}</b>
                      </p>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>
          <div className="review-details">
            <div className="header-rd">
              <h3>Revisión</h3>
            </div>
            <div className="body-rd">
              {props.review.Equipment ? (
                <div>
                  <Row>
                    <Col sm={12}>
                      <p>
                        <Icon className="icons">help_outline</Icon>
                        Estado:{" "}
                        {props.review.status === "Pendiente" ? (
                          <span style={{ color: "orange" }}>
                            <b>Pendiente</b>
                          </span>
                        ) : props.review.status === "Finalizada" ? (
                          <span style={{ color: "green" }}>
                            <b>Finalizada</b>
                          </span>
                        ) : props.review.status === "Postergada" ? (
                          <span style={{ color: "blue" }}>
                            <b>Postergada</b>
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>
                            <b>No realizada</b>
                          </span>
                        )}{" "}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <p>
                        <Icon className="icons">supervised_user_circle</Icon>
                        Técnicos a cargo:
                        <b> {props.review.technicians}</b>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      <p>
                        <Icon className="icons">today</Icon>Fecha inicial
                        prevista:
                        <b> {props.review.startedAt}</b>
                      </p>
                    </Col>
                    <Col md={6} sm={12}>
                      <p>
                        <Icon className="icons">event_available</Icon>Fecha de
                        finalización:
                        <b> {props.review.finishedAt}</b>
                      </p>
                    </Col>
                  </Row>
                  {props.review.postponedAt &&
                  (props.review.status === "Postergada" ||
                    props.review.status === "No realizada") ? (
                    <div>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <Icon className="icons">event</Icon>Fecha de
                            postergación:
                            <b> {props.review.postponedAt}</b>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  ) : null}
                  {props.review.reasonPostponement ? (
                    <Row>
                      <Col sm={12}>
                        <p>
                          <Icon className="icons">comment_bank</Icon>Motivo de
                          postergación:
                          <b> {props.review.reasonPostponement}</b>
                        </p>
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col sm={12}>
                      <p>
                        <Icon className="icons">bug_report</Icon>Problemas:
                        <b> {props.review.problemDescription}</b>
                      </p>
                    </Col>
                  </Row>
                  {props.review.status === "Finalizada" ? (
                    <Row>
                      <Col sm={12}>
                        <p>
                          <Icon className="icons">build_circle</Icon>Solución:
                          <b> {props.review.solutionDescription}</b>
                        </p>
                      </Col>
                    </Row>
                  ) : null}
                  {props.review.additionalComment ? (
                    <Row>
                      <Col sm={12}>
                        <p>
                          <Icon className="icons">add_comment</Icon>Comentario
                          adicional:
                          <b> {props.review.additionalComment}</b>
                        </p>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
