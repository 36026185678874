import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Component } from "react";

export class NewInfractionModal extends Component {
    render() {
        const { addNewInfraction, ...rest } = this.props;
        return (
            <div>
                <Modal
                    {...rest}
                    centered
                    aria-labelledby='contained-modal-title-vcenter'
                >
                    <Modal.Body>
                        <div className='confirmacion-exitosa'>
                            <img
                                src={require("../../../../assets/images/tick.png")}
                                alt='success'
                            />
                            <p>La infracción ha sido confirmada con éxito</p>
                        </div>
                        <h4>¿Qué desea hacer ahora?</h4>
                        <p>Puede agregar una nueva infracción sobre este mismo video o analizar el siguiente video</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary'
                            className='btn-gris addNew'
                            onClick={() => addNewInfraction()}

                        >
                            Agregar infracción
            </Button>
                        <Button
                            variant='primary'
                            className='btn-azul'
                            onClick={this.props.onHide}
                        >
                            Siguiente video
            </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
