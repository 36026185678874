import React from "react";
import "./Technician.scss";
import Button from "react-bootstrap/Button";

export default function HomeTechnician(props) {
    return (
        <div className='technicianhome-container'>
            <div className='home-wrapper'>
                <img src={require("../../../assets/images/technician/fondo.png")} alt="technician" />
                <div className='bienvenido'>
                    <h1>¡Bienvenido!</h1>
                    <h4>
                     Para dar de alta una revisión, haga click en el botón de abajo
                     </h4>
                    <Button
                        className='btn-celeste'
                        onClick={() => props.history.push("/reviews/today")}
                    >
                        EMPEZAR REVISION
          </Button>
                </div>
            </div>
        </div>
    );
}


