const TipoAnalizadores = {
  VIDEO_SEMAFORO: {
    id: 1,
    descripcion: "Semáforo",
  },
  CINEMOMETRO: {
    id: 2,
    descripcion: "Cinemometro",
  },
  TRUCAM: {
    id: 3,
    descripcion: "Trucam",
  },
  ESTACIONAMIENTO: {
    id: 4,
    descripcion: "Estacionamiento",
  },
};

export const TipoAnalizadoresSelect = [
  TipoAnalizadores.VIDEO_SEMAFORO,
  TipoAnalizadores.CINEMOMETRO,
  TipoAnalizadores.ESTACIONAMIENTO,
  TipoAnalizadores.TRUCAM,
];
export default TipoAnalizadores;
