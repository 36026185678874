import React, { Component } from "react";
import "./Timer.scss";
export default class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds: this.props.initialTime,
            timer: new Date().toLocaleString(),
        };
    }


    componentDidMount() {
        this.myInterval = setInterval(() => {
            if (!this.props.reset) {
            this.setState((prevState) => ({
                seconds: prevState.seconds + 1,
                timer: new Date(this.state.seconds * 1000).toISOString().substr(11, 8),
            }));
            this.props.onChange(this.state.seconds);
            } else {
                this.setState((prevState) => ({
                    seconds: this.props.initialTime,
                    timer: new Date(this.state.seconds * 1000).toISOString().substr(11, 8),
                }));
                this.props.onChange(this.state.seconds);
                this.props.handleReset();
            }
        }, 1000);

   
    }

    render() {
        return <div>{this.state.timer}</div>;
    }
}
