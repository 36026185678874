import { Tooltip } from "@material-ui/core";
import React from "react";

import Modal from "react-bootstrap/Modal";

export default class ImageListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalActive: false,
    };

    this.onClick = this.onClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }
  onClick(i) {
    this.props.removeIndexFromQueue(this.props.imageIndex);
  }

  markAsPrincipal(i) {
    this.props.changeMainImage(this.props.imageIndex);
  }

  handleClose() {
    this.setState({
      modalActive: false,
    });
  }

  handleOpen() {
    this.setState({
      modalActive: true,
    });
  }
  render() {
    if (this.state.modalActive) {
      return (
        <Modal show={this.state.modalActive} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Vista previa</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img alt="" src={this.props.src} className="cropped-img-preview" />
          </Modal.Body>
        </Modal>
      );
    } else {
      return (
        <div className="img-infraccion">
          <div
            className={`image-list-item-${this.props.imageIndex} image-list-item-container cont-prev`}
          >
            {!this.props.showGenericImage ? (
              <div>
                <div>
                  <Tooltip
                    title={
                      this.props.imageIndex === this.props.indexMainImage
                        ? "Imagen principal"
                        : "Imagen secundaria"
                    }
                    arrow
                    placement={
                      this.props.imageIndex % 2 !== 0 ? "right" : "left"
                    }
                  >
                    <img
                      className={
                        this.props.imageIndex === this.props.indexMainImage
                          ? `img-list-preview img-principal`
                          : "img-list-preview"
                      }
                      onClick={this.handleOpen}
                      src={this.props.src}
                      alt="patente"
                    />
                  </Tooltip>

                  {this.props.imageIndex > 0 && this.props.imageIndex < 3 ? (
                    <div
                      onClick={() =>
                        this.markAsPrincipal(this.props.imageIndex)
                      }
                    >
                      <img
                        id={`mark-principal-${this.props.imageIndex}`}
                        className="mark-principal"
                        alt="estrella"
                        src={
                          this.props.imageIndex === this.props.indexMainImage
                            ? require("../../../../assets/images/analyzer/check_m.png")
                            : require("../../../../assets/images/analyzer/check_nm.png")
                        }
                      ></img>
                    </div>
                  ) : null}
                </div>

                <div onClick={this.onClick} className="image-list-close-button">
                  {this.props.imageIndex === this.props.totalImages - 1 ? (
                    <span role="img" aria-labelledby="delete">
                      <img
                        src={require("../../../../assets/images/analyzer/delete.png")}
                        className="delete-img-list"
                        alt="delete"
                      ></img>
                    </span>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            ) : this.props.imageIndex !== 3 ? (
              <div className="image-placeholder">
                <img src={this.props.src} width="45" alt=""></img>
              </div>
            ) : (
              <div className="patente-holder">
                <input
                  className="input"
                  type="text"
                  maxLength="7"
                  placeholder="Patente"
                  name="patent"
                  onChange={this.props.onChangePantente}
                  value={this.props.infoToPost.patent || ""}
                />
                {this.props.errorPatente ? (
                  <span class="error">
                    Este campo es obligatorio y el formato tiene que ser válido
                  </span>
                ) : this.props.patenteDuplicada ? (
                  <span class="error">
                    Ya ha reportado una infracción para esta patente
                  </span>
                ) : null}
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}
