import React, { useState, useCallback } from "react";
import Slider from "@material-ui/core/Slider";
import { Modal, Col, Button } from "react-bootstrap";
import Cropper from "react-easy-crop";
import "./stylesCropper.scss";
import getCroppedImg from "./cropper";

export default function CropperModal(props) {
  const { image, setCroppedImage, ...rest } = props;
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, cap) => {
    setCroppedAreaPixels(cap);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
      setCroppedImage(croppedImage);
      props.onHide();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, image]);

  return (
    <div>
      <Modal
        {...rest}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div>
            <div className="crop-container">
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                zoomSpeed={4}
                maxZoom={3}
                zoomWithScroll={true}
                showGrid={true}
                onCropComplete={onCropComplete}
                aspect={4 / 3}
                onCropChange={setCrop}
                onZoomChange={setZoom}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="controls ">
            <label>
              Zoom
              <Slider
                value={zoom}
                min={1}
                max={10}
                step={0.1}
                aria-labelledby="zoom"
                onChange={(e, zoom) => setZoom(zoom)}
                className="range"
              />
            </label>
          </div>
          <Button onClick={showCroppedImage}>Recortar</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
