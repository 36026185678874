import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Component } from "react";
import Row from "react-bootstrap/esm/Row";
import { Icon } from "@material-ui/core";

export class OnFinishModal extends Component {
  constructor(props) {
    super(props);
    this.submitButtonRef = React.createRef();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (
      this.submitButtonRef.current &&
      !this.submitButtonRef.current.disabled
    ) {
      this.submitButtonRef.current.disabled = true;
      this.props.onSubmit();
    }
  };
  render() {
    return (
      <div>
        <Modal
          {...this.props}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title className="title">Confirmar infracción</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>¿Está seguro que desea confirmar esta infracción?</h3>
            <Row>
              <span className="motivo">
                <Icon className="icons">help_outline</Icon>Tipo de infracción:
              </span>
              <span className="descripcion">
                {this.props.info.infractionType}
              </span>
            </Row>
            {this.props.info.infractionType === "Otro..." ? (
              <Row>
                <span className="motivo">
                  <Icon className="icons">remove_red_eye</Icon>Observaciones:{" "}
                </span>
                <span className="descripcion">
                  {this.props.info.description}
                </span>
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="btn-gris"
              onClick={this.props.onHide}
            >
              Cancelar
            </Button>

            <Button
              variant="primary"
              className="btn-azul"
              onClick={(e) => {
                this.handleSubmit(e);
              }}
              ref={this.submitButtonRef}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
