import React, { useEffect, useState } from "react";
import Loader from "../../../utils/Loader/Loader";
import { AreYouSureModal } from "../../../utils/Modals/AreYouSureModal";
import { SuccessModal } from "../../../utils/Modals/SuccessModal";
import { ErrorModal } from "../../../utils/Modals/ErrorModal";
import Axios from "axios";
import "./TechnicianReview.scss";
import Moment from 'moment';
import { Row, Col, Button, Form } from "react-bootstrap";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function ReviewLastStep(props) {
    const { checkLoggedIn, history } = props;
    const [isLoading, setIsLoading] = useState(false);
    const id = props.match.params.id;
    const [showAreYouSureModal, setAreYouSureModal] = useState(false);
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [solutions, setSolutions] = useState([])
    const [solutionsChecked, setSolutionsChecked] = useState([]);
    const [reviewSelected, setReviewSelected] = useState({
        statusId: 2,
        finishedAt: Moment(Date.now()).format("YYYY-MM-DD"),
        reviewId: id
    });
    const [lastPosition, setLastPosition] = useState(0);
    const [repair, setRepair] = useState({
        reviewId: id,
        startedAt: Moment(Date.now()).format("YYYY-MM-DD"),
        equipmentId: 0,
        statusId: 1,
        reason: "",
        repairPlace: "",
    })


    const updateReview = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }

        const info = { reviewSelected, solutionsChecked, repair }
        await Axios.put(`/api/review/${id}`, info, {
            headers: { "auth-token": authToken },
        }).then( () => {
            setIsLoading(false);
            setConfirmationModal(true);
        }).catch((err) => {
            setIsLoading(false);
            setErrorModal(true);
        });

    };

    const handleConfirmation = () => {
        setIsLoading(true);
        setAreYouSureModal(false);
        updateReview();
    }

    const handleFinish = () => {
        setAreYouSureModal(true);
    }


    const handleChange = (event) => {
        setReviewSelected({
            ...reviewSelected,
            [event.target.name]: event.target.value,
        })

    }

    const handleRepairChange = (event) => {
        setRepair({
            ...repair,
            [event.target.name]: event.target.value,
        })

    }

    const getSolutions = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get(`/api/review/solutions`, {
            headers: { "auth-token": authToken },
        }).then((response) => {
            setSolutions(response.data.solutions)
            let array = [];
            response.data.solutions.map(() => (array.push(false)))
            setSolutionsChecked(array)
            setLastPosition(array.length - 1)
            setIsLoading(false);
        });
    };
    const onSolutionChange = (event) => {
        setSolutionsChecked({
            ...solutionsChecked,
            [event.target.name - 1]: event.target.checked,
        });
    }

    const getReview = async () => {
        const id = props.match.params.id;
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get(`/api/review/${id}`, {
            headers: { "auth-token": authToken },
        }).then((response) => {
            setRepair({
                ...repair,
                equipmentId: response.data.review.equipmentId
            })
        });
    };


    useEffect(() => {
        setIsLoading(true);
        getSolutions();
        getReview();
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleCancel = () => {
        history.push("/reviews/today")
    }

    const closeModal = () => {
        setAreYouSureModal(false);
        if (showConfirmationModal || errorModal ) {
            setConfirmationModal(false);
            setErrorModal(false);
            history.push("/reviews/today");
        }
    }

    return (
        <div className="review">
        <div className='review-technician '>
            <Loader show={isLoading}></Loader>
            <div className="header-rtp">
                <h1>Revisión #{id}</h1>
            </div>
            <div className="step-description">
                <p>Confirmación de revisión</p>
            </div>
            <div className="ok-description">
                <p>Seleccione la/s soluciones a los problemas que se dieron en esta revisión</p>
                <Form style={{ textAlign: 'left' }}>
                    <Form.Group controlId="formBasicCheckbox">

                        <Row className="checkbox">
                            {solutions.map((solution, index) => (
                                <Col md={4} sm={6} key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={onSolutionChange}
                                                disabled={solutionsChecked[0] && !solutionsChecked[index]}
                                                name={solution.reviewSolutionsId.toString()}
                                                color="primary"
                                            />
                                        }
                                        label={solution.description}
                                    />
                                </Col>

                            ))}

                        </Row>
                        {solutionsChecked[lastPosition] === true ?
                            <Row>
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>¿Cuál fue la solución? *</Form.Label>
                                    <Form.Control
                                        name='descriptionSolution'
                                        as='textarea'
                                        required={solutionsChecked[lastPosition]}
                                        rows='3'
                                        type='text'
                                        onChange={handleChange}
                                    />

                                </Form.Group>
                            </Row>
                            : null}

                        {solutionsChecked[0] === true ?
                            <Row>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <Form.Label>¿Por qué va a retirar el equipo? *</Form.Label>
                                    <Form.Control
                                        name='reason'
                                        as='textarea'
                                        required={solutionsChecked[0]}
                                        rows='3'
                                        type='text'
                                        onChange={handleRepairChange}
                                    />

                                </Form.Group>
                                <Form.Group as={Col} md={6} sm={12}>
                                    <Form.Label>¿A dónde se va a llevar el equipo? Incluir dirección completa *</Form.Label>
                                    <Form.Control
                                        name='repairPlace'
                                        as='textarea'
                                        required={solutionsChecked[0]}
                                        rows='3'
                                        type='text'
                                        onChange={handleRepairChange}
                                    />

                                </Form.Group>
                            </Row>

                            : null}
                    </Form.Group>

                    <Row>
                        <Form.Group as={Col} md={12} >
                            <Form.Label>Comentario adicional</Form.Label>
                            <Form.Control
                                name='additionalComment'
                                as='textarea'
                                rows='3'
                                type='text'
                                onChange={handleChange}
                            />

                        </Form.Group>
                    </Row>
                </Form>


            </div>


            <Row>
                <div className="btn-container">
                    <Button className="btn-celeste"
                        onClick={handleFinish}>
                        Finalizar</Button>
                    <Button variant='secondary' className="btn-gris"
                        onClick={handleCancel}>Volver</Button>
                </div>
            </Row>
            <AreYouSureModal
                show={showAreYouSureModal}
                handleConfirmation={handleConfirmation}
                onHide={closeModal}
                text={"¿Está seguro que desea finalizar esta revisión?"}
            />
            <SuccessModal
                show={showConfirmationModal}
                onHide={closeModal}
                text={"La revisión se ha finalizado con éxito"}
            ></SuccessModal>
             <ErrorModal
                show={errorModal}
                onHide={closeModal}
            ></ErrorModal>
        </div>
        </div>
    );
}

