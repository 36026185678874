import React, { useEffect, useState } from "react";
import Axios from "axios";
import MaterialTable from "material-table";
import { TablePagination } from "@material-ui/core";

export default function ResumenTable(props) {
  const { data, isLoading, date, columns, totales } = props;
  return (
    <MaterialTable
      components={{
        Pagination: props => 
          <div>
            <TablePagination {...props}></TablePagination>
            <div style={{textAlign: "left", marginLeft: 10}}>
              <div dangerouslySetInnerHTML={{__html: totales}}></div>
            </div>
          </div>
      }}
      columns={columns}
      data={data}
      isLoading={isLoading}
      localization={{
        // pagination: {
        //   labelDisplayedRows: "{from}-{to} de {count}",
        // },
        toolbar: {
          nRowsSelected: "{0} fila(s) seleccionadas",
        },
        header: {
          actions: " ",
        },
        body: {
          emptyDataSourceMessage: "No hay registros para mostrar",
          filterRow: {
            filterTooltip: "Filtro",
          },
        },
      }}
      title=" "
      options={{
        exportButton: true,
        exportAllData: true,
        headerStyle: { position: "sticky", top: 0 },
        maxBodyHeight: "650px",
        exportFileName: date.dateFrom + "_" + date.dateTo + "_Reporte_Resumen",
        actionsColumnIndex: -1,
        filtering: false,
        pageSize: typeof data?.length !== "undefined" ? data.length : 100, // make initial page size
        emptyRowsWhenPaging: false, // To avoid of having empty rows
        pageSizeOptions: [
          10,
          30,
          50,
          100,
          {
            value: typeof data?.length !== "undefined" ? data.length : 100,
            label: "All",
          },
        ], // rows selection options
        rowStyle: (rowData) => {
          return {
            backgroundColor:
              rowData.tableData.id % 2 == 0 ? "#eaeaea" : "#ffffff",
          };
        },
      }}
    />
  );
}
