import "./styles.scss";
import { Button, Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { AreYouSureModal } from "../../utils/Modals/AreYouSureModal";

import Axios from "axios";
import Loader from "../../utils/Loader/Loader";
import TipoAnalizadores from "../../utils/Constants/TipoAnalizadores";
import CropperModal from "./Cropper/CropperModal";

export default function AnalyzerImage(props) {
  const [patente, setPatente] = useState();
  const { checkLoggedIn, history } = props;
  const [validated, setValidated] = useState(false);
  const [showAreYouSureModalPatente, setAreYouSureModalPatente] =
    useState(false);
  const [infractionId, setInfractionId] = useState();
  const [image, setImage] = useState();
  const [imageSecondary, setImageSecondary] = useState();

  const [thereIsContent, setThereIsContent] = useState(false);
  const [showAreYouSureModalNoSeVe, setAreYouSureModalNoSeVe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tipoEquipo, setTipoEquipo] = useState(0);
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [patenteImage, setPatenteImage] = useState();
  const [errorImagen, setErrorImagen] = useState(false);

  const regex = /^(?:[A-Z]{3}\d{3}|[A-Z]{2}\d{3}[A-Z]{2})$/;

  useEffect(() => {
    if (props.match.params.equipmentType) {
      let tipoEquipo = props.match.params.equipmentType;
      switch (tipoEquipo) {
        case "e":
          setTipoEquipo(TipoAnalizadores.ESTACIONAMIENTO.id);
          getEstacionamientoInfraction();
          break;
        case "t":
          setTipoEquipo(TipoAnalizadores.TRUCAM.id);
          getTrucamInfraction();
          break;
        default:
          history.push("/home");
          break;
      }
    }
  }, []);

  const getTrucamInfraction = async () => {
    if (props?.match?.params?.municipalityId) {
      let authToken = await getAuthToken();
      setIsLoading(true);
      await Axios.get(
        "/api/trucam/municipality/" +
          props.match.params.municipalityId +
          "/infraction",
        {
          headers: { "auth-token": authToken },
        }
      )
        .then((response) => {
          if (response.data && response.data.infractionId) {
            setThereIsContent(true);
            setInfractionId(response.data.infractionId);
            getImage(response.data.imageName, authToken, 1);
          } else {
            setIsLoading(false);
            setThereIsContent(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
          history.push("/home");
        });
    }
  };

  const getEstacionamientoInfraction = async () => {
    if (props?.match?.params?.municipalityId) {
      let authToken = await getAuthToken();
      setIsLoading(true);
      await Axios.get(
        "/api/estacionamiento/municipality/" +
          props.match.params.municipalityId +
          "/infraction",
        {
          headers: { "auth-token": authToken },
        }
      )
        .then((response) => {
          if (response.data && response.data.zImageName) {
            setThereIsContent(true);
            setInfractionId(response.data.infractionId);
            getImage(response.data.zImageName, authToken, 1);
            getImage(response.data.pImageName, authToken, 2);
            setPatente(formatPatent(response.data.patent));
          } else {
            setIsLoading(false);
            setThereIsContent(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
          history.push("/home");
        });
    }
  };

  const getImage = async (name, authToken, imageOrder) => {
    await Axios.get(`/api/image/${name}`, {
      headers: { "auth-token": authToken },
      responseType: "blob",
    })
      .then((res) => {
        const fileURL = URL.createObjectURL(res.data);
        if (imageOrder == 1) setImage(fileURL);
        else setImageSecondary(fileURL);
      })
      .finally(() => setIsLoading(false));
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setErrorImagen(false);
    setValidated(true);

    if (form.checkValidity() === false) {
      if (tipoEquipo == TipoAnalizadores.TRUCAM.id && !patenteImage) {
        setErrorImagen(true);
        return;
      }

      return;
    }

    setAreYouSureModalPatente(true);
  };

  const closeModal = () => {
    setAreYouSureModalPatente(false);
    setAreYouSureModalNoSeVe(false);
  };

  const formatPatent = (patent) => {
    if (patent) return patent.replace(/\s/g, "").toUpperCase();
    else return "";
  };

  const handleConfirmationPatente = async () => {
    setAreYouSureModalPatente(false);
    setIsLoading(true);
    switch (tipoEquipo) {
      case TipoAnalizadores.ESTACIONAMIENTO.id:
        updatePatenteEstacionamiento();
        break;
      case TipoAnalizadores.TRUCAM.id:
        updatePatenteTrucam();
        break;
      default:
        history.push("/home");
        break;
    }
  };

  const updatePatenteTrucam = async () => {
    let authToken = await getAuthToken();
    await Axios.post(
      "/api/trucam/" + infractionId,
      { patente: patente },
      {
        headers: { "auth-token": authToken },
      }
    )
      .then(async (response) => {
        const data = new FormData();
        var res = await fetch(patenteImage);
        data.append("images", await res.blob());
        Axios.post(`/api/trucam/${infractionId}/frame`, data, {
          headers: { "auth-token": authToken },
        });
        resetForm();
        getTrucamInfraction();
      })
      .catch((e) => {
        setIsLoading(false);
        history.push("/home");
      });
  };

  const updatePatenteEstacionamiento = async () => {
    let authToken = await getAuthToken();
    await Axios.post(
      "/api/estacionamiento/" + infractionId,
      { patente: patente },
      {
        headers: { "auth-token": authToken },
      }
    )
      .then((response) => {
        resetForm();
        getEstacionamientoInfraction();
      })
      .catch(() => {
        setIsLoading(false);
        history.push("/home");
      });
  };

  const getAuthToken = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    return authToken;
  };

  const resetForm = () => {
    setPatente("");
    setPatenteImage("");
    setInfractionId("");
    setImage("");
    setImageSecondary("");
    setValidated(false);
  };

  const handleConfirmationNoSeVe = async () => {
    setAreYouSureModalNoSeVe(false);
    setIsLoading(true);
    switch (tipoEquipo) {
      case TipoAnalizadores.ESTACIONAMIENTO.id:
        updateEstacionamientoNoSeVePatente();
        break;
      case TipoAnalizadores.TRUCAM.id:
        updateTrucamNoSeVePatente();
        break;
      default:
        history.push("/home");
        break;
    }
  };

  const updateTrucamNoSeVePatente = async () => {
    let authToken = await getAuthToken();
    await Axios.post(
      "/api/trucam/" + infractionId + "/noPatente",
      {},
      {
        headers: { "auth-token": authToken },
      }
    )
      .then((response) => {
        resetForm();
        getTrucamInfraction();
      })
      .catch(() => {
        setIsLoading(false);
        history.push("/home");
      });
  };

  const updateEstacionamientoNoSeVePatente = async () => {
    let authToken = await getAuthToken();
    await Axios.post(
      "/api/estacionamiento/" + infractionId + "/noPatente",
      {},
      {
        headers: { "auth-token": authToken },
      }
    )
      .then((response) => {
        resetForm();
        getEstacionamientoInfraction();
      })
      .catch(() => {
        setIsLoading(false);
        history.push("/home");
      });
  };

  return (
    <div className="analyzer-trucam__container">
      <Loader show={isLoading} />
      {thereIsContent ? (
        <div>
          <div className="row">
            <div className="col-md-12 text-left">
              <h3>Por favor, completá la patente que se ve en la imagen</h3>
              <p>
                {tipoEquipo == TipoAnalizadores.TRUCAM.id
                  ? " Hacé click sobre la imagen para recortarla"
                  : ""}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="image-container">
                {imageSecondary ? (
                  <div className="row">
                    <div className="col-md-6">
                      {image && <img src={image} className="image-one"></img>}
                    </div>
                    <div className="col-md-6">
                      <img src={imageSecondary} className="image-two"></img>
                    </div>
                  </div>
                ) : (
                  image && (
                    <img
                      src={image}
                      className="image-one"
                      onClick={() => setShowCropperModal(true)}
                    ></img>
                  )
                )}
              </div>
            </div>

            <div className="col-md-4">
              {tipoEquipo == TipoAnalizadores.TRUCAM.id ? (
                <div className="mb-15">
                  <h5 className="ptp__titulo">Imagen patente</h5>
                  <div className="preview-img-container">
                    {patenteImage ? <img src={patenteImage}></img> : null}
                  </div>
                  {errorImagen ? (
                    <span className="error">Por favor, recorte la patente</span>
                  ) : null}
                </div>
              ) : null}

              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Patente</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength="7"
                    value={patente}
                    pattern={regex.source}
                    required
                    onChange={(x) => setPatente(formatPatent(x.target.value))}
                    placeholder="Ingrese la patente"
                  />
                </Form.Group>
                <div className="btn-group">
                  <Button
                    variant="danger"
                    disabled={isLoading || !thereIsContent}
                    onClick={() => setAreYouSureModalNoSeVe(true)}
                  >
                    No se ve la patente
                  </Button>{" "}
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isLoading || !thereIsContent}
                  >
                    Siguiente
                  </Button>{" "}
                </div>
              </Form>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-content__container">
          <img
            src={require("../../../assets/images/admin/sadface.png")}
            alt="money"
          />
          <h4>No hay imágenes para mostrar</h4>
          <Button variant="primary" onClick={() => history.push("/home")}>
            Volver
          </Button>
        </div>
      )}

      <AreYouSureModal
        show={showAreYouSureModalNoSeVe}
        handleConfirmation={handleConfirmationNoSeVe}
        onHide={closeModal}
        text={"¿Confirmas que no se ve la patente?"}
      />
      <AreYouSureModal
        show={showAreYouSureModalPatente}
        handleConfirmation={handleConfirmationPatente}
        onHide={closeModal}
        text={"¿Confirmas que la patente es " + patente + "?"}
      />
      <CropperModal
        show={showCropperModal}
        image={image}
        setCroppedImage={(x) => {
          setPatenteImage(x);
          setErrorImagen(false);
        }}
        onHide={() => setShowCropperModal(false)}
      ></CropperModal>
    </div>
  );
}
