import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./TechnicianReview.scss";
import Loader from "../../../utils/Loader/Loader";
import { Row, Col, Button } from "react-bootstrap";
import { Icon } from "@material-ui/core";

export default function TechnicianReview(props) {
  const { checkLoggedIn, history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [reviews, setReviews] = useState([]);

  const getReviewsOfTheDay = async () => {
    const id = props.userData.id;
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/review/technician/day/${id}`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setIsLoading(false);
      setReviews(response.data.reviews);
      updateViewedReviews(response.data.reviews);
    });
  };

  const updateViewedReviews = async (reviews) => {
    reviews.map(async (review) => {
      if (!review.viewed) {
        const authToken = await checkLoggedIn();
        if (!authToken) {
          return history.push("/");
        }
        await Axios.put(`/api/review/viewed/${review.reviewId}`, review, {
          headers: { "auth-token": authToken },
        });
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getReviewsOfTheDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (review) => {
    history.push(`/review/${review.reviewId} `);
  };

  return (
    <div className="rt-container">
      <div className="review-technician">
        <Loader show={isLoading}></Loader>
        <Row>
          <Col md={7}>
            <div className="header-rt">
              <h1>Tareas asignadas para hoy</h1>
              <h3>
                En amarillo son las tareas pendientes, en verde las tareas
                finalizadas y en rojo las tareas que no se completaron
              </h3>
            </div>
          </Col>
          <Col md={5}>
            <div className="header-button">
              <Button className="btn-celeste" href="/reviews/all">
                Ver todas
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          {reviews.map((review) => (
            <Col md={4} sm={12} key={review.reviewId}>
              <div
                className="card-review"
                style={{
                  backgroundColor:
                    review.statusId === 1 || review.statusId === 3
                      ? "#FFE39B"
                      : review.statusId === 2
                      ? "#ACFF9E"
                      : null,
                }}
                onClick={(e) => handleClick(review)}
              >
                <Icon className="icon-header">assignment</Icon>
                <h4>Revisión #{review.reviewId}</h4>
                <p>
                  <Icon className="icons">developer_board</Icon>Equipo:
                  <span className="description-cr">
                    {" "}
                    {review.Equipment.brand} {review.Equipment.model}
                  </span>
                </p>
                <p>
                  <Icon className="icons">settings_input_antenna</Icon>Equipo:
                  <span className="description-cr">
                    {" "}
                    {review.Equipment.EquipmentType?.description}{" "}
                  </span>
                </p>
                <p>
                  <Icon className="icons">location_on</Icon>Ubicación:{" "}
                  <span className="description-cr">
                    {review.Equipment.street} {review.Equipment.streetNumber},{" "}
                    {review.Equipment.Municipality?.name ?? " - "}
                  </span>
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <img
        className="wave"
        src={require("../../../../assets/images/waves.png")}
        alt=""
      />
    </div>
  );
}
