import React from "react";
import "./Home.scss";
import Button from "react-bootstrap/Button";

export default function HomeSupervisorTechnician(props) {
    return (
        <div className="supervisor-container">
            <div className='homesuper-wrapper'>
                <img src={require("../../../assets/images/supervisor/car.png")} alt="car" />
                <div className='bienvenido'>
                    <h1> ¡Bienvenido!</h1>
                    <h4>
                        Visualize el trabajo de los técnicos haciendo click en
                        el botón de abajo
          </h4>
                    <Button
                        className='btn-celeste'
                        onClick={() => props.history.push("/reporte")}
                    >
                        VER TRABAJO
          </Button>
                </div>
            </div>

        </div>);
}