import React from "react";
import ImageListItem from "./ImageListItem.jsx";

export default class ImageList extends React.Component {
  removeIndexFromQueue(i) {
    this.props.removeIndexFromQueue(i);
  }
  render() {
    let listItems = [];
    for (let i = 0; i < this.props.images.length; i++) {
      listItems[i] = (
        <ImageListItem
          src={this.props.images[i]}
          key={i}
          removeIndexFromQueue={(i) => {
            this.removeIndexFromQueue(i);
          }}
          imageIndex={i}
          totalImages={this.props.images.length}
        />
      );
    }
    return <div className="image-list-container">{listItems}</div>;
  }
}
