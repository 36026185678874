import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./CheckVideos.scss";
import MaterialTable from "material-table";
import Moment from "moment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button, Icon } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

export default function CheckVideos(props) {
  const [errorDate, setErrorDate] = useState(false);
  const [municipalities, setMunicipalities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn, history } = props;
  const [bajadas, setBajadas] = useState([]);
  const [data, setData] = useState({
    fechaDesde: "",
    fechaHasta: "",
    municipalityId: "",
  });

  useEffect(() => {
    setIsLoading(true);
    getMunicipalities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setErrorDate(false);
    if (dateFromBiggerThanDateTo()) {
      event.preventDefault();
      event.stopPropagation();
      setErrorDate(true);
      return;
    }
    getBajadas();
  };

  const dateFromBiggerThanDateTo = () => {
    var isBigger = false;
    const dt = new Date(data.fechaHasta);
    const df = new Date(data.fechaDesde);
    if (df > dt) {
      isBigger = true;
    }
    return isBigger;
  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  const getBajadas = async () => {
    const authToken = await getAuthToken();
    await Axios.get("/api/bajada/filter", {
      params: {
        municipalityId: data.municipalityId,
        fechaDesde: data.fechaDesde,
        fechaHasta: data.fechaHasta,
      },
      headers: { "auth-token": authToken },
    }).then((response) => {
      console.log(response);
      setBajadas(response.data.bajada);
      setIsLoading(false);
    });
  };

  const getMunicipalities = async () => {
    const authToken = await getAuthToken();
    await Axios.get("/api/municipality", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setMunicipalities(response.data.municipalities);
      setIsLoading(false);
    });
  };

  const getAuthToken = async () => {
    const authToken = await props.checkLoggedIn();
    if (!authToken) {
      props.history.push("/");
      return;
    }
    return authToken;
  };

  return (
    <div className="userpayments-container">
      <div className="userpayments-wrapper">
        <div className="header">
          <div className="text-header">
            <h1>Bajadas</h1>
            <h2>Mirá las bajadas que se subieron al servidor</h2>
          </div>
        </div>

        <Accordion className="mb-4">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="subtitle">
              <Icon className="filter-icon">search</Icon>
              <h2> Filtros</h2>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Form id="form" onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Grabado Desde: </Form.Label>
                  <Form.Control
                    required
                    name="fechaDesde"
                    type="date"
                    value={data.fechaDesde}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Grabado Hasta: </Form.Label>
                  <Form.Control
                    required
                    name="fechaHasta"
                    type="date"
                    value={data.fechaHasta}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Municipio: </Form.Label>
                  <Form.Control
                    required
                    name="municipalityId"
                    as="select"
                    custom
                    value={data.municipalityId}
                    onChange={handleChange}
                  >
                    <option hidden value="">
                      Selecciona...
                    </option>
                    {municipalities.map((municipality, index) => (
                      <option key={index} value={municipality.municipalityId}>
                        {municipality.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              {errorDate ? (
                <span className="error">
                  'Fecha desde' no puede ser mayor a 'Fecha hasta'.
                </span>
              ) : null}
              <Button className="btn-celeste" type="submit">
                Buscar
              </Button>
            </Form>
          </AccordionDetails>
        </Accordion>

        <MaterialTable
          columns={[
            { title: "ID de equipo", field: "equipmentId" },
            {
              title: "Cantidad de videos subidos",
              field: "cantidad",
            },
            {
              title: "Fecha de subida al servidor",
              field: "fechaRecibido",
              type: "date",
              dateSetting: {
                format: "dd/MM/yyyy",
              },
            },
          ]}
          data={bajadas}
          isLoading={isLoading}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
            },
            toolbar: {
              nRowsSelected: "{0} fila(s) seleccionadas",
            },
            header: {
              actions: " ",
            },
            body: {
              emptyDataSourceMessage: "No hay registros para mostrar",
              filterRow: {
                filterTooltip: "Filtro",
              },
            },
          }}
          title=" "
          options={{
            exportDelimiter: ";",
            exportButton: true,
            exportAllData: true,
            exportFileName:
              "Datos bajada " +
              data.municipalityId +
              " - " +
              data.fechaDesde +
              " - " +
              data.fechaHasta,
          }}
        />
      </div>
    </div>
  );
}
