import React, { useEffect, useState } from "react";
import Axios from "axios";
import MaterialTable from "material-table";
import "./style.scss";
import { WarningModal } from "../../../utils/Modals/WarningModal";
import { Button } from "react-bootstrap";
import SelectMunicipalityModal from "../../Home/Modals/SelectMunicipalityModal";

export default function IndexStateOfCameras(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn, history } = props;
  const [equipments, setEquipments] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [showSelectMunicipalityModal, setSelectMunicipalityModal] =
    useState(false);
  const [municipalitySelected, setMunicipalitySelected] = useState(-1);

  const setDataTable = (response) => {
    var aux;
    let array = [];
    response.forEach((equipment) => {
      let streetNumber = "";
      if (equipment.streetNumber) streetNumber = equipment.streetNumber;
      aux = {
        equipmentId: equipment.equipmentId,
        serialNumber: equipment.serialNumber,
        brand_model: equipment.brand + " " + equipment.model,
        isWorking: equipment.isWorking,
        municipalityId: equipment.municipalityId,
        municipality: equipment.Municipality?.municipalityId ?? 0,
        equipmentType: equipment.EquipmentType?.description,
        address: equipment.street + " " + streetNumber,
      };
      array.push(aux);
    });
    if (props.location.state) {
      array = array.filter(
        (equipment) =>
          equipment.municipalityId === props.location.state.municipalityId
      );
    }
    setEquipments(equipments.concat(array));
  };

  const closeModal = () => {
    setErrorModal(false);
    setSelectMunicipalityModal(false);
  };

  const handleMoreDetails = (rowData) => {
    const type = rowData.equipmentType === "Velocidad" ? "v" : "s";
    history.push(`/cameraStatus/${rowData.equipmentId}/${type}`);
  };

  const download = async (rowData) => {
    const authToken = await getAuthToken();

    if (rowData.equipmentType !== "Velocidad") {
      await Axios.get(`/api/equipment/${rowData.equipmentId}/10/last/videos`, {
        headers: { "auth-token": authToken },
      }).then((response) => {
        const videos = response.data.videos;

        if (videos.length === 0) {
          setErrorModal(true);
          setErrorText(
            "No hay videos disponibles para descargar de este equipo."
          );
        } else if (videos.length < 10) {
          setErrorModal(true);
          setErrorText(
            "Solo hay " +
              videos.length +
              " videos disponibles para descargar de este equipo."
          );
        }

        //Foreach video, download it
        videos.map(async (x) => {
          await Axios.get(`/api/user/video/get/${x.videoId}`, {
            headers: { "auth-token": authToken },
            responseType: "blob",
          }).then((response) => {
            downloadFile(response.data, x.name);
          });
        });
      });
    } else {
      await Axios.get(`/api/equipment/${rowData.equipmentId}/10/last/images`, {
        headers: { "auth-token": authToken },
      }).then((response) => {
        const images = response.data.images;

        if (images.length === 0) {
          setErrorModal(true);
          setErrorText(
            "No hay imágenes disponibles para descargar de este equipo."
          );
        } else if (images.length < 10) {
          setErrorModal(true);
          setErrorText(
            "Solo hay " +
              images.length +
              " imágenes disponibles para descargar de este equipo."
          );
        }

        //For each image, download it
        images.map(async (x) => {
          await Axios.get(`/api/image/${x.image1}`, {
            headers: { "auth-token": authToken },
            responseType: "blob",
          }).then((response) => {
            downloadFile(response.data, x.image1);
          });
        });
      });
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (municipalitySelected === "0" || municipalitySelected === 0) {
      history.push(`/all/cameraStatus/${municipalities[0].municipalityId}`);
    } else history.push(`/all/cameraStatus/${municipalitySelected}`);
  };

  function downloadFile(data, name) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
  }

  const getEquipments = async () => {
    const authToken = await getAuthToken();
    await Axios.get("/api/equipment/", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setDataTable(response.data.equipments);
      setIsLoading(false);
    });
  };

  const getAuthToken = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    return authToken;
  };

  const getMunicipalities = async () => {
    const authToken = await getAuthToken();
    await Axios.get("/api/municipality", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setMunicipalities(response.data.municipalities);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getEquipments();
    getMunicipalities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="equipments-container">
      <div className="equipments-wrapper">
        <div className="header-equipments">
          <div className="text-header">
            <h1>Estado de las cámaras</h1>
            <h2>Seleccioná algún equipo para ver el estado de la cámara</h2>
          </div>
          <div className="button-header">
            <Button
              className="btn-celeste"
              onClick={() => setSelectMunicipalityModal(true)}
            >
              VER TODO
            </Button>
          </div>
        </div>
        <div className="header">
          <h1>Equipos</h1>
        </div>
        <MaterialTable
          columns={[
            { title: "ID", field: "equipmentId", filtering: false },
            { title: "N° de serie", field: "serialNumber", filtering: false },
            { title: "Modelo", field: "brand_model", filtering: false },
            {
              title: "Dirección",
              field: "address",
              filtering: false,
              cellStyle: { minWidth: 270 },
              headerStyle: { minWidth: 270 },
            },
            {
              title: "Municipio",
              field: "municipality",
              lookup: municipalities.reduce(function (acc, cur, i) {
                acc[cur.municipalityId] = cur.name;
                return acc;
              }, {}),
            },
            {
              title: "Tipo de equipo",
              field: "equipmentType",
              lookup: { Semaforo: "Semaforo", Velocidad: "Velocidad" },
            },
            {
              title: "Estado",
              field: "isWorking",
              lookup: { 0: "No funciona", 1: "Funciona" },
              render: (rowData) => {
                return rowData.isWorking ? (
                  <span className="finalizada status">Funcionando</span>
                ) : (
                  <span className="no-realizada status">No funciona</span>
                );
              },
            },
          ]}
          data={equipments}
          isLoading={isLoading}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
            },
            toolbar: {
              nRowsSelected: "{0} fila(s) seleccionadas",
            },
            header: {
              actions: " ",
            },
            body: {
              emptyDataSourceMessage: "No hay registros para mostrar",
              filterRow: {
                filterTooltip: "Filtro",
              },
            },
          }}
          actions={[
            {
              icon: "download",
              tooltip: "Descargar 10 últimos",
              onClick: (event, rowData) => download(rowData),
            },
            {
              icon: "open_in_new",
              tooltip: "Ver estado de la camara",
              onClick: (event, rowData) => handleMoreDetails(rowData),
            },
          ]}
          title=" "
          options={{
            actionsColumnIndex: -1,
            filtering: true,
          }}
        />
      </div>
      <img
        className="wave"
        src={require("../../../../assets/images/waves.png")}
        alt=""
      />
      <img
        className="background-top"
        src={require("../../../../assets/images/admin/equipment.png")}
        alt=""
      />
      <WarningModal
        show={errorModal}
        onHide={closeModal}
        text={errorText}
      ></WarningModal>
      <SelectMunicipalityModal
        show={showSelectMunicipalityModal}
        onHide={closeModal}
        handleSubmit={handleSubmit}
        question={"¿Por cuál municipio querés empezar?"}
        municipalities={municipalities}
        setMunicipalitySelected={setMunicipalitySelected}
      ></SelectMunicipalityModal>
    </div>
  );
}
