import React, { useState, useEffect } from "react";
import "./register.scss";
import Col from "react-bootstrap/Col";
import Axios from "axios";
import Button from "react-bootstrap/esm/Button";
import Loader from "../../utils/Loader/Loader";
import { AreYouSureModal } from "../../utils/Modals/AreYouSureModal";
import { SuccessModal } from "../../utils/Modals/SuccessModal";
import Roles, { RolesSelect } from "../../utils/Constants/Roles";
import { TipoAnalizadoresSelect } from "../../utils/Constants/TipoAnalizadores";
import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Form } from "react-bootstrap";

export default function RegisterUser(props) {
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn, history } = props;
  const [paymentData, setPaymentData] = useState({
    amountPerApproval: 0,
    amountPerDisapproval: 0,
    amountPerDiscard: 0,
  });
  const [error, setError] = useState([]);
  const [showAreYouSureModal, setAreYouSureModal] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [tipoAnalizadorSelected, setTipoAnalizadorSelected] = useState([]);

  const [userData, setUserData] = useState({
    name: "",
    lastname: "",
    phone: 0,
    email: "",
    password: "",
    confirmPassword: "",
    address: "",
    city: "",
    roleId: 0,
    CBU: 0,
    CUIL: 0,
    CP: 0,
  });

  const getCurrentAmounts = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get("/api/payment", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      if (response.data.amounts[0]) {
        setPaymentData({
          paymentId: response.data.amounts[0].paymentId,
          amountPerApproval: response.data.amounts[0].amountPerApproval,
          amountPerDisapproval: response.data.amounts[0].amountPerDisapproval,
          amountPerDiscard: response.data.amounts[0].amountPerDiscard,
        });
      } else {
        setPaymentData({
          paymentId: "",
        });
      }

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (props.userData.roleId === Roles.SUPERVISOR_ANALIZADORES.id) {
      setUserData({ roleId: Roles.ANALIZADOR.id });
    }
    setIsLoading(true);
    getCurrentAmounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmation = () => {
    setAreYouSureModal(false);
    setIsLoading(true);

    const {
      email,
      password,
      address,
      city,
      roleId,
      name,
      lastname,
      phone,
      CBU,
      CUIL,
      CP,
    } = userData;
    Axios.post("/api/user/register", {
      name: name,
      lastname: lastname,
      phone: phone,
      email: email,
      password: password,
      address: address === "" ? "-" : address,
      city: city === "" ? "-" : city,
      roleId: roleId,
      paymentId: paymentData.paymentId,
      CBU: CBU ? CBU.toString() : "",
      CUIL: CUIL,
      CP: CP,
      tipoAnalizadorId: tipoAnalizadorSelected.map((x) => x.id),
    })
      .then((response) => {
        setIsLoading(false);
        setConfirmationModal(true);
        setValidated(false);
        history.push("/users");
      })
      .catch((err) => {
        setError(["El email ingresado ya se encuentra registrado."]);
        setIsLoading(false);
      });
  };

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeTipoAnalizador = (event) => {
    const {
      target: { value },
    } = event;
    setTipoAnalizadorSelected(value);
  };

  const closeModal = () => {
    setAreYouSureModal(false);
    setConfirmationModal(false);
  };

  const handleCancel = () => {
    history.push("/users");
  };

  const isValid = () => {
    var isValid = true;
    var errors = [];

    if (userData.passsword && userData.password.length < 5) {
      isValid = false;
      errors.push("La contrasena debe tener como minimo 5 caracteres.");
    }

    if (userData.password !== userData.confirmPassword) {
      isValid = false;
      errors.push("Contrasena y confirmar contrasena no coinciden.");
    }

    if (
      userData.phone > 0 &&
      (userData.phone.length < 8 || userData.phone.length > 13)
    ) {
      isValid = false;
      errors.push("El telefono debe tener entre 8 y 13 digitos.");
    }

    if (userData.CBU > 0 && userData.CBU.length !== 22) {
      errors.push("El CBU debe  tener 22 digitos.");
      isValid = false;
    }

    if (
      userData.CUIL > 0 &&
      (userData.CUIL.length < 10 || userData.CUIL.length > 12)
    ) {
      errors.push("El CUIL debe tener entre 10 y 12 digitos.");
      isValid = false;
    }
    setError(errors);
    return isValid;
  };

  const handleSumbit = (event) => {
    setError([]);
    setValidated(false);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      if (
        userData.roleId == Roles.ANALIZADOR.id &&
        tipoAnalizadorSelected.length == 0
      ) {
        alert(
          "Por favor, seleccione que tipo de equipos va a analizar esta persona"
        );
      }
      return;
    }

    if (!isValid()) {
      return;
    }

    setValidated(true);

    setAreYouSureModal(true);
  };

  return (
    <div className="register-container">
      <Loader show={isLoading} />
      <div className="register-wrapper">
        <div className="register">
          <h1>Registrá un nuevo usuario</h1>

          <div className="form-register">
            <div className="form-cont">
              <Form
                noValidate
                validated={validated}
                id="form"
                onSubmit={handleSumbit}
              >
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="formGridName">
                    <Form.Label>Nombre* </Form.Label>
                    <Form.Control
                      name="name"
                      type="text"
                      required
                      value={userData.name}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="3" controlId="formGridLastname">
                    <Form.Label>Apellido*</Form.Label>
                    <Form.Control
                      name="lastname"
                      type="text"
                      required
                      value={userData.lastname}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="formGridEmail">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      name="email"
                      value={userData.email}
                      type="email"
                      required
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="formGridPassword">
                    <Form.Label>Contraseña*</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      value={userData.password}
                      required
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Confirmar Contraseña*</Form.Label>
                    <Form.Control
                      name="confirmPassword"
                      value={userData.confirmPassword}
                      type="password"
                      required
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="formGridRol">
                    <Form.Label>Rol*</Form.Label>
                    <Form.Control
                      required
                      name="roleId"
                      as="select"
                      value={userData.roleId}
                      disabled={
                        props.userData.roleId ===
                        Roles.SUPERVISOR_ANALIZADORES.id
                      }
                      custom
                      onChange={handleChange}
                    >
                      <option hidden value="">
                        Selecciona...
                      </option>
                      {RolesSelect.map((rol, i) => {
                        return (
                          <option key={i} value={rol.id}>
                            {rol.descripcion}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  {userData.roleId == Roles.ANALIZADOR.id && (
                    <Form.Group
                      as={Col}
                      className="align-self-end"
                      md="3"
                      controlId="formGridTipoAnalizador"
                    >
                      <FormControl
                        className="form-control-select"
                        variant="standard"
                      >
                        <InputLabel id="demo-tipoequipo">
                          Tipo de equipo a analizar
                        </InputLabel>

                        <Select
                          labelId="demo-tipoequipo"
                          name="tipoAnalizadorId"
                          multiple
                          required={userData.roleId == Roles.ANALIZADOR.id}
                          value={tipoAnalizadorSelected}
                          onChange={handleChangeTipoAnalizador}
                          input={
                            <OutlinedInput label="Tipo de equipo a analizar" />
                          }
                          renderValue={(selected) =>
                            selected.map((x) => x.descripcion).join(", ")
                          }
                        >
                          {TipoAnalizadoresSelect.map((rol, i) => {
                            return (
                              <MenuItem key={rol.id} value={rol}>
                                <Checkbox
                                  checked={
                                    tipoAnalizadorSelected.indexOf(rol) > -1
                                  }
                                />
                                <ListItemText primary={rol.descripcion} />
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Form.Group>
                  )}
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="3" controlId="formGridPhone">
                    <Form.Label>Teléfono </Form.Label>
                    <Form.Control
                      value={userData.phone}
                      name="phone"
                      type="number"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>CBU </Form.Label>
                    <Form.Control
                      value={userData.CBU}
                      name="CBU"
                      type="number"
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="3">
                    <Form.Label>CUIL </Form.Label>
                    <Form.Control
                      value={userData.CUIL}
                      name="CUIL"
                      type="number"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group controlId="formGridAddress1" as={Col} md="3">
                    <Form.Label>Dirección</Form.Label>
                    <Form.Control
                      name="address"
                      value={userData.address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formGridAddress1" as={Col} md="3">
                    <Form.Label>Código Postal</Form.Label>
                    <Form.Control
                      name="CP"
                      value={userData.CP}
                      type="number"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="formGridCity">
                    <Form.Label>Ciudad</Form.Label>
                    <Form.Control
                      name="city"
                      value={userData.city}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>

                {error.map((errorDescription, i) => {
                  return (
                    <Form.Row key={i}>
                      <span className="error">{errorDescription}</span>
                    </Form.Row>
                  );
                })}

                <Form.Row>
                  <Button
                    variant="secondary"
                    form="form"
                    className="btn-gris"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>

                  <Button
                    variant="primary"
                    form="form"
                    className="btn-celeste"
                    type="submit"
                  >
                    Registrar
                  </Button>
                </Form.Row>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <AreYouSureModal
        show={showAreYouSureModal}
        handleConfirmation={handleConfirmation}
        onHide={closeModal}
        text={"Esta seguro que desea registrar un nuevo usuario? "}
      />
      <SuccessModal
        show={showConfirmationModal}
        onHide={closeModal}
        text={"Se ha registrado con exito"}
      ></SuccessModal>
    </div>
  );
}
