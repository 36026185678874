import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Equipments.scss";
import { AreYouSureModal } from "../../utils/Modals/AreYouSureModal";
import { SuccessModal } from "../../utils/Modals/SuccessModal";
import { ErrorModal } from "../../utils/Modals/ErrorModal";
import Roles from "../../utils/Constants/Roles";

import Loader from "../../utils/Loader/Loader";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/Col";
import { Row } from "react-bootstrap";

export default function AddEditEquipment(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn, history } = props;
  const [validated, setValidated] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [municipalities, setMunicipalities] = useState([]);
  const [equipment, setEquipment] = useState({
    coordinates: "",
    municipalityId: "",
    streetCode: "",
    serialNumber: "",
    street: "",
    streetNumber: "",
    velocityAllowed: 0,
    ip: "",
    brand: "",
    model: "",
    isWorking: "",
    equipmentTypeId: "",
    typeOfSystem: "",
    description: "",
    authorityId: "0009",
    way: "",
    projectId: "",
    equipmentId: 0,
    lastReception: Date.now(),
  });
  const [showAreYouSureModal, setAreYouSureModal] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState({
    visible: false,
    mensaje: "",
  });
  const id = props.match.params.id;
  const canEdit = !isEdit
    ? true
    : props.userData.roleId === Roles.ADMIN.id
    ? true
    : false;

  const handleConfirmation = async () => {
    setAreYouSureModal(false);
    setIsLoading(true);
    if (equipment.streetNumber === "") equipment.streetNumber = null;
    if (isEdit) {
      await updateEquipment();
    } else {
      await createEquipment();
    }
  };

  const handleSubmit = (event) => {
    setValidated(false);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    setAreYouSureModal(true);
  };

  const handleChange = (event) => {
    var value = event.target.value;
    var name = event.target.name;
    if (name === "equipmentTypeId" || name === "isWorking") {
      value = parseInt(value, 10);
    }

    setEquipment({
      ...equipment,
      [name]: value,
    });
  };

  const getAuthToken = async () => {
    const authToken = await props.checkLoggedIn();
    if (!authToken) {
      props.history.push("/");
      return;
    }
    return authToken;
  };

  const closeModal = () => {
    setAreYouSureModal(false);
    if (showConfirmationModal) {
      setConfirmationModal(false);
      history.push("/equipments");
    }
  };

  const getEquipment = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/equipment/${id}`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setIsLoading(false);
      setEquipment(response.data.equipment);
    });
  };

  const handleCancel = () => {
    history.push("/equipments");
  };

  const getMunicipalities = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get("/api/municipality/", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setMunicipalities(response.data.municipalities);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      setIsLoading(true);
      getEquipment();
    }
    getMunicipalities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="equipments-container">
      <Loader show={isLoading} />

      <div className="equipments-wrapper">
        <div className="ade-header">
          <h1>{isEdit ? "Editá el equipo" : "Registrá un nuevo equipo"}</h1>
        </div>
        <Row>
          <Col md={8} sm={12}>
            <div className="form-cont">
              <Form
                noValidate
                validated={validated}
                id="form"
                onSubmit={handleSubmit}
              >
                <Form.Row>
                  <Form.Group
                    as={Col}
                    md={equipment.equipmentTypeId === 1 ? 3 : 4}
                  >
                    <Form.Label>Tipo de equipo*</Form.Label>
                    <Form.Control
                      required
                      name="equipmentTypeId"
                      as="select"
                      disabled={!canEdit}
                      value={equipment.equipmentTypeId}
                      onChange={handleChange}
                    >
                      <option hidden value="">
                        Selecciona...
                      </option>
                      <option value="1">Velocidad</option>
                      <option value="2">Semáforo</option>
                    </Form.Control>
                  </Form.Group>

                  {equipment.equipmentTypeId === 1 ? (
                    <Form.Group
                      as={Col}
                      md={equipment.equipmentTypeId === 1 ? 3 : 4}
                    >
                      <Form.Label>Velocidad permitida</Form.Label>
                      <Form.Control
                        name="velocityAllowed"
                        value={equipment.velocityAllowed}
                        type="number"
                        disabled={!canEdit}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  ) : null}
                  <Form.Group
                    as={Col}
                    md={equipment.equipmentTypeId === 1 ? 3 : 4}
                  >
                    <Form.Label>Equipo ID *</Form.Label>
                    <Form.Control
                      name="equipmentId"
                      value={equipment.equipmentId}
                      type="number"
                      required
                      disabled={isEdit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md={equipment.equipmentTypeId === 1 ? 3 : 4}
                  >
                    <Form.Label>Número de serie *</Form.Label>
                    <Form.Control
                      name="serialNumber"
                      value={equipment.serialNumber}
                      disabled={!canEdit}
                      type="text"
                      required
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="4" controlId="formGridName">
                    <Form.Label>Calle* </Form.Label>
                    <Form.Control
                      required
                      name="street"
                      type="text"
                      disabled={!canEdit}
                      value={equipment.street}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="2" controlId="formGridLastname">
                    <Form.Label>Número</Form.Label>
                    <Form.Control
                      name="streetNumber"
                      disabled={!canEdit}
                      type="text"
                      value={equipment.streetNumber}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Municipalidad*</Form.Label>
                    <Form.Control
                      required
                      name="municipalityId"
                      as="select"
                      disabled={!canEdit}
                      value={equipment.municipalityId}
                      onChange={handleChange}
                    >
                      <option hidden value="">
                        Selecciona...
                      </option>

                      {municipalities.map((municipality, index) => (
                        <option key={index} value={municipality.municipalityId}>
                          {municipality.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="3" controlId="formGridEmail">
                    <Form.Label>Coordenadas</Form.Label>
                    <Form.Control
                      name="coordinates"
                      value={equipment.coordinates}
                      type="text"
                      disabled={!canEdit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Marca *</Form.Label>
                    <Form.Control
                      required
                      name="brand"
                      value={equipment.brand}
                      type="text"
                      disabled={!canEdit}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Modelo *</Form.Label>
                    <Form.Control
                      required
                      name="model"
                      value={equipment.model}
                      disabled={!canEdit}
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Estado del equipo * </Form.Label>
                    <Form.Control
                      required
                      name="isWorking"
                      as="select"
                      value={equipment.isWorking}
                      onChange={handleChange}
                    >
                      <option hidden value="">
                        Selecciona...
                      </option>
                      <option value="1">Funciona</option>
                      <option value="0">No funciona</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Sentido * </Form.Label>
                    <Form.Control
                      required
                      name="way"
                      as="select"
                      disabled={!canEdit}
                      value={equipment.way}
                      onChange={handleChange}
                    >
                      <option hidden value="">
                        Selecciona...
                      </option>
                      <option value="A">Ascendente</option>
                      <option value="D">Descendente</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Tipo de sistema</Form.Label>
                    <Form.Control
                      name="typeOfSystem"
                      as="select"
                      disabled={!canEdit}
                      value={equipment.typeOfSystem}
                      onChange={handleChange}
                    >
                      <option hidden value="">
                        Selecciona...
                      </option>
                      <option value="hibryd">Híbrido</option>
                      <option value="new">Nuevo</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md="3">
                    <Form.Label>Dirección IP</Form.Label>
                    <Form.Control
                      name="ip"
                      disabled={!canEdit}
                      value={equipment.ip}
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Código de calle ID</Form.Label>
                    <Form.Control
                      name="streetCode"
                      disabled={!canEdit}
                      value={equipment.streetCode}
                      type="number"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="3">
                    <Form.Label>Proyecto ID </Form.Label>
                    <Form.Control
                      name="projectId"
                      disabled={!canEdit}
                      value={equipment.projectId}
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control
                      name="description"
                      as="textarea"
                      rows="2"
                      value={equipment.description}
                      type="text"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row style={{ justifyContent: "flex-end" }}>
                  <Button
                    variant="secondary"
                    form="form"
                    className="btn-gris"
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>

                  <Button
                    variant="primary"
                    form="form"
                    className="btn-celeste"
                    type="submit"
                  >
                    Aceptar
                  </Button>
                </Form.Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      <img
        className="illustration"
        src={require("../../../assets/images/admin/fondoAddEditEquipment.png")}
        alt="wallet"
      />

      <AreYouSureModal
        show={showAreYouSureModal}
        handleConfirmation={handleConfirmation}
        onHide={closeModal}
        text={
          isEdit
            ? "¿Está seguro que desea editar este equipo? "
            : "¿Está seguro que desea agregar un nuevo equipo?"
        }
      />
      <SuccessModal
        show={showConfirmationModal}
        onHide={closeModal}
        text={
          isEdit
            ? "Se ha editado el equipo con éxito"
            : "Se ha agregado el equipo con éxito"
        }
      ></SuccessModal>
      <ErrorModal
        show={showErrorModal.visible}
        onHide={() => setShowErrorModal({ visible: false })}
        text={showErrorModal.mensaje}
      ></ErrorModal>
    </div>
  );

  async function createEquipment(headers) {
    const authToken = await getAuthToken();
    await Axios.post(`/api/equipment`, equipment, {
      headers: { "auth-token": authToken },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setConfirmationModal(true);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        showErrorEquipment(error);
      });
  }

  async function updateEquipment() {
    const id = props.match.params.id;
    const authToken = await getAuthToken();
    await Axios.put(`/api/equipment/${id}`, equipment, {
      headers: { "auth-token": authToken },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setConfirmationModal(true);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
        showErrorEquipment(error);
      });
  }

  function showErrorEquipment(error) {
    if (error.response.status === 409) {
      setShowErrorModal({
        visible: true,
        mensaje:
          "El ID del equipo o el número de serie ya se encuentra en el sistema.",
      });
    } else {
      setShowErrorModal({
        visible: true,
        mensaje: "Intente nuevamente más tarde.",
      });
    }
  }
}
