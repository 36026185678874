import React from "react";
import ImageListItem from "./ImageListItem.jsx";

export default class ImageList extends React.Component {
  removeIndexFromQueue(i) {
    this.props.removeIndexFromQueue(i);
  }
  render() {
    let listItems = [];
    for (let i = 0; i < 4; i++) {
      listItems[i] = (
        <ImageListItem
          src={
            this.props.images[i]
              ? this.props.images[i]
              : require("../../../../assets/images/analyzer/image-placeholder.png")
          }
          showGenericImage={this.props.images[i] ? false : true}
          key={i}
          removeIndexFromQueue={(i) => {
            this.removeIndexFromQueue(i);
          }}
          changeMainImage={(i) => this.props.changeMainImage(i)}
          imageIndex={i}
          indexMainImage={this.props.indexMainImage}
          totalImages={this.props.images.length}
          errorPatente={this.props.errorPatente}
          onChangePantente={this.props.handleChangePatente}
          infoToPost={this.props.infoToPost}
          patenteDuplicada={this.props.patenteDuplicada}
        />
      );
    }
    return <div className="image-list-container">{listItems}</div>;
  }
}
