import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Equipments.scss";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import EquipmentModal from "./Modals/EquipmentModal";

export default function Equipments(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn, history } = props;
  const [equipments, setEquipments] = useState([]);
  const [equipmentSelected, setEquipmentSelected] = useState({});
  const [showEquipmentModal, setShowEquipmentModal] = useState(false);
  const [municipalities, setMunicipalities] = useState([]);

  const setDataTable = (response) => {
    var aux;
    let array = [];
    response.forEach((equipment) => {
      const reviews = equipment.Reviews.filter(
        (review) => review.statusId === 1 || review.statusId === 3
      );
      const repairs = equipment.Repairs.filter(
        (repair) => repair.statusId === 1
      );
      let streetNumber = "";
      if (equipment.streetNumber) streetNumber = equipment.streetNumber;
      aux = {
        reviews: reviews,
        repairs: repairs,
        equipmentId: equipment.equipmentId,
        serialNumber: equipment.serialNumber,
        brand_model: equipment.brand + " " + equipment.model,
        isWorking: equipment.isWorking,
        coordinates: equipment.coordinates,
        ip: equipment.ip,
        municipalityId: equipment.municipalityId,
        typeOfSystem: equipment.typeOfSystem,
        equipmentType: equipment.EquipmentType?.description,
        address: equipment.street + " " + streetNumber,
        municipality: equipment.Municipality?.municipalityId ?? 0,
      };
      array.push(aux);
    });
    if (props.location.state) {
      array = array.filter(
        (equipment) =>
          equipment.municipalityId === props.location.state.municipalityId
      );
    }
    setEquipments(equipments.concat(array));
  };

  const handleMoreDetails = (rowData) => {
    setEquipmentSelected(rowData);
    setShowEquipmentModal(true);
  };

  const closeModal = () => {
    setShowEquipmentModal(false);
  };

  const getEquipments = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get("/api/equipment/", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setDataTable(response.data.equipments);
      setIsLoading(false);
    });
  };

  const getMunicipalities = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get("/api/municipality", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setMunicipalities(response.data.municipalities);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getEquipments();
    getMunicipalities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleDelete = () => { }
  const handleEdit = (rowData) => {
    history.push(`/equipments/edit/${rowData.equipmentId} `);
  };

  return (
    <div className="equipments-container">
      <div className="equipments-wrapper">
        <div className="header-equipments">
          <div className="text-header">
            <h1>Obtené información sobre los equipos</h1>
            <h2>Podés dar de alta o editar un equipo</h2>
          </div>
          <div className="button-header">
            <Button className="btn-celeste" href="/equipments/add">
              Nuevo equipo
            </Button>
          </div>
        </div>
        <div className="header">
          <h1>Equipos</h1>
        </div>
        <MaterialTable
          columns={[
            { title: "ID de equipo", field: "equipmentId", filtering: false },
            { title: "N° de serie", field: "serialNumber", filtering: false },
            { title: "Modelo", field: "brand_model", filtering: false },
            {
              title: "Dirección",
              field: "address",
              filtering: false,
              cellStyle: { minWidth: 270 },
              headerStyle: { minWidth: 270 },
            },
            {
              title: "Municipio",
              field: "municipality",
              lookup: municipalities.reduce(function (acc, cur, i) {
                acc[cur.municipalityId] = cur.name;
                return acc;
              }, {}),
            },
            {
              title: "Tipo de equipo",
              field: "equipmentType",
              lookup: { Semaforo: "Semaforo", Velocidad: "Velocidad" },
            },
            {
              title: "Estado",
              field: "isWorking",
              lookup: { 0: "No funciona", 1: "Funciona" },
              render: (rowData) => {
                return rowData.isWorking ? (
                  <span className="finalizada status">Funcionando</span>
                ) : (
                  <span className="no-realizada status">No funciona</span>
                );
              },
            },
          ]}
          data={equipments}
          isLoading={isLoading}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
            },
            toolbar: {
              nRowsSelected: "{0} fila(s) seleccionadas",
            },
            header: {
              actions: " ",
            },
            body: {
              emptyDataSourceMessage: "No hay registros para mostrar",
              filterRow: {
                filterTooltip: "Filtro",
              },
            },
          }}
          actions={[
            {
              icon: "info",
              tooltip: "Detalle",
              onClick: (event, rowData) => handleMoreDetails(rowData),
            },
            {
              icon: "edit",
              tooltip: "Editar",
              onClick: (event, rowData) => handleEdit(rowData),
            },
            // {
            //     icon: 'delete',
            //     tooltip: 'Eliminar',
            //     onClick: (event, rowData) => handleDelete(rowData)
            // }
          ]}
          title=" "
          options={{
            actionsColumnIndex: -1,
            filtering: true,
          }}
        />
      </div>
      <img
        className="wave"
        src={require("../../../assets/images/waves.png")}
        alt=""
      />
      <img
        className="background-top"
        src={require("../../../assets/images/admin/equipment.png")}
        alt=""
      />
      <EquipmentModal
        show={showEquipmentModal}
        onHide={closeModal}
        equipment={equipmentSelected}
      />
    </div>
  );
}
