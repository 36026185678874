import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { Checkbox, FormControlLabel, Icon } from "@material-ui/core";
import Moment from "moment";
import Axios from "axios";
export default function AddUserMunicipalityModal(props) {
  const [usersChecked, setUsersChecked] = useState([]);
  const { checkLoggedIn, ...rest } = props;

  const onCheckBoxChange = (e, u) => {
    let aux = {
      id: u.id,
      checked: e.target.checked,
    };

    if (usersChecked.filter((x) => x.id === u.id).length === 0) {
      const updateUsers = [...usersChecked, aux];
      setUsersChecked(updateUsers);
    } else {
      const updatedObject = usersChecked.map((user) =>
        user.id === u.id ? aux : user
      );

      setUsersChecked(updatedObject);
    }
  };

  const seleccionarTodos = (e) => {
    if (e.target.checked) {
      let aux,
        updateUsers = [];
      props.users.map((x) => {
        aux = {
          id: x.id,
          checked: true,
        };
        updateUsers.push(aux);
      });
      setUsersChecked(updateUsers);
    } else {
      let aux,
        updateUsers = [];
      props.users.map((x) => {
        aux = {
          id: x.id,
          checked: false,
        };
        updateUsers.push(aux);
      });
      setUsersChecked(updateUsers);
    }
  };

  const guardar = async () => {
    const authToken = await checkLoggedIn();
    await Axios.post(
      `/api/municipality/${props.municipality.municipalityId}/users`,
      usersChecked,
      {
        headers: { "auth-token": authToken },
      }
    ).then(() => {
      props.onHide();
    });
  };

  const loadUsers = () => {
    let aux,
      updateUsers = [];
    props.municipality.Users.map((x) => {
      aux = {
        id: x.id,
        checked: true,
      };
      updateUsers.push(aux);
    });
    setUsersChecked(updateUsers);
  };

  return (
    <div>
      <Modal
        onEntered={() => loadUsers()}
        size="lg"
        {...rest}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <h4 className="mt-4 mb-4">
              Seleccione que analizadores van a poder editar videos de{" "}
              {props.municipality.name}
            </h4>
            <Row>
              {props.users && props.municipality && props.municipality.Users
                ? props.users.map((user) => (
                    <Col md={4} key={user.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              usersChecked.filter(
                                (x) => x.id == user.id && x.checked
                              ).length == 1
                            }
                            onChange={(e) => onCheckBoxChange(e, user)}
                            name={user.id.toString()}
                            color="primary"
                          />
                        }
                        label={user.name + " " + user.lastname}
                      />
                    </Col>
                  ))
                : null}
              <Col md={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        usersChecked.filter((x) => x.checked).length ===
                        props.users.length
                      }
                      onChange={(e) => seleccionarTodos(e)}
                      name="all"
                      color="primary"
                    />
                  }
                  label="Todos"
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn-gris"
            onClick={() => props.onHide()}
          >
            Cancelar
          </Button>
          <Button
            variant="primary"
            className="btn-azul"
            onClick={() => guardar()}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
