import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./EquipmentStatus.scss";
import { Row, Col } from "react-bootstrap";
import Loader from "../../../utils/Loader/Loader";

export default function EquipmentStatus(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn, history } = props;
  const [municipalities, setMunicipalities] = useState([]);

  const getEquipments = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/equipment/byMunicipality`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setMunicipalities(response.data.equipments);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (municipality) => {
    return history.push({
      pathname: "/equipments",
      state: { municipalityId: municipality.municipalityId },
    });
  };
  return (
    <div className="equipment-container">
      <Loader show={isLoading} />
      <div className="equipment-status">
        <div className="es-header">
          <h1>Estado de los equipos</h1>
          <h2>Controlá cuantos equipos funcionan o no según municipio.</h2>
        </div>
        <Row>
          {municipalities.map((municipality) => (
            <Col md={4} key={municipality.municipalityId}>
              <div
                className="card-equipment"
                onClick={(e) => handleClick(municipality)}
              >
                <h3>{municipality.Municipality?.name ?? " - "}</h3>
                <Row>
                  <Col
                    md={6}
                    style={{ borderRight: "2px solid #c5c5c5", color: "green" }}
                  >
                    <h4>{municipality.areWorking}</h4>
                    <p>Funcionando</p>
                  </Col>
                  <Col md={6} style={{ color: "#d30000" }}>
                    <h4>{municipality.notWorking}</h4>
                    <p>Fallan</p>
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <img
        className="wave"
        src={require("../../../../assets/images/waves.png")}
        alt=""
      />
    </div>
  );
}
