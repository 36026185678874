import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./MyAccount.scss";
import { Button } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { SuccessModal } from "../../utils/Modals/SuccessModal";
import Loader from "../../utils/Loader/Loader";
import { AreYouSureModal } from "../../utils/Modals/AreYouSureModal";
var validator = require("email-validator");

export default function MyAccount(props) {
    const [userData, setUserData] = useState({
        email: "",
        name: "",
        lastname: "",
        phone: "",
        city: "",
        address: "",
    });
    const [isEditing, setisEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showAreYouSureModal, setAreYouSureModal] = useState(false);
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const { checkLoggedIn, history } = props;
    const [error, setError] = useState({
        email: false,
        name: false,
        lastname: false,
        phone: false,
        textEmail: ""
    });
    const getUser = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get(`/api/user/${props.userData.id}`, {
            headers: { "auth-token": authToken },
        }).then((response) => {
            if (response.data.user) {
                setUserData({
                    id: response.data.user.id,
                    email: response.data.user.email,
                    name: response.data.user.name,
                    lastname: response.data.user.lastname,
                    address: response.data.user.address,
                    city: response.data.user.city,
                    phone: response.data.user.phone,
                    CBU: response.data.user.CBU,
                    CUIL: response.data.user.CUIL,
                });
                setIsLoading(false);
            }
        }).catch(err => {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isValid = () => {
        let isValid = true;
        let errorEmail = false;
        let errorName = false;
        let errorLastname = false;
        let errorPhone = false;
        let errorCBU = false;
        let errorCUIL = false;
        let errorTextEmail = "";

        if (!userData.email) {
            errorEmail = true;
            errorTextEmail = "Este campo está vacio"
            isValid = false;
        } else if (!validator.validate(userData.email)) {
            errorTextEmail = "Este no es un email valido"
            errorEmail = true;
            isValid = false;
        }
        if (!userData.name) {
            errorName = true;
            isValid = false;

        }
        if (!userData.lastname) {
            errorLastname = true;
            isValid = false;

        }
        if (!userData.phone) {
            errorPhone = true;
            isValid = false;

        }

        if (!userData.CBU) {
            errorCBU = true;
            isValid = false;

        }

        if (!userData.CUIL) {
            errorCUIL = true;
            isValid = false;

        }

        setError({
            email: errorEmail,
            lastname: errorLastname,
            name: errorName,
            phone: errorPhone,
            textEmail: errorTextEmail,
            CBU: errorCBU,
            CUIL: errorCUIL
        });

        return isValid;
    };

    const handleSubmit = (event) => {
        setError({
            email: false,
            name: false,
            lastname: false,
            phone: false,
            textEmail: "",
            CBU: false,
            CUIL: false
        });

        if (!isValid()) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        event.preventDefault();



        setAreYouSureModal(true);

    };

    const editeAccount = () => {
        if (isEditing)
            setisEditing(false);
        else
            setisEditing(true);
    }

    const closeModal = () => {
        setAreYouSureModal(false);
        setConfirmationModal(false);
    }

    const handleConfirmation = () => {
        setisEditing(false);
        setIsLoading(true);
        setAreYouSureModal(false);
        const updateAccount = async () => {
            const authToken = await checkLoggedIn();
            if (!authToken) {
                return history.push("/");
            }
            await Axios.put("/api/user/", userData, {

                headers: { "auth-token": authToken },
            }).then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    setConfirmationModal(true);
                } else {
                    window.alert("error");
                }


            });
        };
        updateAccount();

    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setUserData({
            ...userData,
            [name]: value
        });

    };

    return (
        <div>
            <Loader show={isLoading} />
            <div className="account-container">
                <div className="account-wrapper">
                    <img className="illustration" src={require("../../../assets/images/micuenta.png")} alt="car" />
                    <div className="perfil">
                        {props.userData.roleId === 1 ?
                            <h1>Editá tu perfil</h1>
                            : <h1>Mi cuenta</h1>}
                        <div className="form-perfil">
                            <form id='form' autoComplete="off" >
                                <TextField label="Nombre" name="name" value={userData.name ? userData.name : ""}
                                    onChange={handleChange} disabled={!isEditing}
                                    required error={error.name} helperText={error.name ? "Este campo es obligatorio" : ""} />

                                <TextField label="Apellido" name="lastname" value={userData.lastname ? userData.lastname : ""}
                                    onChange={handleChange} disabled={!isEditing} required error={error.lastname}
                                    helperText={error.lastname ? "Este campo es obligatorio" : ""} />

                                <TextField label="Email" name="email" id="email" type="email" value={userData.email ? userData.email : ""}
                                    onChange={handleChange} disabled={!isEditing} required error={error.email}
                                    helperText={error.textEmail} />

                                <TextField label="Teléfono" name="phone"
                                    type="number" value={userData.phone ? userData.phone : ""}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                    inputProps={{ maxLength: 12, minLength: 8 }}
                                    required
                                    error={error.phone}
                                    helperText={error.phone ? "Este campo es obligatorio" : ""} />
                                <TextField label="Domicilio" name="address" value={userData.address ? userData.address : ""}
                                    onChange={handleChange} disabled={!isEditing} />
                                <TextField label="Localidad" name="city" value={userData.city ? userData.city : ""}
                                    onChange={handleChange} disabled={!isEditing} />

                                <TextField label="CBU" name="CBU" id="cbu"
                                    type="number" value={userData.CBU ? userData.CBU : ""}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                    inputProps={{ maxLength: 22, minLength: 22 }}
                                    required
                                    error={error.CBU}
                                    helperText={error.CUIL ? "Este campo es obligatorio" : ""} />

                                <TextField label="CUIL" name="CUIL"
                                    type="number" value={userData.CUIL ? userData.CUIL : ""}
                                    onChange={handleChange}
                                    disabled={!isEditing}
                                    inputProps={{ maxLength: 12, minLength: 10 }}
                                    required
                                    error={error.CUIL}
                                    helperText={error.CUIL ? "Este campo es obligatorio" : ""} />
                            </form>
                        </div>
                        {props.userData.roleId === 1 ?
                            <Button className="btn-celeste" type="submit"
                                onClick={(isEditing) ? handleSubmit : editeAccount}> {(isEditing) ? "Aceptar" : "Editar perfil"}
                            </Button>
                            : <Button className="btn-celeste" type="submit"
                                href="/changePassword">Cambiar contraseña
                            </Button>}
                    </div>
                </div>
                <AreYouSureModal
                    show={showAreYouSureModal}
                    handleConfirmation={handleConfirmation}
                    onHide={closeModal}
                    text={"¿Está seguro que desea editar su perfil?"}
                />
                <SuccessModal
                    show={showConfirmationModal}
                    onHide={closeModal}
                    text={"Se ha editado con éxito el perfil"}
                ></SuccessModal>

            </div>
        </div>);
}
