import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../Review.scss";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Icon } from "@material-ui/core";
import Loader from "../../../utils/Loader/Loader";
import Moment from "moment";
import { SuccessModal } from "../../../utils/Modals/SuccessModal";
import { AreYouSureModal } from "../../../utils/Modals/AreYouSureModal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function ReviewSecondStep(props) {
  const [lastPosition, setLastPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn, history } = props;
  const [validated, setValidated] = useState(false);
  const [showAreYouSureModal, setAreYouSureModal] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [equipmentSelected, setEquipmentSelected] = useState({});
  const [error, setError] = useState("");
  const [technicians, setTechnicians] = useState([]);
  const [problems, setProblems] = useState([]);
  const [problemsChecked, setProblemsChecked] = useState([]);
  const [infoReview, setInfoReview] = useState({
    equipmentId: props.match.params.equipmentId,
    technicianOne: "",
    technicianTwo: "",
    descriptionProblem: "",
    date: Moment(Date.now()).format("YYYY-MM-DD"),
    statusId: 1,
  });

  const handleCancel = () => {
    history.push("/review/start");
  };

  const closeModal = () => {
    setAreYouSureModal(false);
    if (setConfirmationModal) {
      setConfirmationModal(false);
      history.push("/review");
    }
  };

  const handleConfirmation = () => {
    setAreYouSureModal(false);
    setIsLoading(true);
    const infoToPost = { infoReview, problemsChecked };
    const createReview = async () => {
      const authToken = await checkLoggedIn();
      if (!authToken) {
        return history.push("/");
      }
      await Axios.post("/api/review/", infoToPost, {
        headers: { "auth-token": authToken },
      })
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            setConfirmationModal(true);
          }
        })
        .catch((err) => {
          setError("Ha ocurrido un error.");
          setIsLoading(false);
        });
    };
    createReview();
  };

  const handleFinish = (event) => {
    setError("");
    setValidated(false);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    if (infoReview.technicianOne === infoReview.technicianTwo) {
      event.preventDefault();
      event.stopPropagation();
      setError("Los técnicos seleccionados deben ser distintos.");
      return;
    }

    if (!atLeastOneProblemChecked()) {
      event.preventDefault();
      event.stopPropagation();
      setError("Por favor, seleccione aunque sea un posible problema.");
      return;
    }

    setValidated(true);

    setAreYouSureModal(true);
  };

  const atLeastOneProblemChecked = () => {
    var checked = false;
    for (var value of Object.values(problemsChecked)) {
      if (value) {
        checked = true;
        return checked;
      }
    }
    return checked;
  };

  const handleChange = (event) => {
    setInfoReview({
      ...infoReview,
      [event.target.name]: event.target.value,
    });
  };

  const onProblemChange = (event) => {
    setProblemsChecked({
      ...problemsChecked,
      [event.target.name - 1]: event.target.checked,
    });
  };
  const getEquipment = async () => {
    const id = props.match.params.equipmentId;
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/equipment/${id}`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setEquipmentSelected(response.data.equipment);
    });
  };

  const getTechnicians = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/user/technicians`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setTechnicians(response.data.users);
    });
  };

  const getReviewProblems = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/review/problems`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setProblems(response.data.problems);
      let array = [];
      response.data.problems.map(() => array.push(false));
      setProblemsChecked(array);
      setLastPosition(array.length - 1);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getEquipment();
    getTechnicians();
    getReviewProblems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="review">
      <Loader show={isLoading} />
      <div className="review-container">
        <Row style={{ height: "80vh" }}>
          <Col md={5} sm={12} style={{ display: "flex" }}>
            {equipmentSelected && equipmentSelected.Municipality ? (
              <div className="description-equipment">
                <Icon className="icon-de">developer_board</Icon>
                <h2>
                  {equipmentSelected.brand} {equipmentSelected.model}{" "}
                </h2>
                <h3>
                  {equipmentSelected.street} {equipmentSelected.streetNumber},{" "}
                  {equipmentSelected.Municipality?.name ?? " - "}
                </h3>
                <p>
                  <Icon className="icons">api</Icon>Estado:{" "}
                  {equipmentSelected.isWorking ? (
                    <span style={{ color: "#CCFFBA", fontWeight: "bold" }}>
                      Funcionando
                    </span>
                  ) : (
                    <span style={{ color: "#FF7272", fontWeight: "bold" }}>
                      No funciona
                    </span>
                  )}
                </p>
                <p>
                  <Icon className="icons">broken_image</Icon>Nro de serie:{" "}
                  <span style={{ fontWeight: "500" }}>
                    {" "}
                    {equipmentSelected.serialNumber}
                  </span>
                </p>
                <p>
                  <Icon className="icons">settings_input_antenna</Icon>Tipo de
                  equipo:{" "}
                  <span style={{ fontWeight: "500" }}>
                    {equipmentSelected.EquipmentType?.description}
                  </span>
                </p>
              </div>
            ) : null}
          </Col>
          <Col md={7} sm={12}>
            <div className="header">
              <div className="text-header" style={{ marginTop: "2.5rem" }}>
                <h1>Dar de alta una revisión</h1>
              </div>
            </div>
            <div className="step-description" style={{ marginBottom: "3rem" }}>
              <p>2. Completá los datos</p>
            </div>
            <Form
              noValidate
              validated={validated}
              id="form"
              onSubmit={handleFinish}
            >
              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Técnico a cargo</Form.Label>
                  <Form.Control
                    name="technicianOne"
                    as="select"
                    required
                    value={infoReview.technicianOne}
                    onChange={handleChange}
                  >
                    <option hidden value="">
                      Selecciona...
                    </option>

                    {technicians.map((technician) => (
                      <option value={technician.id} key={technician.id}>
                        {technician.name} {technician.lastname}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Técnico a cargo</Form.Label>

                  <Form.Control
                    name="technicianTwo"
                    as="select"
                    required
                    value={infoReview.technicianTwo}
                    onChange={handleChange}
                  >
                    <option hidden value="">
                      Selecciona...
                    </option>

                    {technicians.map((technician) => (
                      <option value={technician.id} key={technician.id}>
                        {technician.name} {technician.lastname}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label>Fecha de revisión</Form.Label>
                  <Form.Control
                    name="date"
                    type="date"
                    required
                    min={Moment(Date.now()).format("YYYY-MM-DD")}
                    value={infoReview.date}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group controlId="formBasicCheckbox">
                <Form.Label>Problemas</Form.Label>

                <Row className="checkbox">
                  {problems.map((problem, index) => (
                    <Col md={4} sm={6} key={index}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={onProblemChange}
                            name={problem.reviewProblemsId.toString()}
                            color="primary"
                          />
                        }
                        label={problem.description}
                      />
                    </Col>
                  ))}
                </Row>
                {problemsChecked[lastPosition] ? (
                  <Row>
                    <Form.Group as={Col} md="12">
                      <Form.Label>Descripción</Form.Label>
                      <Form.Control
                        name="descriptionProblem"
                        as="textarea"
                        required={problemsChecked[lastPosition]}
                        rows="3"
                        type="text"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Row>
                ) : null}
              </Form.Group>
              {error ? (
                <Form.Row>
                  <span className="error">{error}</span>
                </Form.Row>
              ) : null}
              <Form.Row>
                <div className="btn-container">
                  <Button className="btn-celeste" type="submit">
                    Finalizar
                  </Button>
                  <Button
                    variant="secondary"
                    className="btn-gris"
                    onClick={handleCancel}
                  >
                    Atras
                  </Button>
                </div>
              </Form.Row>
            </Form>
          </Col>
        </Row>

        <AreYouSureModal
          show={showAreYouSureModal}
          handleConfirmation={handleConfirmation}
          onHide={closeModal}
          text={"¿Está seguro que desea dar de alta esta revisión?"}
        />
        <SuccessModal
          show={showConfirmationModal}
          onHide={closeModal}
          text={"La revisión se ha dado de alta con éxito"}
        ></SuccessModal>
      </div>
      <img
        className="wave-left"
        src={require("../../../../assets/images/supervisor/wave-left.png")}
        alt=""
      />
    </div>
  );
}
