import "./auth.scss";
import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { AES, enc } from "crypto-js";
export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      loginErrors: "",
      invalidUser: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.appCanRun = this.appCanRun.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ invalidUser: false });
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    //TODO: Validar campos y mostrar mensaje de error
    if (await this.appCanRun()) {
      const { email, password } = this.state;

      axios
        .post("/api/user/login", {
          email: email,
          password: password,
        })
        .then((response) => {
          if (response.data.status === "authenticated") {
            this.props.handleSuccessfulAuth(response.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.setState({ invalidUser: true });
          }
          console.error("login error", err);
        });
    }
  }

  async appCanRun() {
    let val = false;
    await axios
      .get(
        "https://us-central1-api-validacion-webapp.cloudfunctions.net/app/validate"
      )
      .then((response) => {
        let password = "d6F3Efeq";
        const result = AES.decrypt(response.data, password);
        if (result.toString(enc.Utf8).startsWith("true")) val = true;
      })
      .catch((err) => {});
    return val;
  }

  render() {
    return (
      <div>
        <div className="accountbg"></div>
        <div className="wrapper-page">
          <div className="card">
            <div className="card-body">
              <div className="p-3">
                <h3 className="text-center m-0">
                  {/* <Link to='/' className='logo logo-admin'>
                                        <img
                                            src={require("../../../assets/images/logo3.png")}

                                            alt='logo'
                                        />
                                    </Link> */}
                </h3>
                <p className="text-muted text-center">
                  Inicie sesión para continuar.
                </p>
                <form
                  className="form-horizontal m-t-30"
                  id="form"
                  onSubmit={this.handleSubmit}
                  validate="true"
                >
                  <div className="form-group">
                    <label>Correo Electrónico</label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      required
                      placeholder="Ingrese email"
                    />
                  </div>

                  <div className="form-group">
                    <label>Contraseña</label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      required
                      placeholder="Ingrese contraseña"
                    />
                  </div>
                  <span
                    className={this.state.invalidUser ? "error-msg" : "hidden"}
                  >
                    El correo o la contraseña es incorrecto.
                  </span>

                  <div
                    className="form-group m-t-10 mb-0 "
                    style={{ display: "flow-root" }}
                  >
                    <Button
                      variant="contained"
                      form="form"
                      type="submit"
                      className="button-login"
                    >
                      {" "}
                      Iniciar sesión{" "}
                    </Button>
                  </div>

                  {/* <div className='form-group m-t-10 mb-0 row'>
                                        <div className='col-12 m-t-20'>
                                            <Link to='' className='text-muted'>
                                                <Icon className='icon'>lock</Icon> ¿Olvidaste tu
                                                 contraseña?
                                             </Link>
                                        </div>
                                    </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
