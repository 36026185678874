import React from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { Component } from "react";
import "../../../utils/Modals/modal.scss";

export class OnDiscardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      description: "",
      validated: false,
    };

    this.submitButtonRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (videoId, municipalityId, event) => {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) {
      this.setState({ validated: true });
    } else if (
      this.submitButtonRef.current &&
      !this.submitButtonRef.current.disabled
    ) {
      this.submitButtonRef.current.disabled = true;
      if (this.state.reason === "videoDesaprobado") {
        this.props.handleNoInfraction(videoId);
      } else {
        this.props.handleDiscard(this.state, videoId);
      }
    }
  };

  render() {
    const {
      municipalityId,
      videoId,
      handleDiscard,
      loggedInStatus,
      usesExternalURLs,
      handleSubmit,
      handleNoInfraction,
      initialTime,
      setTimer,
      checkLoggedIn,
      userData,
      handleLogout,
      staticContext,
      ...rest
    } = this.props;
    return (
      <div>
        <Modal
          {...rest}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title className="title">Descartar infracción</Modal.Title>
          </Modal.Header>
          <Form
            noValidate
            validated={this.state.validated}
            id="form2"
            onSubmit={(e) => this.handleSubmit(videoId, municipalityId, e)}
          >
            <Modal.Body>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridReason">
                  <Form.Label>Razón de descarte</Form.Label>
                  <Form.Control
                    required
                    name="reason"
                    as="select"
                    defaultValue="Seleccione..."
                    onChange={this.handleChange}
                  >
                    <option hidden value="">
                      Seleccione...
                    </option>
                    <option value="videoDesaprobado">No hay infracción</option>
                    <option>Vehículo privilegiado</option>
                    <option>Patente difusa</option>
                    <option>Semáforo fuera de servicio</option>
                    <option>Falta visual del semáforo</option>
                    <option>No se ve la patente</option>
                    <option>Semáforo en verde</option>
                    <option>Camión</option>
                    <option>Lluvia</option>
                    <option>Feria</option>
                    <option>Otro...</option>
                  </Form.Control>
                  <Form.Control.Feedback tooltip={true} type="invalid">
                    Seleccione una razón.
                  </Form.Control.Feedback>
                </Form.Group>
              </Form.Row>
              <Form.Group controlId="formGridDescription">
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                  as="textarea"
                  required={this.state.reason === "Otro..." ? true : false}
                  rows="5"
                  name="description"
                  placeholder="Describa el motivo del descarte"
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                form="form2"
                className="btn-gris"
                onClick={this.props.onHide}
              >
                Cancelar
              </Button>
              <Button
                variant="danger"
                form="form2"
                className="btn-rojo"
                type="submit"
                ref={this.submitButtonRef}
              >
                Descartar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
