import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Reports.scss";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Icon } from "@material-ui/core";
import Moment from "moment";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { Dropdown, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import REPTable from "./REPTables";
import ResumenTable from "./REPTables/ResumenTable";
export default function ReportsAdmin(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn } = props;
  const [data, setData] = useState([]);
  const [errorDate, setErrorDate] = useState(false);

  const dateFromBiggerThanDateTo = () => {
    var isBigger = false;
    const dt = new Date(date.dateTo);
    const df = new Date(date.dateFrom);
    if (df > dt) {
      isBigger = true;
    }
    return isBigger;
  };

  const history = useHistory();

  const [date, setDate] = useState({
    dateFrom: "2022-01-01",
    dateTo: Moment(Date.now()).format("YYYY-MM-DD"),
  });

  const handleSubmit = (event) => {
    setErrorDate(false);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();

    if (dateFromBiggerThanDateTo()) {
      event.preventDefault();
      event.stopPropagation();
      setErrorDate(true);
      return;
    }
    getData();
  };

  const handleChange = (event) => {
    setDate({
      ...date,
      [event.target.name]: event.target.value,
    });
  };
  const getData = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get("/api/statistics/reports/supAnalizer/analyzers", {
      headers: { "auth-token": authToken },
      params: {
        fechaDesde: date.dateFrom,
        fechaHasta: date.dateTo,
      },
    }).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="statistics-container">
      <div className="statistics">
        <div className="header-user">
          <div className="text-header">
            <h1>Reportes editores</h1>
          </div>
        </div>

        <Accordion style={{ marginBottom: "2rem", marginTop: "2rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="header">
              <Icon className="filter-icon">search</Icon>
              <h2> Filtros</h2>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Form id="form" onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Fecha desde: </Form.Label>
                  <Form.Control
                    name="dateFrom"
                    type="date"
                    required
                    value={date.dateFrom}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Fecha hasta: </Form.Label>

                  <Form.Control
                    name="dateTo"
                    type="date"
                    required
                    value={date.dateTo}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              {errorDate ? (
                <span className="error">
                  'Fecha desde' no puede ser mayor a 'Fecha hasta'.
                </span>
              ) : null}
              <Button className="btn-celeste" type="submit">
                Buscar
              </Button>
            </Form>
          </AccordionDetails>
        </Accordion>

        <Tabs
          defaultActiveKey="resumen"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="resumen" title="Edición diaria">
            <MaterialTable
              columns={[
                {
                  title: "Apellido",
                  field: "Apellido",
                },
                {
                  title: "Nombre",
                  field: "Nombre",
                },
                {
                  title: "Fecha de revisión",
                  field: "fechaRevision",
                },
                {
                  title: "Municipalidad",
                  field: "Municipalidad",
                },
                {
                  title: "Videos Analizados",
                  field: "VideosAnalizados",
                },

                {
                  title: "Descartado",
                  field: "descartado",
                  searchable: true,
                },

                {
                  title: "Imputable",
                  field: "imputable",
                },
                {
                  title: "No imputable",
                  field: "noImputable",
                },
                {
                  title: "Tasa de imputables",
                  field: "tasaImputables",
                },
              ]}
              data={data?.data1}
              isLoading={isLoading}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                },
                toolbar: {
                  nRowsSelected: "{0} fila(s) seleccionadas",
                },
                header: {
                  actions: " ",
                },
                body: {
                  emptyDataSourceMessage: "No hay registros para mostrar",
                  filterRow: {
                    filterTooltip: "Filtro",
                  },
                },
              }}
              title=" "
              options={{
                exportButton: true,
                doubleHorizontalScroll: true,
                headerStyle: { position: "sticky", top: 0 },
                maxBodyHeight: "650px",
                exportAllData: true,
                exportFileName:
                  date.dateFrom +
                  "_" +
                  date.dateTo +
                  "_ReporteEditores_EdicionDiaria",
                actionsColumnIndex: -1,
                filtering: false,
                pageSize: 10, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [10, 30, 50, 100], // rows selection options
                rowStyle: (rowData) => {
                  return {
                    backgroundColor:
                      rowData.tableData.id % 2 == 0 ? "#eaeaea" : "#ffffff",
                  };
                },
              }}
            />
          </Tab>
          <Tab eventKey="produccion" title="Detalle presunciones">
            <MaterialTable
              columns={[
                {
                  title: "Apellido",
                  field: "apellido",
                },
                {
                  title: "Nombre",
                  field: "nombre",
                },
                {
                  title: "Equipo",
                  field: "Equipo",
                },
                {
                  title: "Municipalidad",
                  field: "Municipalidad",
                },
                {
                  title: "Video Id",
                  field: "VideoId",
                },

                {
                  title: "Nombre Video",
                  field: "video",
                },

                {
                  title: "Fecha Grabacion",
                  field: "FechaGrabacion",
                },
                {
                  title: "Hora Grabacion",
                  field: "HoraGrabacion",
                },
                {
                  title: "Fecha visualizacion",
                  field: "FechaVisualizacion",
                },
                {
                  title: "Hora visualizacion",
                  field: "HoraVisualizacion",
                },
                {
                  title: "Aprobado",
                  field: "aprobado",
                },
                {
                  title: "No imputable",
                  field: "noImputable",
                },
                {
                  title: "Tipo de infracción",
                  field: "tipoinfraccion",
                },
                {
                  title: "Descripcion Infraccion",
                  field: "DescripcionInfraccion",
                },
                {
                  title: "URL video",
                  field: "urlVideo",
                },
                {
                  title: "URL imagen principal",
                  field: "urlImagenPrincipal",
                },
                {
                  title: "URL imagen 2",
                  field: "urlImagen2",
                },
                {
                  title: "URL imagen con zoom",
                  field: "urlImagenZoom",
                },
              ]}
              data={data?.data2}
              isLoading={isLoading}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                },
                toolbar: {
                  nRowsSelected: "{0} fila(s) seleccionadas",
                },
                header: {
                  actions: " ",
                },
                body: {
                  emptyDataSourceMessage: "No hay registros para mostrar",
                  filterRow: {
                    filterTooltip: "Filtro",
                  },
                },
              }}
              title=" "
              options={{
                exportButton: true,
                doubleHorizontalScroll: true,
                headerStyle: { position: "sticky", top: 0 },
                maxBodyHeight: "650px",
                exportAllData: true,
                exportFileName:
                  date.dateFrom +
                  "_" +
                  date.dateTo +
                  "_ReporteEditores_DetallePresunciones",
                actionsColumnIndex: -1,
                filtering: false,
                pageSize: 10, // make initial page size
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [10, 30, 50, 100], // rows selection options
                rowStyle: (rowData) => {
                  return {
                    backgroundColor:
                      rowData.tableData.id % 2 == 0 ? "#eaeaea" : "#ffffff",
                  };
                },
              }}
            />
          </Tab>
        </Tabs>
      </div>
      <img
        className="wave"
        src={require("../../../assets/images/waves.png")}
        alt=""
      />
      <img
        className="background-top"
        src={require("../../../assets/images/usuariosFondo.png")}
        alt=""
      />
    </div>
  );
}
