import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Icon } from "@material-ui/core";

export default function RepairDetailsModal(props) {
  return (
    <div>
      <Modal
        size="lg"
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="review-details">
            <div className="header-rd">
              <h3>Reparación</h3>
            </div>
            <div className="body-rd">
              {props.repair ? (
                <div>
                  <Row>
                    <Col>
                      <p>
                        <Icon className="icons">api</Icon>Estado:
                        {props.repair.status === "Pendiente" ? (
                          <b style={{ color: "orange" }}>
                            {" "}
                            {props.repair.status}
                          </b>
                        ) : (
                          <b style={{ color: "green" }}>
                            {" "}
                            {props.repair.status}
                          </b>
                        )}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <Icon className="icons">today</Icon>Fecha de inicio:
                        <b> {props.repair.startedAt}</b>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        <Icon className="icons">location_on</Icon>Ubicación:
                        <b> {props.repair.repairPlace}</b>
                      </p>
                    </Col>
                    {props.repair.status === "Finalizada" ? (
                      <Col>
                        <p>
                          <Icon className="icons">event_available</Icon>Fecha de
                          fin:
                          <b> {props.repair.finishedAt}</b>
                        </p>
                      </Col>
                    ) : null}
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        <Icon className="icons">nfc</Icon>Motivo:
                        <b> {props.repair.reason}</b>
                      </p>
                    </Col>
                  </Row>
                  {props.repair.status === "Finalizada" ? (
                    <Row>
                      <Col>
                        <p>
                          <Icon className="icons">build_circle</Icon>Solución:
                          <b> {props.repair.solution}</b>
                        </p>
                      </Col>
                    </Row>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>

          <div className="review-details">
            <div className="header-rd">
              <h3>Equipo</h3>
            </div>
            <div className="body-rd">
              {props.repair.Equipment ? (
                <div>
                  <Row>
                    <Col>
                      <p>
                        <Icon className="icons">location_on</Icon>Equipo:
                        <b> {props.repair.equipment}</b>
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <Icon className="icons">location_on</Icon>Ubicación:
                        <b>
                          {" "}
                          {props.repair.Equipment.street}{" "}
                          {props.repair.Equipment.streetNumber},
                          <span>
                            {props.repair.Equipment.Municipality?.name ?? " - "}
                          </span>
                        </b>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        <Icon className="icons">settings_system_daydream</Icon>
                        Tipo de sistema:
                        <b>
                          {" "}
                          {props.repair.Equipment.typeOfSystem === "new"
                            ? "Nuevo"
                            : "Híbrido"}
                        </b>
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <Icon className="icons">nfc</Icon>Tipo de equipo:
                        <b>
                          {" "}
                          {props.repair.Equipment.EquipmentType?.description}
                        </b>
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <p>
                        <Icon className="icons">wb_auto</Icon>IP:
                        <b> {props.repair.Equipment.ip}</b>
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <Icon className="icons">broken_image</Icon>Número de
                        serie:
                        <b> {props.repair.Equipment.serialNumber}</b>
                      </p>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
