import React from "react";

import Modal from "react-bootstrap/Modal";

export default class Cropper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.modalActive,
    };
    this.setShow = this.setShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  crop(img) {
    this.props.cropImage();
  }
  setShow(status) {
    this.setState({
      show: status,
    });
  }

  handleClose() {
    this.props.closeModal();
  }
  handleShow() {
    this.setShow(true);
  }
  removeIndexFromQueue(i) {
    this.props.removeIndexFromQueue(i);
  }
  render() {
    if (!this.props.modalActive) {
      return <div></div>;
    } else {
      return (
        <Modal
          show={this.props.modalActive}
          onHide={this.handleClose}
          dialogClassName='modal-80w'
        >
          <Modal.Header closeButton>
            <Modal.Title className='title'>Recorte la Patente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className='cropper-image-previews'>
                <div className='img-cropper-container'>
                  <img
                    alt=''
                    className='selected-img'
                    src={this.props.imageSrc}
                  />
                </div>
                <div className='crop-preview'>
                  <div className='crop-preview-container'></div>
                </div>
              </div>

              <div className='crop-button-container'>
                <button id='crop-img' className='tool-button'>
                  Recortar
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  }
}
