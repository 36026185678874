import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import EquipmentType from "../../../../utils/Constants/EquipmentType.js";
import { Button } from "react-bootstrap";
import { TextField } from "@material-ui/core";
export default function BusquedaVideo(props) {
  return (
    <Form id="form-filter" onSubmit={props.handleSubmit}>
      <Form.Row>
        <Form.Group as={Col} md={12} controlId="formGridInfraccion">
          <Form.Control
            required
            name="option"
            as="select"
            onChange={props.handleChange}
          >
            {props.state.equipmentSelected &&
            props.state.equipmentSelected.equipmentTypeId ===
              EquipmentType.SEMAFORO ? (
              <option value={"0"}>Obtener el último video</option>
            ) : (
              <option value={"0"}>Obtener última infracción</option>
            )}
            <option value={"1"}>Seleccionar fechas</option>
            <option value={"2"}>Buscar por ID de video</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>
      {props.state.option === "1" ? (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <TextField
            label="Fecha desde"
            type="datetime-local"
            name="dateFrom"
            className="datetime-picker"
            required
            value={props.state.dateFrom}
            onChange={props.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            label="Fecha hasta"
            type="datetime-local"
            name="dateTo"
            className="datetime-picker"
            required
            style={{ marginLeft: "0.5rem" }}
            value={props.state.dateTo}
            onChange={props.handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      ) : props.state.option === "2" ? (
        <>
          <TextField
            label="ID de video"
            type="number"
            style={{ width: "100%" }}
            name="videoIdSearch"
            value={props.state.videoIdSearch}
            onChange={props.handleChange}
          />
        </>
      ) : null}

      <Form.Row>
        <div className="error">{props.state.errorDate}</div>
        <Button className="btn-celeste" style={{ width: "100%" }} type="submit">
          {props.state.option === "0" ? "Siguiente" : "Buscar"}
        </Button>
        {props.state.option === "0" ? (
          <Button
            className="tool-button"
            id="copy-button"
            style={{ width: "100%", marginTop: "1rem" }}
            onClick={props.saltar10}
          >
            {props.state.equipmentSelected &&
            props.state.equipmentSelected.equipmentTypeId ===
              EquipmentType.SEMAFORO
              ? "SALTAR 10 VIDEOS"
              : "SALTAR 10 IMAGENES"}
          </Button>
        ) : null}
      </Form.Row>
    </Form>
  );
}
