import React from "react";
import { Login } from "./components/pages/Auth/Auth.jsx";

export default function Home(props) {
    const handleSuccessfulAuth = (data) => {
        props.handleLogin(data);
        if(data.passwordChanged === 0){
            props.history.push("/changePassword")
        }else {
            props.history.push("/home");
        }



    };

    return (
        <div>
            <Login handleSuccessfulAuth={handleSuccessfulAuth} />
        </div>
    );
}
//<h1>Home</h1>
