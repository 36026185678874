import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../Repairs.scss";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import RepairDetailsModal from "../Modals/RepairDetailsModal";


export default function Repairs(props) {

    const [isLoading, setIsLoading] = useState(false);
    const { checkLoggedIn } = props;
    const [repairs, setRepairs] = useState([]);
    const history = useHistory();
    const [showRepairDetailsModal, setShowRepairDetailsModal] = useState(false);
    const [repairSelected, setRepairSelected] = useState({});


    const setDataTable = (response) => {

        var aux;
        let array = [];
        response.filter(repair => repair.statusId === 1).map((repair) => {
            aux ={
                id: repair.repairId, //se manda a la pagina de editar
                repairId:"#" + repair.repairId,
                status: repair.Status.description,
                startedAt: Moment(repair.startedAt).utc().format("DD/MM/YYYY"),
                reason: repair.reason,
                repairPlace: repair.repairPlace,
                equipment: repair.Equipment.brand +" - " + repair.Equipment.model,
                serialNumber: repair.Equipment.serialNumber,
                Equipment: repair.Equipment
            }
            array.push(aux)
            return null
        })


        setRepairs(repairs.concat(array));

    };


    const getRepairs = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }

        await Axios.get("/api/repair", {
            headers: { "auth-token": authToken },
        }).then((response) => {
        
            setDataTable(response.data.repairs)
            setIsLoading(false);
        });

    };


    useEffect(() => {
        setIsLoading(true);
        getRepairs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleMoreDetails = (rowData) => {
        setRepairSelected(rowData);
        setShowRepairDetailsModal(true);
    }

    const closeModal = () => {
        setShowRepairDetailsModal(false); 
    }

    const handleEdit = (rowData) => {
        history.push(
            `/repair/edit/${rowData.id} `)
    }


    return (
        <div className='statistics-container'>
            <div className='statistics'>
                <div className="header-repair" style={{ marginBottom: '2rem' }}>
                    <div className="text-header">
                        <h1>Reparaciones pendientes</h1>
                        <h2>Podés dar de alta una nueva orden de revisón, editarla o postergarla</h2>
                    </div>

                    <div className="button-header">
                        <Button className='btn-gris' href="repairs/record" >Ver historial</Button>
                    </div>
                </div>
                <MaterialTable

                    columns={[
                        { title: "Número de reparación", field: "repairId", filtering: false },
                        { title: "Fecha dada de alta", field: "startedAt", filtering: false },
                        { title: "Equipo", field: "equipment", filtering: false },
                        { title: "Numero de serie", field: "serialNumber", filtering: false },
                        { title: "Motivo de reparación", field: "reason", filtering: false },
                        { title: "Donde se está reparando", field: "repairPlace", filtering: false },
                        {
                            title: "Estado", field: "status",
                            lookup: {
                                'Pendiente': 'Pendiente', 'Finalizada': 'Finalizada', 
                            },
                            cellStyle: { minWidth: 150 },
                            render: rowData => {
                                return (
                                    rowData.status === "Pendiente" ?
                                        <span className="iniciada status">{rowData.status}</span> :
                                        rowData.status === "Postergada" ?
                                            <span className="postergada status">{rowData.status}</span> :
                                            rowData.status === "No realizada" ?
                                                <span className="no-realizada status">{rowData.status}</span> :
                                                rowData.status === "Finalizada" ?
                                                    <span className="finalizada status">{rowData.status}</span> :
                                                    null
                                )
                            },
                        },
            

                    ]}
                    data={repairs}
                    isLoading={isLoading}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} fila(s) seleccionadas'
                        },
                        header: {
                            actions: ' '
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay registros para mostrar',
                            filterRow: {
                                filterTooltip: 'Filtro'
                            }
                        }
                    }}
                    actions={[
                        {
                            icon: 'info',
                            tooltip: 'Detalle',
                            onClick: (event, rowData) => handleMoreDetails(rowData)
                        },
                        rowData => (
                            {
                                icon: 'edit',
                                tooltip: 'Editar',
                                hidden: rowData.status === "Finalizada",
                                onClick: (event, rowData) => handleEdit(rowData)
                            }),
                    ]}

                    title=' '
                    options={{
                        actionsColumnIndex: -1,
                    }}
                />
            </div>
            <img
                className='wave'
                src={require("../../../../assets/images/waves.png")}
                alt=''
            />
            {/* <img
                className='background-top'
                src={require("../../../assets/images/supervisor/review.png")}
                alt=''
            /> */}
        <RepairDetailsModal
            show={showRepairDetailsModal}
            onHide={closeModal}
            repair={repairSelected}
        ></RepairDetailsModal>

        </div>

    );

}
