import React, { useEffect, useState } from "react";
import Loader from "../../../utils/Loader/Loader";
import Axios from "axios";
import "./TechnicianReview.scss";
import Button from "react-bootstrap/Button";
import { Row, Col } from "react-bootstrap";
import { Icon } from "@material-ui/core";
import Moment from "moment";
import GoogleApiWrapper from "../../../utils/Maps/GoogleApiWrapper";

export default function ReviewPreview(props) {
  const { checkLoggedIn, history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [review, setReview] = useState({});
  const [problemDescription, setProblemDescription] = useState("");
  const [solutionDescription, setSolutionDescription] = useState("");
  const [marker, setMarker] = useState([]);

  const setMarkerData = (equipment) => {
    let array = [];
    setMarker((marker.length = 0));
    const coordinates = equipment.coordinates.split(",");
    const aux = {
      lat: coordinates[0],
      long: coordinates[1],
      title: equipment.street + " " + equipment.streetNumber,
    };
    array.push(aux);
    setMarker(marker.concat(array));
  };

  const setProblems = (review) => {
    var aux = "";
    review.ReviewProblems.forEach((problem) => {
      if (problem.description !== "Otros") {
        aux += problem.description + ", ";
      } else {
        aux += review.otherProblemDescription;
      }
    });

    setProblemDescription(aux);
  };

  const setSolutions = (review) => {
    var aux = "";
    review.ReviewSolutions.forEach((solution) => {
      if (solution.description !== "Otro") {
        aux += solution.description + ", ";
      } else {
        aux += review.otherSolutionDescription;
      }
    });

    setSolutionDescription(aux);
  };

  const getReview = async () => {
    const id = props.match.params.id;
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/review/${id}`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setIsLoading(false);
      setReview(response.data.review);
      setProblems(response.data.review);
      setSolutions(response.data.review);
      setMarkerData(response.data.review.Equipment);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getReview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStart = () => {
    if (review.Equipment.EquipmentType?.description === "Semaforo") {
      history.push(`/review/semaphore/first/${review.reviewId} `);
    } else if (review.Equipment.EquipmentType?.description === "Velocidad") {
      history.push(`/review/speed/first/${review.reviewId} `);
    }
  };

  const handleCancel = () => {
    history.push("/reviews/today");
  };

  return (
    <div className="rt-container">
      <div className="review-technician ">
        <Loader show={isLoading}></Loader>
        <div className="header-rtp">
          <h2>Datos de la revisión</h2>
        </div>
        <Row>
          <Col md={4} sm={12}>
            {review && review.Equipment ? (
              <div className="description-equipment">
                <Icon className="icon-de">assignment</Icon>
                <h2>Revisión #{review.reviewId} </h2>
                <h3>
                  {review.Equipment.street} {review.Equipment.streetNumber},{" "}
                  {review.Equipment.Municipality?.name ?? " - "}
                </h3>
                <p>
                  <Icon className="icons">api</Icon>Estado del equipo:{" "}
                  {review.Equipment.isWorking ? (
                    <span style={{ color: "#CCFFBA", fontWeight: "bold" }}>
                      Funcionando
                    </span>
                  ) : (
                    <span style={{ color: "#FF7272", fontWeight: "bold" }}>
                      No funciona
                    </span>
                  )}
                </p>
                <p>
                  <Icon className="icons">broken_image</Icon>Nro de serie:
                  <span className="desc"> {review.Equipment.serialNumber}</span>
                </p>
                <p>
                  <Icon className="icons">settings_input_antenna</Icon>Tipo de
                  equipo:
                  <span className="desc">
                    {" "}
                    {review.Equipment.EquipmentType?.description}
                  </span>
                </p>
                <p>
                  <Icon className="icons">supervised_user_circle</Icon>Técnicos
                  a cargo:
                  <span className="desc">
                    {" "}
                    {review.Technicians[0].name}{" "}
                    {review.Technicians[0].lastname},
                    {review.Technicians[1].name}{" "}
                    {review.Technicians[1].lastname}
                  </span>
                </p>
                <p>
                  <Icon className="icons">event</Icon>Fecha:{" "}
                  <span className="desc">
                    {Moment(review.startedAt).utc().format("DD/MM/YYYY")}
                  </span>
                </p>
                <p>
                  <Icon className="icons">bug_report</Icon>Problema:{" "}
                  <span className="desc">{problemDescription}</span>
                </p>
                {review.statusId === 2 ? (
                  <p>
                    <Icon className="icons">build_circle</Icon>Solución:{" "}
                    <span className="desc">{solutionDescription}</span>
                  </p>
                ) : null}
              </div>
            ) : null}
          </Col>
          <Col md={8} sm={12} className="mapa">
            {marker[0] ? (
              <GoogleApiWrapper
                markers={marker}
                zoom={14}
                width={"100%"}
              ></GoogleApiWrapper>
            ) : null}
          </Col>
        </Row>

        <Row>
          <div className="btn-container">
            {review && review.statusId !== 4 && review.statusId !== 2 ? (
              <Button className="btn-celeste" onClick={handleStart}>
                Empezar
              </Button>
            ) : null}
            <Button
              variant="secondary"
              className="btn-gris"
              onClick={handleCancel}
            >
              Volver
            </Button>
          </div>
        </Row>
      </div>
      <img
        className="waves-bottom"
        src={require("../../../../assets/images/technician/waves-bottom.png")}
        alt=""
      />
    </div>
  );
}
