import React from "react";
import { Modal, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { TipoAnalizadoresSelect } from "../../../utils/Constants/TipoAnalizadores";
export default function SelectMunicipalityModal(props) {
  const {
    setMunicipalitySelected,
    setTipoEquipoSelected,
    tipoEquipos,
    handleSubmit,
    ...rest
  } = props;

  const handleChange = (event) => {
    var value = event.target.value;
    value = parseInt(value, 10);
    props.setMunicipalitySelected(value);
  };

  return (
    <div>
      <Modal {...rest} centered aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="review-details">
            <h3>{props.question}</h3>

            <div className="body-rd">
              <Form id="form" onSubmit={props.handleSubmit}>
                <div className="form">
                  {tipoEquipos && tipoEquipos.length > 0 && (
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridTipoEquipo">
                        <Form.Label>Tipo de equipo a analizar</Form.Label>
                        <Form.Control
                          required
                          name="municipality"
                          as="select"
                          onChange={(e) => {
                            var value = e.target.value;
                            value = parseInt(value, 10);
                            setTipoEquipoSelected(value);
                          }}
                        >
                          {tipoEquipos?.map((x, index) => (
                            <option key={index} value={x.id}>
                              {x.descripcion}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                  )}
                  <Form.Row>
                    <Form.Group as={Col} controlId="formGridInfraccion">
                      <Form.Label>Municipios</Form.Label>
                      <Form.Control
                        required
                        name="municipality"
                        as="select"
                        onChange={handleChange}
                      >
                        <option hidden value="">
                          Seleccione...
                        </option>

                        {props.municipalities.map((municipality, index) => (
                          <option
                            key={index}
                            value={municipality.municipalityId}
                          >
                            {municipality.name}
                          </option>
                        ))}
                        {/* <option disabled style={{ color: "#dedede" }}>
                          ──────────
                        </option>
                        <option value={0}>Sin especificar</option> */}
                      </Form.Control>
                    </Form.Group>
                  </Form.Row>
                </div>
                <div
                  className="but-container"
                  style={{ justifyContent: "flex-end" }}
                >
                  <Button
                    className="tool-button"
                    id="finalize-button"
                    type="submit"
                    variant="primary"
                  >
                    Aceptar
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
