import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Toolbar.scss";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ROLES from "../../utils/Constants/Roles";

export default function Toolbar(props) {
  const history = useHistory();
  const { checkLoggedIn } = props;
  const [paymentData, setPaymentData] = useState({
    amountPerApproval: 0,
    amountPerDisapproval: 0,
    amountPerDiscard: 0,
  });
  const [videosAmount, setVideosAmount] = useState(0);
  const [toolbarStatistics, setToolbarStatistics] = useState({
    approved: 0,
    disapproved: 0,
    notImputable: 0,
    money: 0,
    worker: 0,
  });

  const onLogout = () => {
    props.handleLogout();
    history.push("/login");
  };

  const getVideoAmount = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get("/api/user/video/notAnalyzedYet", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setVideosAmount(response.data.amount);
    });
  };

  const redirectHome = () => {
    history.push("/home");
  };

  useEffect(() => {
    refreshToolbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkLoggedIn, history]);

  const refreshToolbar = () => {
    if (props.userData.roleId === ROLES.ANALIZADOR.id) {
      getVideoAmount();
      getToolbarStatisticsAnalyzer();
    } else if (
      props.userData.roleId === ROLES.ADMIN.id ||
      props.userData.roleId === ROLES.SUPERVISOR_ANALIZADORES.id
    ) {
      getToolbarStatisticsSupervisor();
      getVideoAmount();
    }
  };

  const getToolbarStatisticsAnalyzer = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    Axios.get("/api/statistics/toolbar/perUser", {
      params: {
        userId: props.userData.id,
      },
      headers: { "auth-token": authToken },
    }).then((response) => {
      setToolbarStatistics({
        approved: response.data.approved,
        disapproved: response.data.disapproved,
        notImputable: response.data.notImputable,
      });
      setPaymentData({
        amountPerApproval: response.data.amounts[0].amountPerApproval,
        amountPerDisapproval: response.data.amounts[0].amountPerDisapproval,
        amountPerDiscard: response.data.amounts[0].amountPerDiscard,
      });
    });
  };

  const getToolbarStatisticsSupervisor = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    Axios.get("/api/statistics/toolbar", {
      params: {
        userId: props.userData.id,
      },
      headers: { "auth-token": authToken },
    }).then((response) => {
      setToolbarStatistics({
        approved: response.data.approved,
        notImputable: response.data.notImputable,
        disapproved: response.data.disapproved,
        worker: 35,
      });
    });
  };

  return (
    <header className="toolbar">
      <nav className="toolbar_navigation">
        <div>
          <DrawerToggleButton click={props.drawerClickHandler} />
        </div>
        <div onClick={redirectHome} className="toolbar_logo">
          {/* <img
            src={require("../../../assets/images/logo2.png")}
            className="logo-h"
            alt="logo"
          /> */}
        </div>
        <div className="spacer"></div>
        <div className="toolbar_navigation-items">
          {props.userData.roleId &&
          props.userData.roleId !== ROLES.SUPERVISOR_TECNICO_CALLE.id &&
          props.userData.roleId !== ROLES.TECNICO_CALLE.id ? (
            <ul>
              <li>Hoy: </li>
              {props.userData.roleId === ROLES.ANALIZADOR.id ? (
                <li className="tooltipg">
                  <Icon className="icons"> payments</Icon>$
                  <span id="plataGanada">
                    {toolbarStatistics.approved *
                      paymentData.amountPerApproval +
                      toolbarStatistics.disapproved *
                        paymentData.amountPerDisapproval +
                      toolbarStatistics.notImputable *
                        paymentData.amountPerDiscard}
                  </span>
                  <span className="tooltiptextg">Dinero recaudado</span>
                </li>
              ) : null}
              <li className="tooltipg">
                <Icon className="icons">check_circle_outline</Icon>
                <span id="cantAprobadas">{toolbarStatistics.approved}</span>
                <span className="tooltiptextg">
                  Cantidad de infracciones aprobadas
                </span>
              </li>
              <li className="tooltipg">
                <Icon className="icons">warning</Icon>
                <span id="cantInimputables">
                  {toolbarStatistics.notImputable}
                </span>
                <span className="tooltiptextg">
                  Cantidad de infracciones descartadas
                </span>
              </li>
              <li className="tooltipg">
                <Icon className="icons">highlight_off</Icon>
                <span id="cantDescartadas">
                  {toolbarStatistics.disapproved}
                </span>
                <span className="tooltiptextg">
                  Cantidad de videos desaprobados
                </span>
              </li>
              <li className="tooltipg">
                <Icon className="icons">ondemand_video</Icon>
                <span id="cantVideos">{videosAmount}</span>
                <span className="tooltiptextg">
                  Videos restantes para digitar
                </span>
              </li>
              <li className="tooltipg refresh" onClick={refreshToolbar}>
                <Icon className="icons">refresh</Icon>
                <span className="tooltiptextg">
                  Hace click acá para actualizar los valores de la barra
                </span>
              </li>
            </ul>
          ) : null}
        </div>
        <div className="spacer"></div>

        <div className="toolbar_navigation-items">
          <ul>
            <li>
              <Nav className="nav-link">
                {props.userData.email ? (
                  <NavDropdown
                    title={props.userData.email}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item onClick={() => history.push("/account")}>
                      <span className="color">
                        {" "}
                        <Icon className="icons">account_circle </Icon>Mi cuenta
                      </span>
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={onLogout}>
                      <span className="color">
                        {" "}
                        <Icon className="icons">exit_to_app </Icon> Cerrar
                        Sesion
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : null}
              </Nav>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}
