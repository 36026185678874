import React from "react";
import { useHistory } from "react-router-dom";
import "./SideDrawer.scss";
import { Icon } from "@material-ui/core";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Roles from "../../utils/Constants/Roles";

export default function SideDrawer(props) {
  let drawerClases = ["side-drawer"];
  if (props.show) {
    drawerClases = ["side-drawer open"];
  }

  const history = useHistory();

  const onLogout = () => {
    props.handleLogout();
    history.push("/login");
  };

  const AnalyzerPages = (
    <ul>
      {" "}
      <li>
        {" "}
        <a href="/home">
          <Icon className="icons">ondemand_video</Icon>Analizar
        </a>
      </li>
      <li>
        {" "}
        <a href={"/record/" + props.userData.id}>
          <Icon className="icons">insights</Icon>Historial
        </a>
      </li>
    </ul>
  );

  const SupervisorAnalyzerPages = (
    <ul>
      <li>
        {" "}
        <a href="/userPayments">
          <Icon className="icons">payments</Icon>Pagos
        </a>
      </li>
      <li>
        {" "}
        <a href="/statistics">
          <Icon className="icons">insights</Icon>Estadísticas
        </a>
      </li>
      <li>
        {" "}
        <a href="/users">
          <Icon className="icons">supervisor_account</Icon>Analizadores
        </a>
      </li>
      <li>
        <a href={`/reporte/analizadores`}>
          <Icon className="icons">sensor_occupied</Icon>Reporte Editores
        </a>
      </li>
      <li>
        {" "}
        <a href="/configMunicipalities">
          <Icon className="icons">admin_panel_settings</Icon>Configurar
          municipios
        </a>
      </li>
    </ul>
  );

  const AdminPages = (
    <ul>
      <li>
        {" "}
        <a href="/userPayments">
          <Icon className="icons">payments</Icon>Pagos
        </a>
      </li>
      <li>
        {" "}
        <a href="/statistics">
          <Icon className="icons">insights</Icon>Estadísticas
        </a>
      </li>
      <li>
        {" "}
        <a href="/users">
          <Icon className="icons">supervisor_account</Icon>Usuarios
        </a>
      </li>
      <li>
        {" "}
        <a href="/configMunicipalities">
          <Icon className="icons">admin_panel_settings</Icon>Configurar
          municipios
        </a>
      </li>
      <li>
        {" "}
        <a href="/equipments">
          <Icon className="icons">developer_board</Icon>Equipos
        </a>
      </li>
      <li>
        {" "}
        <a href="/equipments/status">
          <Icon className="icons">api</Icon>Estado general de equipos
        </a>
      </li>
      <li>
        <a href={`/cameraStatus`}>
          <Icon className="icons">visibility</Icon>Estado de camaras
        </a>
      </li>
      <li>
        <a href={`/reporte`}>
          <Icon className="icons">assignment</Icon>Reporte
        </a>
      </li>
      <li>
        <a href={`/reporte/analizadores`}>
          <Icon className="icons">sensor_occupied</Icon>Reporte Editores
        </a>
      </li>
      {/* <li>
        <a href={`/checkvideos`}>
          <Icon className="icons">missed_video_call</Icon>Bajadas
        </a>
      </li> */}
    </ul>
  );

  const TechnicianPages = (
    <ul>
      <li>
        <a href={`/reviews/today`}>
          <Icon className="icons">assignment</Icon>Revisiones
        </a>
      </li>
      <li>
        <a href={`/cameraStatus`}>
          <Icon className="icons">visibility</Icon>Estado de camaras
        </a>
      </li>
      {/* <li>
        <a href={`/checkvideos`}>
          <Icon className="icons">missed_video_call</Icon>Bajadas
        </a>
      </li> */}
    </ul>
  );

  const VisualizadorReportesPages = (
    <ul>
      <li>
        <a href={`/reporte`}>
          <Icon className="icons">assignment</Icon>Reporte
        </a>
      </li>
    </ul>
  );

  const SupervisorTechnicianPages = (
    <ul>
      <li>
        {" "}
        <a href="/review">
          <Icon className="icons">assignment</Icon>Revisiones
        </a>
      </li>
      <li>
        {" "}
        <a href="/repairs">
          <Icon className="icons">build_circle</Icon>Reparaciones
        </a>
      </li>
      <li>
        {" "}
        <a href="/equipments">
          <Icon className="icons">developer_board</Icon>Equipos
        </a>
      </li>
      <li>
        {" "}
        <a href="/equipments/status">
          <Icon className="icons">api</Icon>Estado general de equipos
        </a>
      </li>
      <li>
        <a href={`/cameraStatus`}>
          <Icon className="icons">visibility</Icon>Estado de camaras
        </a>
      </li>
    </ul>
  );

  const VerificadorVideosPages = (
    <ul>
      <li>
        {" "}
        <a href="/verificacion">
          <Icon className="icons">ondemand_video</Icon>Verificador de videos
        </a>
      </li>
      <li>
        {" "}
        <a href="/verificacionIA">
          <Icon className="icons">ondemand_video</Icon>Verificador de videos IA
        </a>
      </li>
    </ul>
  );
  return (
    <nav className={drawerClases}>
      <div className="topbar-left">
        <div className="margin-top">
          {/* <a href="/home" className="logo"><img src={require("../../../assets/images/logo2.png")} height="50" alt="logo" /></a> */}
        </div>
      </div>

      <div className="toolbar_navigation-items mobile-only">
        <ul>
          <li>
            <Nav className="nav-link">
              {props.userData.email ? (
                <NavDropdown
                  title={props.userData.email}
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item onClick={() => history.push("/account")}>
                    <span className="color">
                      {" "}
                      <Icon className="icons">account_circle </Icon>Mi cuenta
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={onLogout}>
                    <span className="color">
                      {" "}
                      <Icon className="icons">exit_to_app </Icon> Cerrar Sesion
                    </span>
                  </NavDropdown.Item>
                </NavDropdown>
              ) : null}
            </Nav>
          </li>
        </ul>
      </div>
      {props.userData.roleId === Roles.ADMIN.id
        ? AdminPages
        : props.userData.roleId === Roles.ANALIZADOR.id
        ? AnalyzerPages
        : props.userData.roleId === Roles.SUPERVISOR_ANALIZADORES.id
        ? SupervisorAnalyzerPages
        : props.userData.roleId === Roles.TECNICO_CALLE.id
        ? TechnicianPages
        : props.userData.roleId === Roles.SUPERVISOR_TECNICO_CALLE.id
        ? SupervisorTechnicianPages
        : props.userData.roleId === Roles.VISUALIZADOR_REPORTES.id
        ? VisualizadorReportesPages
        : props.userData.roleId === Roles.VERIFICADOR_VIDEOS.id
        ? VerificadorVideosPages
        : null}
    </nav>
  );
}

//Role ID: 1 Admin
//Role ID: 2 Analyzer
//Role ID: 3 Supervisor
