import React from "react";
import Axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";

import Moment from "moment";

import "./styles.scss";
import ReactDOM from "react-dom";
import ImageCarousel from "./ImageCarousel.jsx";
import ImageEditor from "./ImageEditor";
import CropperJS from "cropperjs";
import ImageList from "./ImageList";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/esm/Row";
import { Button } from "react-bootstrap";
import { OnFinishModal } from "../Modals/OnFinishModal";
import { OnDiscardModal } from "../Modals/OnDiscardModal";
import { OnOverflowModal } from "../Modals/OnOverflowModal";
import Loader from "../../../utils/Loader/Loader";
import Timer from "../../../utils/Timer/Timer";
import ChangeConfigModal from "../Modals/ChangeConfigModal";
import { Icon } from "@material-ui/core";
import MunicipalityId from "../../../utils/Constants/MunicipalityId";
import { NewInfractionModal } from "../Modals/NewInfractionModal";

export default class MainCropper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoId: "",
      images: [],
      municipalities: [],
      municipalityId: 0,
      videoName: "",
      frames: null,
      noVideo: false,
      captures: [],
      loading: true,
      data: null,
      framesToPost: [],
      errorPatente: false,
      patenteDuplicada: false,
      infractionTypeOptions: [],
      infoToPost: {
        description: "",
        vehicleType: "",
        infractionType: "",
        patent: "",
      },
      nextVideo: {
        videoId: "",
        videoName: "",
        equipment: "",
        date: "",
        frames: [],
        noVideo: false,
        data: null,
        municipality: " - ",
        ready: false,
        infractionTypeOptions: [],
      },
      patentsSend: [],
      indexMainImage: -1,
      municipality: "",
      equipment: null,
      date: "",
      curImageSelected: 0,
      modalActive: false,
      curImageSrc: "",
      scrollPos: 0,
      imageIndex: 0,
      initialTime: this.props.initialTime,
      resetTimer: false,
      buttonAction: "Copiar info",

      //Modals data
      textOverflowModal: "",
      validated: false,
      onOverflowModal: false,
      onFinishModal: false,
      onDiscardModal: false,
      newInfractionModal: false,
      configModal: false,
    };

    this.onInfractionConfirmed = this.onInfractionConfirmed.bind(this);
    this.resetQueue = this.resetQueue.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.requestImages = this.requestImages.bind(this);
    this.addOnDiscardModal = this.addOnDiscardModal.bind(this);
    this.addOnFinishClose = this.addOnFinishClose.bind(this);
    this.handleNoInfraction = this.handleNoInfraction.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.makeCropper = this.makeCropper.bind(this);
    this.addOnOverflowModal = this.addOnOverflowModal.bind(this);
    this.removeIndexFromQueue = this.removeIndexFromQueue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeNewInfractionModal = this.closeNewInfractionModal.bind(this);
    this.addNewInfraction = this.addNewInfraction.bind(this);
    this.onChangeMunicipality = this.onChangeMunicipality.bind(this);
    this.changeMainImage = this.changeMainImage.bind(this);
    this.checkForReady = this.checkForReady.bind(this);
    this.changeImageIndex = this.changeImageIndex.bind(this);
    this.imageIsOnFramesToPost = this.imageIsOnFramesToPost.bind(this);
  }

  async onInfractionConfirmed() {
    let framesAux = [];
    framesAux = [...this.state.framesToPost];
    let primerElemento = framesAux.shift();
    framesAux.splice(2, 0, primerElemento);

    let indexImage = this.state.indexMainImage - 1;

    console.log("framesAux", framesAux);
    console.log("indexImage", indexImage);

    if (indexImage !== 0) {
      //Hago el switch en el array
      let aux = framesAux[indexImage];
      framesAux[indexImage] = framesAux[0];
      framesAux[0] = aux;

      this.props.handleSubmit(
        this.state.infoToPost,
        framesAux,
        this.state.videoId,
        this.state.infractionId
      );
    } else {
      this.props.handleSubmit(
        this.state.infoToPost,
        framesAux,
        this.state.videoId,
        this.state.infractionId
      );
    }

    let patents = [...this.state.patentsSend, this.state.infoToPost.patent];

    this.setState({
      ...this.state,
      newInfractionModal: true,
      onFinishModal: false,
      patentsSend: patents,
    });
  }

  onChangeMunicipality(event) {
    var value = event.target.value;
    value = parseInt(value, 10);
    this.props.history.push(`/analyzer/${value}/cropper`);
    window.location.reload(false);
  }

  handleReset = () => {
    this.setState({ resetTimer: false });
  };

  addNewInfraction = () => {
    this.setState({
      ...this.state,
      newInfractionModal: false,
      infoToPost: {
        description: "",
        vehicleType: "",
        infractionType: "",
        patent: "",
      },
      indexMainImage: -1,
      validated: false,
      framesToPost: [],
      resetTimer: true,
    });
  };

  getNextVideo() {
    if (this.state.nextVideo.ready) {
      this.resetDataCurrentVideo();
      this.passNextVideoToCurrentVideo();
      this.getVideo(false);
    } else {
      this.setState(
        {
          ...this.state,
          loading: true,
          onDiscardModal: false,
          newInfractionModal: false,
        },
        () => {
          this.checkForReady();
        }
      );
    }
  }

  checkForReady(callback) {
    if (this.state.nextVideo.ready === false) {
      //we want it to match
      setTimeout(this.checkForReady, 500); //wait 5000 millisecnds then recheck
      return;
    }
    this.getNextVideo();
    //real action
  }

  closeNewInfractionModal = async () => {
    this.getNextVideo();
  };

  passNextVideoToCurrentVideo() {
    this.setState(
      (prevState) => ({
        newInfractionModal: false,
        frames: this.state.nextVideo.frames,
        videoId: this.state.nextVideo.videoId,
        videoName: this.state.nextVideo.videoName,
        noVideo: this.state.nextVideo.noVideo,
        equipment: this.state.nextVideo.equipment,
        date: this.state.nextVideo.date,
        loading: false,
        infractionTypeOptions: this.state.nextVideo.infractionTypeOptions,
      }),
      () => {
        if (this.state.nextVideo.noVideo) {
          this.setNoVideoData(true);
        } else {
          this.updateComponents([]);
        }
      }
    );
  }

  addOnFinishClose = () => {
    this.setState({
      onFinishModal: false,
    });
  };

  addOnDiscardModal = () => {
    this.setState({
      onDiscardModal: false,
    });
  };

  addOnOverflowModal = () => {
    this.setState({
      onOverflowModal: false,
    });
  };

  closeChangeConfigModal = () => {
    this.setState({
      configModal: false,
    });
  };

  //fires after user clicks on image in slideshow
  onImageSelected(imageSrc, imageIndex) {
    if (!this.imageIsOnFramesToPost(imageSrc)) {
      if (
        this.state.framesToPost.length > 0 &&
        this.state.framesToPost.length < 3
      ) {
        if (this.state.framesToPost.length == 1) this.changeMainImage(1);
        this.updateComponents([...this.state.framesToPost, imageSrc]);
      } else if (this.state.framesToPost.length === 0) {
        this.openCropper(imageSrc, imageIndex);
      } else {
        this.setState({
          textOverflowModal: "Solo se deben seleccionar 3 imagenes.",
          onOverflowModal: true,
        });
      }
    } else {
      this.setState({
        textOverflowModal: "Ya se ha seleccionado esta imagen. ",
        onOverflowModal: true,
      });
    }
  }

  imageIsOnFramesToPost(imageSrc) {
    //Si es el 3er frame, significa que va a elegir la imagen de la patente
    if (this.state.framesToPost.length === 0) return false;
    return this.state.framesToPost.includes(imageSrc);
  }

  openCropper(imageSrc, imageIndex) {
    this.setState(
      {
        curImageSelected: imageIndex,
        modalActive: true,
        curImageSrc: imageSrc,
      },
      () => {
        this.updateComponents(this.state.framesToPost);
        if (document.querySelector(".croppr-container")) {
          document
            .querySelector(".croppr-container")
            .parentNode.removeChild(
              document.querySelector(".croppr-container")
            );
        }
        ReactDOM.render(
          <ImageEditor
            imageSrc={imageSrc}
            makeCropper={() => {
              this.makeCropper(this.state.curImageSrc);
            }}
          />,
          document.getElementById("cropped-img-container")
        );
      }
    );
  }

  makeCropper(imageSrc) {
    let cropper = new CropperJS(document.querySelector(".selected-img"), {
      initialAspectRatio:
        document.querySelector(".selected-img").width /
        document.querySelector(".selected-img").height,
      viewMode: 2,
      zoomable: true,
      preview: ".crop-preview-container",
      modal: true,
      autoCropArea: 0.1,
    });
    document.getElementById("crop-img").addEventListener("click", () => {
      let croppedCanvas = cropper.getCroppedCanvas();

      let url = croppedCanvas.toDataURL("image/jpeg");

      let newFramesToPost = [...this.state.framesToPost, url];
      this.setState(
        {
          modalActive: false,
        },
        () => {
          this.updateComponents(newFramesToPost);
        }
      );
    });
  }

  closeModal() {
    this.setState(
      {
        modalActive: false,
      },
      () => {
        this.updateComponents(this.state.framesToPost);
      }
    );
  }

  handleDiscard(info, videoId) {
    this.props.handleDiscard(info, videoId);
    this.getNextVideo();
  }

  handleNoInfraction(videoId) {
    this.props.handleNoInfraction(videoId);
    this.getNextVideo();
  }

  async getFrameCaptures(callback) {
    let frameCaptures = [];
    if (this.state.frames)
      for (let i = 0; i < this.state.frames.length; i++) {
        let imgElement = (
          <img
            alt=""
            src={this.state.frames[i]}
            onClick={() => this.onImageSelected(this.state.frames[i], i)}
          />
        );
        frameCaptures.push(imgElement);
      }
    callback(frameCaptures);
  }

  resetQueue() {
    this.updateComponents([]);
  }

  removeIndexFromQueue(i) {
    let newQueue = [...this.state.framesToPost];
    newQueue.splice(i, 1);
    if (i === this.state.indexMainImage) this.setState({ indexMainImage: -1 });
    this.updateComponents(newQueue);
  }

  copyToClipboard() {
    let streetNumber = "";
    if (this.state.equipment.streetNumber)
      streetNumber = this.state.equipment.streetNumber;
    var ubicacion =
      this.state.equipment.street +
      " " +
      streetNumber +
      ", " +
      this.state.equipment.Municipality.name;
    var texto =
      "Fecha: " +
      this.state.date +
      "\n" +
      "Ubicación: " +
      ubicacion +
      "\n" +
      "Num. serie: " +
      this.state.equipment.serialNumber +
      "\n" +
      "ID Equipo: " +
      this.state.equipment.equipmentId +
      "\n" +
      "Video ID: " +
      this.state.videoId +
      "\n";
    if (
      this.state.equipment !== null &&
      navigator.clipboard &&
      window.isSecureContext
    ) {
      navigator.clipboard.writeText(texto);
      this.setState({ buttonAction: "¡Copiado!" });
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = texto;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      this.setState({ buttonAction: "¡Copiado!" });
      return new Promise((res, rej) => {
        // here the magic happens
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }

  updateComponents(newFramesToPost = [], callback = () => {}) {
    if (newFramesToPost.length > 3) {
      newFramesToPost = newFramesToPost.slice(0, 4);
    }

    this.getFrameCaptures((frames) => {
      this.setState({
        captures: frames,
        framesToPost: newFramesToPost,
        loading: false,
        data: true,
      });
    });
  }

  changeImageIndex(value) {
    this.setState((prevState) => ({
      ...prevState,
      imageIndex: value,
    }));
  }

  async componentDidMount() {
    var path = this.props.location.pathname.split("/");
    this.setState({ ...this.state, municipalityId: path[2] });
    this.getMunicipalities();
    await this.getVideo(true);
  }

  async getMunicipalities() {
    const authToken = await this.getAuthToken();
    await Axios.get("/api/municipality/filter", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      this.state.municipalities = response.data.municipalities;
    });
  }

  async getPayments() {
    const authToken = await this.getAuthToken();
    await Axios.get("/api/payment", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      this.setState({
        ...this.state,
        payment: {
          approval: response.data.amounts[0].amountPerApproval,
          noInfraction: response.data.amounts[0].amountPerDisapproval,
          noImputable: response.data.amounts[0].amountPerDiscard,
        },
      });
    });
  }

  //Consumes the API requesting a video
  async getVideo(isFirstVideo) {
    if (isFirstVideo) {
      this.resetDataCurrentVideo();
    } else {
      this.resetDataNextVideo();
    }

    const authToken = await this.getAuthToken();

    Axios.get(`/api/user/video/get/municipality/${this.state.municipalityId}`, {
      headers: { "auth-token": authToken },
    })
      .then((response) => {
        if (response.status === 204) {
          this.setNoVideoData(isFirstVideo);
        } else {
          this.setVideoData(response.data.videoInfo, isFirstVideo, () => {
            this.getFrames(
              response.data.videoInfo.videoId,
              authToken,
              isFirstVideo
            );
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setVideoData(videoInfo, isFirstVideo, callback) {
    let optionsSelect = [];

    if (videoInfo.equipment.cruceSemaforo) {
      optionsSelect.push("Cruce en rojo");
    }
    if (videoInfo.equipment.invasionSenda) {
      optionsSelect.push("Obstruccion de senda peatonal");
    }
    if (videoInfo.equipment.giroIndebido) {
      optionsSelect.push("Giro indebido");
    }

    if (isFirstVideo) {
      this.setState(
        {
          municipality: videoInfo.equipment.Municipality.name,
          equipment: videoInfo.equipment,
          date: videoInfo.date,
          videoId: videoInfo.videoId,
          videoName: videoInfo.name,
          infractionTypeOptions: optionsSelect,
        },
        callback
      );
    } else {
      this.setState(
        (prevState) => ({
          nextVideo: {
            ...prevState.nextVideo,
            municipality: videoInfo.equipment.Municipality.name,
            equipment: videoInfo.equipment,
            date: videoInfo.date,
            videoId: videoInfo.videoId,
            videoName: videoInfo.name,
            infractionTypeOptions: optionsSelect,
          },
        }),
        callback
      );
    }
  }

  setNoVideoData(isFirstVideo) {
    if (isFirstVideo) {
      this.setState({
        municipality: " - ",
        noVideo: true,
        loading: false,
        data: true,
      });
    } else {
      this.setState((prevState) => ({
        nextVideo: {
          ...prevState.nextVideo,
          municipality: " - ",
          noVideo: true,
          data: true,
          ready: true,
        },
      }));
    }
  }

  resetDataCurrentVideo() {
    this.setState({
      noVideo: false,
      onDiscardModal: false,
      framesToPost: [],
      frames: [],
      infoToPost: {
        description: "",
        vehicleType: "",
        infractionType: "",
        patent: "",
      },
      patentsSend: [],
      errorPatente: false,
      patenteDuplicada: false,
      equipment: null,
      infractionTypeOptions: [],
      date: "",
      buttonAction: "Copiar info",
      indexMainImage: -1,
      validated: false,
      resetTimer: true,
      curImageSrc: "",
      imageIndex: 0,
    });
  }

  resetDataNextVideo() {
    this.setState((prevState) => ({
      nextVideo: {
        ...prevState.nextVideo,
        videoId: "",
        videoName: "",
        equipment: "",
        date: "",
        infractionTypeOptions: [],
        frames: [],
        noVideo: false,
        data: false,
        municipality: " - ",
        ready: false,
      },
    }));
  }

  async getAuthToken() {
    const authToken = await this.props.checkLoggedIn();
    if (!authToken) {
      this.props.history.push("/");
      return;
    }
    return authToken;
  }

  getFrames(videoId, authToken, isFirstVideo) {
    Axios.get(`/api/user/video/frames/${videoId}`, {
      headers: { "auth-token": authToken },
    })
      .then(async (res) => {
        let frames = await this.requestImages(
          res.data.framesURLs,
          isFirstVideo
        );
        if (isFirstVideo) {
          this.setState({ ...this.state, frames: frames });
          this.updateComponents(this.state.framesToPost);
          this.getVideo(false);
        } else {
          this.setState((prevState) => ({
            nextVideo: {
              ...prevState.nextVideo,
              frames: frames,
              ready: true,
            },
          }));
        }
      })
      .catch(async (err) => {
        await this.getVideo(isFirstVideo);
      });
  }

  changeMainImage = (i) => {
    this.setState({ indexMainImage: i });
  };

  async requestImages(images, isFirstVideo) {
    let frames = [];
    let isError = false;
    const videoName = isFirstVideo
      ? this.state.videoName
      : this.state.nextVideo.videoName;

    if (videoName.length < 2) return this.getVideo(isFirstVideo);

    const options = {
      headers: {
        "auth-token": this.props.userData.authToken,
        videoname: videoName,
      },
    };
    let promises = [];

    for (const frameURL of images) {
      promises.push(
        // eslint-disable-next-line
        fetch(frameURL, options).then(async (response) => {
          if (response.ok) {
            await response.blob().then((blob) => {
              frames[
                frameURL.slice(frameURL.lastIndexOf("/") + 1, frameURL.length) -
                  1
              ] = URL.createObjectURL(blob);
            });
          } else {
            isError = true;
          }
        })
      );
    }
    await Promise.all(promises);

    if (isError) return this.getVideo(isFirstVideo);

    return frames;
  }

  handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "patent") {
      value = this.formatPatent(value);
    }

    this.setState((prevState) => ({
      ...prevState,
      infoToPost: { ...prevState.infoToPost, [name]: value },
    }));
  };

  formatPatent = (patent) => {
    return patent.replace(/\s/g, "").toUpperCase();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ errorPatente: false, patenteDuplicada: false });

    let regex = /^(?:[A-Z]{3}\d{3}|[A-Z]{2}\d{3}[A-Z]{2})$/;

    if (this.state.framesToPost.length === 3) {
      if (this.state.indexMainImage > 0 && this.state.indexMainImage < 3) {
        const form = event.currentTarget;
        if (
          !this.state.infoToPost.patent ||
          !regex.test(this.state.infoToPost.patent)
        ) {
          this.setState({ errorPatente: true });
        } else if (
          this.state.patentsSend.includes(this.state.infoToPost.patent)
        ) {
          this.setState({ patenteDuplicada: true });
        } else if (form.checkValidity() === false) {
          this.setState({ validated: true });
        } else {
          this.setState({ validated: true, onFinishModal: true });
        }
      } else {
        this.setState({
          textOverflowModal: "Debe seleccionar una imagen principal.",
          onOverflowModal: true,
        });
      }
    } else {
      this.setState({
        textOverflowModal: "Debe seleccionar 3 imagenes.",
        onOverflowModal: true,
      });
    }
  };

  render() {
    return (
      <div>
        <Loader show={this.state.loading} />
        <div className="main-container">
          <div className="cropper-card">
            <div className="header">
              <h1> Complete los datos de la infracción </h1>

              <div className="spacer"></div>
              <div className="timer">
                <Timer
                  initialTime={this.state.initialTime}
                  onChange={this.props.setTimer}
                  reset={this.state.resetTimer}
                  handleReset={this.handleReset}
                />
              </div>
            </div>
            <div className="header-descripcion">
              <h2>
                Haga click sobre las imágenes para seleccionarlas y complete los
                datos. Seleccione una imagen principal haciendo click en
                <Icon className="icons" style={{ marginLeft: "0.5rem" }}>
                  stars
                </Icon>
              </h2>

              <Button
                onClick={() => this.setState({ configModal: true })}
                className="btn-config"
              >
                <Icon>settings</Icon>Cambiar municipio
              </Button>
            </div>
            <Row>
              <Col lg={8} md={7} sm={12}>
                {this.state.data != null && this.state.noVideo === false ? (
                  <div id="main-container">
                    <ImageCarousel
                      removeIndexFromQueue={(i) => {
                        this.removeIndexFromQueue(i);
                      }}
                      curImageSrc={this.state.curImageSrc}
                      closeModal={this.closeModal}
                      modalActive={this.state.modalActive}
                      curImageSelected={this.state.curImageSelected}
                      resetQueue={this.resetQueue}
                      framesToPost={this.state.framesToPost}
                      getFrameCaptures={this.getFrameCaptures}
                      onImageSelected={this.onImageSelected}
                      value={this.state.imageIndex}
                      changeValue={this.changeImageIndex}
                      clickToChange
                      slides={this.state.captures}
                    />
                  </div>
                ) : this.state.data != null && this.state.noVideo === true ? (
                  <div id="no-video">
                    <img
                      src={require("../../../../assets/images/admin/sadface.png")}
                      alt="money"
                    />
                    No hay videos disponibles.
                  </div>
                ) : null}
              </Col>
              <Col lg={4} md={5} sm={12} style={{ paddingLeft: 0 }}>
                <Row>
                  <Col md={12} style={{ paddingLeft: 0 }}>
                    <div className="download-control-container">
                      <div id="cropper-container">
                        <ImageList
                          removeIndexFromQueue={(i) => {
                            this.removeIndexFromQueue(i);
                          }}
                          changeMainImage={(i) => {
                            this.changeMainImage(i);
                          }}
                          indexMainImage={this.state.indexMainImage}
                          images={this.state.framesToPost}
                          errorPatente={this.state.errorPatente}
                          patenteDuplicada={this.state.patenteDuplicada}
                          handleChangePatente={this.handleChange}
                          infoToPost={this.state.infoToPost}
                        />
                        <div id="cropped-img-container"></div>
                      </div>

                      <Form
                        noValidate
                        validated={this.state.validated}
                        id="form"
                        onSubmit={this.handleSubmit}
                        style={{ marginTop: "0.3rem" }}
                      >
                        <div className="form">
                          <Form.Row>
                            <Form.Group as={Col} controlId="formGridInfraccion">
                              <Form.Label>Tipo de infracción</Form.Label>
                              <Form.Control
                                required
                                name="infractionType"
                                as="select"
                                onChange={this.handleChange}
                                value={this.state.infoToPost.infractionType}
                              >
                                <option hidden value="">
                                  Seleccione...
                                </option>
                                {this.state.infractionTypeOptions.map((x) => (
                                  <option value={x}>{x}</option>
                                ))}
                              </Form.Control>
                              <Form.Control.Feedback
                                tooltip={true}
                                type="invalid"
                              >
                                Seleccione un tipo de infracción.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>

                          {this.state.equipment ? (
                            <div style={{ textAlign: "left" }}>
                              <Row style={{ margin: 0 }}>
                                <Col sm={12}>
                                  <p className="ta-left">
                                    <Icon className="icons">event</Icon>Fecha:
                                    <b>
                                      {" "}
                                      {Moment(this.state.date)
                                        .utc()
                                        .format("DD/MM/YYYY HH:mm")}
                                    </b>
                                  </p>
                                </Col>
                                <Col sm={6} style={{ paddingRight: "0" }}>
                                  <p className="ta-left">
                                    <Icon className="icons">broken_image</Icon>
                                    N° de serie:
                                    <b> {this.state.equipment.serialNumber}</b>
                                  </p>
                                </Col>
                                <Col sm={6} style={{ paddingLeft: "0" }}>
                                  <p className="ta-left">
                                    <Icon className="icons">
                                      settings_input_hdmi
                                    </Icon>
                                    ID del equipo:
                                    <b> {this.state.equipment.equipmentId}</b>
                                  </p>
                                </Col>

                                <Col sm={12}>
                                  <p className="ta-left">
                                    <Icon className="icons">location_on</Icon>
                                    Ubicación:
                                    <b>
                                      {" "}
                                      {this.state.equipment.street}{" "}
                                      {this.state.equipment.streetNumber},{" "}
                                      {this.state.equipment.Municipality.name}
                                    </b>
                                  </p>
                                </Col>
                              </Row>
                              <div
                                className="but-container"
                                style={{
                                  justifyContent: "center",
                                  marginTop: "0",
                                }}
                              >
                                <Button
                                  className="tool-button"
                                  id="copy-button"
                                  variant="primary"
                                  onClick={() => this.copyToClipboard()}
                                >
                                  {this.state.buttonAction}
                                </Button>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="but-container">
                          {this.state.patentsSend &&
                          this.state.patentsSend.length > 0 ? (
                            <Button
                              onClick={() => this.getNextVideo()}
                              className="tool-button"
                              id="secondary-button"
                              variant="secondary"
                              disabled={this.state.noVideo}
                            >
                              Sig. video
                            </Button>
                          ) : (
                            <Button
                              onClick={() =>
                                this.setState({ onDiscardModal: true })
                              }
                              className="tool-button"
                              id="discard-button"
                              variant="danger"
                              disabled={this.state.noVideo}
                            >
                              Descartar
                            </Button>
                          )}
                          <Button
                            className="tool-button"
                            id="finalize-button"
                            type="submit"
                            variant="primary"
                            disabled={this.state.noVideo}
                          >
                            Finalizar
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <OnDiscardModal
            {...this.props}
            handleNoInfraction={this.handleNoInfraction}
            handleDiscard={this.handleDiscard}
            municipalityId={this.state.municipalityId}
            show={this.state.onDiscardModal}
            onHide={this.addOnDiscardModal}
            videoId={this.state.videoId}
          ></OnDiscardModal>
          <OnFinishModal
            info={this.state.infoToPost}
            show={this.state.onFinishModal}
            onHide={this.addOnFinishClose}
            onSubmit={this.onInfractionConfirmed}
          ></OnFinishModal>
          <OnOverflowModal
            show={this.state.onOverflowModal}
            onHide={this.addOnOverflowModal}
            text={this.state.textOverflowModal}
          ></OnOverflowModal>
          <NewInfractionModal
            show={this.state.newInfractionModal}
            onHide={this.closeNewInfractionModal}
            addNewInfraction={this.addNewInfraction}
          ></NewInfractionModal>
          <ChangeConfigModal
            municipalityId={this.state.municipalityId}
            municipalities={this.state.municipalities}
            onChangeMunicipality={this.onChangeMunicipality}
            show={this.state.configModal}
            onHide={this.closeChangeConfigModal}
            equipment={this.state.equipment}
            date={this.state.date}
          ></ChangeConfigModal>
        </div>
      </div>
    );
  }
}
