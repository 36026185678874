import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Loader from "../../utils/Loader/Loader";
import Axios from "axios";
import "../Auth/auth.scss";
import { SuccessModal } from "../../utils/Modals/SuccessModal";

export default function ChangePassword(props) {
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const { checkLoggedIn, history } = props;
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const handleChange = (event) => {
    setPassword({
      ...password,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (password.password !== password.confirmPassword) {
      setError("Las contraseñas no coinciden.");
    } else {
      if (password.password.length < 5) {
        setError("La contraseña debe tener minimamente 5 caracteres.");
      } else {
        setIsLoading(true);
        updatePassword();
      }
    }
  };

  const updatePassword = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.put(`/api/user/password`, password, {
      headers: { "auth-token": authToken },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setConfirmationModal(true);
        }
      })
      .catch((err) => {
        setError("Ha ocurrido un error.");
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    history.push("/home");
  };

  return (
    <div>
      <Loader show={isLoading}></Loader>
      <div className="accountbg"></div>
      <div className="wrapper-page">
        <div className="card">
          <div className="card-body">
            <div className="p-3">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin">
                  {/* <img
                                        src={require("../../../assets/images/logo3.png")}

                                        alt='logo'
                                    /> */}
                </Link>
              </h3>
              <p className="text-muted text-center">
                Cambie su contraseña para continuar.
              </p>
              <form
                className="form-horizontal m-t-30"
                id="form"
                onSubmit={handleSubmit}
                validate="true"
              >
                <div className="form-group">
                  <label>Contraseña</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={password.password}
                    onChange={handleChange}
                    required
                    placeholder="Contraseña"
                  />
                </div>

                <div className="form-group">
                  <label>Confirmar contraseña</label>
                  <input
                    type="password"
                    className="form-control"
                    name="confirmPassword"
                    value={password.confirmPassword}
                    onChange={handleChange}
                    required
                    placeholder="Confirmar contraseña"
                  />
                </div>
                {error ? <span className="error">{error}</span> : null}

                <div
                  className="form-group m-t-10 mb-0 "
                  style={{ display: "flow-root" }}
                >
                  <Button
                    variant="contained"
                    form="form"
                    type="submit"
                    className="button-login"
                  >
                    CONTINUAR
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        show={confirmationModal}
        onHide={closeModal}
        text={"Se ha cambiado la contraseña con éxito"}
      ></SuccessModal>
    </div>
  );
}
