import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Payments.scss";
import MaterialTable from "material-table";
import Moment from 'moment';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Icon } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";


export default function UserPayments(props) {

    const { checkLoggedIn, history } = props;
    const [users, setUsers] = useState([]);
    const [date, setDate] = useState({
        month: Moment(Date.now()).format('MM'),
        year: Moment(Date.now()).format('YYYY')
    });
    const [isLoading, setIsLoading] = useState(false);

    const setDataTable = (response) => {
        setUsers([]);
        let aux;
        let array = [];
        let userPaymentMap = new Map();
        if (response.paymentData.infractionsTotal.lenght !== 0 || response.paymentData.disapprovalTotal.lenght !== 0) {
            if (response.paymentData.infractionsTotal.lenght !== 0) {
                response.paymentData.infractionsTotal.map((total) => (
                    userPaymentMap.set(total.userId, total.totalInfractions)
                ))
            }

            if (response.paymentData.disapprovalTotal.lenght !== 0) {
                response.paymentData.disapprovalTotal.map(total => {
                    if (userPaymentMap.has(total.userId)) {
                        aux = userPaymentMap.get(total.userId) + total.totalDisapproval;
                        userPaymentMap.delete(total.userId);
                        userPaymentMap.set(total.userId, aux)
                    } else {
                        userPaymentMap.set(total.userId, total.totalDisapproval);
                    }
                    return null
                })
            }

            response.analyzers.map((user) => {
                let totalToPay = 0;
                if (userPaymentMap.has(user.id))
                    totalToPay = userPaymentMap.get(user.id);
                aux = {
                    cuil: user.CUIL,
                    cbu: user.CBU,
                    name: user.name,
                    lastname: user.lastname,
                    email: user.email,
                    phone: user.phone,
                    totalToPay: "$ " + Number(totalToPay).toFixed(2),
                };
                array.push(aux);
                return null
            })
            setUsers(array);

        }


    };

    const getUsers = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get("/api/payment/analyzers", {
            params: {
                month: date.month, year: date.year
            },
            headers: { "auth-token": authToken },
        }).then((response) => {
            setDataTable(response.data);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();


        search();
    }



    const handleChange = (event) => {
        setDate({
            ...date,
            [event.target.name]: event.target.value,
        });
    };
    const search = () => {
        const searchRecord = async () => {
            const authToken = await checkLoggedIn();
            if (!authToken) {
                return history.push("/");
            }
            await Axios.get("/api/payment/analyzers", {
                params: {
                    month: date.month, year: date.year
                },
                headers: { "auth-token": authToken },
            }).then((response) => {
                setIsLoading(false);
                setDataTable(response.data);


            });
        };
        setIsLoading(true);
        searchRecord();
    }


    return (
        <div className='userpayments-container' >
            <div className='userpayments-wrapper'>

                <div className="header">
                    <div className="text-header">
                        <h1>Generá órdenes de pago para cada usuario</h1>
                        <h2>Podés descargar la tabla haciendo click en el botón <Icon className="icons rotate-90">login</Icon></h2>
                    </div>

                    <div className="button-header">
                        <Button className='btn-celeste' href="/payments" >Cambiar montos</Button>
                    </div>
                </div>


                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >

                        <div className='subtitle'>
                            <Icon className="filter-icon">search</Icon><h2> Filtros</h2>
                        </div>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Form id='form' onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} md={2}>
                                    <Form.Label>Mes: </Form.Label>
                                    <Form.Control
                                        required
                                        name='month'
                                        as='select'
                                        value={date.month}
                                        onChange={handleChange}
                                    >
                                        <option hidden value="" >Selecciona...</option>
                                        <option value="01">Enero</option>
                                        <option value="02">Febrero</option>
                                        <option value="03">Marzo</option>
                                        <option value="04">Abril</option>
                                        <option value="05">Mayo</option>
                                        <option value="06">Junio</option>
                                        <option value="07">Julio</option>
                                        <option value="08">Agosto</option>
                                        <option value="09">Septiembre</option>
                                        <option value="10">Octubre</option>
                                        <option value="11">Noviembre</option>
                                        <option value="12">Diciembre</option>

                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} md={2}>
                                    <Form.Label>Año: </Form.Label>
                                    <Form.Control
                                        required
                                        name='year'
                                        as='select'
                                        value={date.year}
                                        onChange={handleChange}
                                    >
                                        <option hidden value="" >Selecciona...</option>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2027">2027</option>
                                        <option value="2028">2028</option>
                                        <option value="2029">2029</option>
                                        <option value="2030">2030</option>

                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                            <Button className='btn-celeste' type='submit'>Buscar</Button>
                        </Form>
                    </AccordionDetails>
                </Accordion>


                <div className='subtitle' style={{ marginTop: '1rem' }}>
                    <h1>Analizadores</h1>
                </div>
                <MaterialTable
                    columns={[
                        { title: "Nombre", field: "name" },
                        { title: "Apellido", field: "lastname" },
                        { title: "Email", field: "email" },
                        { title: "Teléfono", field: "phone" },
                        { title: "CUIL", field: "cuil" },
                        { title: "CBU", field: "cbu" },
                        { title: "Total a pagar", field: "totalToPay" },
                    ]}
                    data={users}
                    isLoading={isLoading}
                    title=' '
                    options={{
                        exportDelimiter: ";",
                        exportButton: true,
                        exportAllData: true,
                        exportFileName: date.year + "_" + date.month + "_Liquidacion mensual"
                    }}
                />


            </div>
            <img
                className='background-up'
                src={require("../../../assets/images/admin/userPayment.png")}
                alt=''
            />
            <img
                className='background-paper'
                src={require("../../../assets/images/admin/paper.png")}
                alt=''
            />
        </div>

    );
}

