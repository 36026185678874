import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.scss";
import Axios from "axios";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";

//Home
import Home from "./Home";
import HomeSupervisorAnalyzer from "./components/pages/Home/HomeSupervisorAnalyzer";
import HomeSupervisorTechnician from "./components/pages/Home/HomeSupervisorTechnician";
import HomeTechnician from "./components/pages/Home/HomeTechnician";
import HomeVerificador from "./components/pages/Home/HomeVerificador";

//Analyzer
import Analyzer from "./components/pages/Analyzer/Analyzer";
import Record from "./components/pages/Analyzer/Records/Record";

//Admin
import StatisticsAdminSup from "./components/pages/Statistics/StatisticsAdminSup";
import Payments from "./components/pages/Payments/Payments";
import UserPayments from "./components/pages/Payments/UserPayments";
import RegisterUser from "./components/pages/ABM Users/RegisterUser";
import Analyzers from "./components/pages/ABM Users/Analyzers/Analyzers";
import AnalyzerStatistics from "./components/pages/ABM Users/Analyzers/AnalyzerStatistics";
import EditUser from "./components/pages/ABM Users/EditUser";

//All
import MyAccount from "./components/pages/MyAccount/MyAccount";
import ChangePassword from "./components/pages/MyAccount/ChangePassword";

//Equipments
import Equipments from "./components/pages/Equipments/Equipments";
import AddEditEquipment from "./components/pages/Equipments/AddEditEquipment";
import EquipmentStatus from "./components/pages/Equipments/SupervisorTechnician/EquipmentStatus";

//Reviews - Supervisor
import ReviewsPerDay from "./components/pages/Review/Supervisor/ReviewsPerDay";
import ReviewsRecord from "./components/pages/Review/Supervisor/ReviewRecords";
import ReviewFirstStep from "./components/pages/Review/Supervisor/ReviewFirstStep";
import ReviewSecondStep from "./components/pages/Review/Supervisor/ReviewSecondStep";
import ReviewEdit from "./components/pages/Review/Supervisor/ReviewEdit";

//Reviews -Technician
import TechnicianReview from "./components/pages/Review/Technician/TechnicianReview";
import ReviewRecordsT from "./components/pages/Review/Technician/ReviewRecordsT";
import ReviewPreview from "./components/pages/Review/Technician/ReviewPreview";
import SpeedFirstStep from "./components/pages/Review/Technician/Speed/SpeedFirstStep";
import SpeedSecondStep from "./components/pages/Review/Technician/Speed/SpeedSecondStep";
import SpeedThirdStep from "./components/pages/Review/Technician/Speed/SpeedThirdStep";
import SpeedFourthStep from "./components/pages/Review/Technician/Speed/SpeedFourthStep";
import SpeedFifthStep from "./components/pages/Review/Technician/Speed/SpeedFifthStep";
import SemaphFirstStep from "./components/pages/Review/Technician/Semaphore/SemaphFirstStep";
import SemaphSecondStep from "./components/pages/Review/Technician/Semaphore/SemaphSecondStep";
import ReviewLastStep from "./components/pages/Review/Technician/ReviewLastStep";

//Repairs - Supervisor
import Repairs from "./components/pages/Repairs/Supervisor/Repairs";
import RepairEdit from "./components/pages/Repairs/Supervisor/RepairEdit";
import RepairsRecord from "./components/pages/Repairs/Supervisor/RepairsRecord";
import Layout from "./components/layout/Layout";
import Users from "./components/pages/ABM Users/Users";

//Camera status
import IndexCameraStatus from "./components/pages/Equipments/CameraStatus/IndexCameraStatus";
import AnalyzeCameraStatus from "./components/pages/Equipments/CameraStatus/AnalyzeCameraStatus";
import AnalyzeAllCameras from "./components/pages/Equipments/CameraStatus/AnalyzeAllCameras";

//Confugurar municipios
import ConfigMunicipalities from "./components/pages/ConfigMunicipalities/ConfigMunicipalities";

//Videos por equipo
import CheckVideos from "./components/pages/Equipments/CheckVideos/CheckVideos";

//Reportes
import ReportsAdmin from "./components/pages/Reports/ReportsAdmin";
import ReportsSupAnalizer from "./components/pages/Reports/ReportsSupAnalizer";
import AnalyzerTrucam from "./components/pages/AnalyzerImage/AnalyzerImage";

//Verificador
import VideoVerificator from "./components/pages/VideoVerificator/VideoVerifcator";
import VideoVerificatorIA from "./components/pages/VideoVerificatorIA/VideoVerifcatorIA";

export default function App() {
  const [userData, setUserData] = useState({
    loggedInStatus: "NOT_LOGGED_IN",
    authToken: "", //undefined
    tokenExpiresAt: "",
    id: "",
    email: "",
    roleId: "",
    paymentId: "",
    canAnalyzeSemaphore: false,
    canAnalyzeCinemometer: false,
    canAnalyzeTrucam: false,
    canAnalyzeParking: false,
  });

  const checkLoggedIn = async () => {
    if (
      userData.authToken !== "" &&
      userData.tokenExpiresAt > Date.now() / 1000
    ) {
      return userData.authToken;
    }
    let refreshToken = localStorage.getItem("refresh-token");
    if (refreshToken === null) {
      return false;
    }
    const response3 = await Axios.post("/api/user/refreshToken", null, {
      headers: {
        "refresh-token": refreshToken,
      },
    });

    if (response3.data) {
      setUserData({
        loggedInStatus: "LOGGED_IN",
        authToken: response3.data.authToken,
        tokenExpiresAt: response3.data.expiresAt,
        id: response3.data.id,
        email: response3.data.email,
        roleId: response3.data.roleId,
        paymentId: response3.data.paymentId,
        passwordChanged: response3.data.passwordChanged,
        canAnalyzeSemaphore: response3.data.canAnalyzeSemaphore,
        canAnalyzeCinemometer: response3.data.canAnalyzeCinemometer,
        canAnalyzeTrucam: response3.data.canAnalyzeTrucam,
        canAnalyzeParking: response3.data.canAnalyzeParking,
      });
      return response3.data.authToken;
    } else {
      return false;
    }
  };

  useEffect(() => {
    checkLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleLogin(data) {
    setUserData({
      loggedInStatus: "LOGGED_IN",
      authToken: data.authToken,
      tokenExpiresAt: data.expiresAt,
      id: data.id,
      email: data.email,
      roleId: data.roleId,
      canAnalyzeSemaphore: data.canAnalyzeSemaphore,
      canAnalyzeCinemometer: data.canAnalyzeCinemometer,
      canAnalyzeTrucam: data.canAnalyzeTrucam,
      canAnalyzeParking: data.canAnalyzeParking,
    });

    localStorage.setItem("refresh-token", data.refreshToken);
  }

  function handleLogout(props) {
    setUserData({
      loggedInStatus: "NOT_LOGGED_IN",
      authToken: "", //undefined
      tokenExpiresAt: "",
      id: "",
      email: "",
      roleId: "",
      paymentId: "",
      canAnalyzeSemaphore: false,
      canAnalyzeCinemometer: false,
      canAnalyzeTrucam: false,
      canAnalyzeParking: false,
    });
    localStorage.clear();
  }

  const AnalyzerContainer = () => (
    <div>
      <Route
        path={"/home"}
        render={(props) => (
          <Analyzer
            {...props}
            loggedInStatus={userData.loggedInStatus}
            handleLogout={handleLogout}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          />
        )}
      />

      <Route
        path={"/analyzer"}
        render={(props) => (
          <Analyzer
            {...props}
            loggedInStatus={userData.loggedInStatus}
            handleLogout={handleLogout}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          />
        )}
      />

      <Route
        path={"/trues/analyzer/:municipalityId/:equipmentType"}
        render={(props) => (
          <AnalyzerTrucam
            {...props}
            loggedInStatus={userData.loggedInStatus}
            handleLogout={handleLogout}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          />
        )}
      />

      <Route
        path={"/record/:userId"}
        render={(props) => (
          <Record
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          />
        )}
      />
    </div>
  );

  const SupervisorAnalyzerContainer = () => (
    <div>
      <Route
        exact
        path={"/home"}
        render={(props) => (
          <HomeSupervisorAnalyzer
            {...props}
            checkLoggedIn={checkLoggedIn}
          ></HomeSupervisorAnalyzer>
        )}
      />
      <Route
        exact
        path={"/statistics"}
        render={(props) => (
          <StatisticsAdminSup
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></StatisticsAdminSup>
        )}
      />

      <Route
        exact
        path={"/reporte/analizadores"}
        render={(props) => (
          <ReportsSupAnalizer
            {...props}
            checkLoggedIn={checkLoggedIn}
          ></ReportsSupAnalizer>
        )}
      />
      <Route
        exact
        path={"/users"}
        render={(props) => (
          <Analyzers
            {...props}
            userData={userData}
            checkLoggedIn={checkLoggedIn}
          ></Analyzers>
        )}
      />
      <Route
        exact
        path={"/register"}
        render={(props) => (
          <RegisterUser
            {...props}
            userData={userData}
            checkLoggedIn={checkLoggedIn}
          ></RegisterUser>
        )}
      />
      <Route
        exact
        path={"/edit/:userId"}
        render={(props) => (
          <EditUser
            {...props}
            userData={userData}
            checkLoggedIn={checkLoggedIn}
          ></EditUser>
        )}
      />
      <Route
        exact
        path={"/payments"}
        render={(props) => (
          <Payments {...props} checkLoggedIn={checkLoggedIn}></Payments>
        )}
      />
      <Route
        exact
        path={"/userPayments"}
        render={(props) => (
          <UserPayments {...props} checkLoggedIn={checkLoggedIn}></UserPayments>
        )}
      />
      <Route
        exact
        path={"/statistics/:userId"}
        render={(props) => (
          <AnalyzerStatistics
            {...props}
            checkLoggedIn={checkLoggedIn}
          ></AnalyzerStatistics>
        )}
      />
      <Route
        exact
        path={"/configMunicipalities"}
        render={(props) => (
          <ConfigMunicipalities
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ConfigMunicipalities>
        )}
      />
    </div>
  );

  const AdminContainer = () => (
    <div>
      <Route
        exact
        path={"/home"}
        render={(props) => (
          <HomeSupervisorAnalyzer
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></HomeSupervisorAnalyzer>
        )}
      />
      <Route
        exact
        path={"/reporte"}
        render={(props) => (
          <ReportsAdmin {...props} checkLoggedIn={checkLoggedIn}></ReportsAdmin>
        )}
      />
      <Route
        exact
        path={"/reporte/analizadores"}
        render={(props) => (
          <ReportsSupAnalizer
            {...props}
            checkLoggedIn={checkLoggedIn}
          ></ReportsSupAnalizer>
        )}
      />
      <Route
        exact
        path={"/register"}
        render={(props) => (
          <RegisterUser
            {...props}
            userData={userData}
            checkLoggedIn={checkLoggedIn}
          ></RegisterUser>
        )}
      />
      <Route
        exact
        path={"/users"}
        render={(props) => (
          <Users {...props} checkLoggedIn={checkLoggedIn}></Users>
        )}
      />
      <Route
        exact
        path={"/statistics"}
        render={(props) => (
          <StatisticsAdminSup
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></StatisticsAdminSup>
        )}
      />
      <Route
        exact
        path={"/payments"}
        render={(props) => (
          <Payments {...props} checkLoggedIn={checkLoggedIn}></Payments>
        )}
      />
      <Route
        exact
        path={"/userPayments"}
        render={(props) => (
          <UserPayments {...props} checkLoggedIn={checkLoggedIn}></UserPayments>
        )}
      />
      <Route
        exact
        path={"/edit/:userId"}
        render={(props) => (
          <EditUser
            {...props}
            userData={userData}
            checkLoggedIn={checkLoggedIn}
          ></EditUser>
        )}
      />
      <Route
        exact
        path={"/statistics/:userId"}
        render={(props) => (
          <AnalyzerStatistics
            {...props}
            checkLoggedIn={checkLoggedIn}
          ></AnalyzerStatistics>
        )}
      />

      <Route
        exact
        path={"/equipments"}
        render={(props) => (
          <Equipments {...props} checkLoggedIn={checkLoggedIn}></Equipments>
        )}
      />

      <Route
        exact
        path={"/equipments/add"}
        render={(props) => (
          <AddEditEquipment
            {...props}
            userData={userData}
            checkLoggedIn={checkLoggedIn}
          ></AddEditEquipment>
        )}
      />
      <Route
        exact
        path={"/equipments/edit/:id"}
        render={(props) => (
          <AddEditEquipment
            {...props}
            userData={userData}
            checkLoggedIn={checkLoggedIn}
          ></AddEditEquipment>
        )}
      />
      <Route
        exact
        path={"/equipments/status"}
        render={(props) => (
          <EquipmentStatus
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></EquipmentStatus>
        )}
      />

      <Route
        exact
        path={"/cameraStatus"}
        render={(props) => (
          <IndexCameraStatus
            {...props}
            checkLoggedIn={checkLoggedIn}
          ></IndexCameraStatus>
        )}
      />
      <Route
        exact
        path={"/cameraStatus/:id/:type"}
        render={(props) => (
          <AnalyzeCameraStatus
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></AnalyzeCameraStatus>
        )}
      />
      <Route
        exact
        path={"/all/cameraStatus/:municipality"}
        render={(props) => (
          <AnalyzeAllCameras
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></AnalyzeAllCameras>
        )}
      />
      <Route
        exact
        path={"/configMunicipalities"}
        render={(props) => (
          <ConfigMunicipalities
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ConfigMunicipalities>
        )}
      />
      <Route
        exact
        path={"/checkvideos"}
        render={(props) => (
          <CheckVideos
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></CheckVideos>
        )}
      />
    </div>
  );

  const TechnicianContainer = () => (
    <div>
      <Route
        exact
        path={"/home"}
        render={(props) => (
          <HomeTechnician
            {...props}
            checkLoggedIn={checkLoggedIn}
          ></HomeTechnician>
        )}
      />

      <Route
        exact
        path={"/review/info"}
        render={(props) => (
          <ReviewsPerDay
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ReviewsPerDay>
        )}
      />

      <Route
        exact
        path={"/reviews/today"}
        render={(props) => (
          <TechnicianReview
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></TechnicianReview>
        )}
      />

      <Route
        exact
        path={"/reviews/all"}
        render={(props) => (
          <ReviewRecordsT
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ReviewRecordsT>
        )}
      />

      <Route
        exact
        path={"/review/:id"}
        render={(props) => (
          <ReviewPreview
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ReviewPreview>
        )}
      />
      <Route
        exact
        path={"/review/speed/first/:id"}
        render={(props) => (
          <SpeedFirstStep
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></SpeedFirstStep>
        )}
      />
      <Route
        exact
        path={"/review/speed/second/:id"}
        render={(props) => (
          <SpeedSecondStep
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></SpeedSecondStep>
        )}
      />
      <Route
        exact
        path={"/review/speed/third/:id"}
        render={(props) => (
          <SpeedThirdStep
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></SpeedThirdStep>
        )}
      />
      <Route
        exact
        path={"/review/speed/fourth/:id"}
        render={(props) => (
          <SpeedFourthStep
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></SpeedFourthStep>
        )}
      />
      <Route
        exact
        path={"/review/speed/fifth/:id"}
        render={(props) => (
          <SpeedFifthStep
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></SpeedFifthStep>
        )}
      />

      <Route
        exact
        path={"/review/semaphore/first/:id"}
        render={(props) => (
          <SemaphFirstStep
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></SemaphFirstStep>
        )}
      />
      <Route
        exact
        path={"/review/semaphore/second/:id"}
        render={(props) => (
          <SemaphSecondStep
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></SemaphSecondStep>
        )}
      />
      <Route
        exact
        path={"/review/last/:id"}
        render={(props) => (
          <ReviewLastStep
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ReviewLastStep>
        )}
      />

      <Route
        exact
        path={"/cameraStatus"}
        render={(props) => (
          <IndexCameraStatus
            {...props}
            checkLoggedIn={checkLoggedIn}
          ></IndexCameraStatus>
        )}
      />
      <Route
        exact
        path={"/cameraStatus/:id/:type"}
        render={(props) => (
          <AnalyzeCameraStatus
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></AnalyzeCameraStatus>
        )}
      />
      <Route
        exact
        path={"/all/cameraStatus/:municipality"}
        render={(props) => (
          <AnalyzeAllCameras
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></AnalyzeAllCameras>
        )}
      />
      <Route
        exact
        path={"/checkvideos"}
        render={(props) => (
          <CheckVideos
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></CheckVideos>
        )}
      />
    </div>
  );

  const SupervisorTechnicianContainer = () => (
    <div>
      <Route
        exact
        path={"/home"}
        render={(props) => (
          <HomeSupervisorTechnician {...props}></HomeSupervisorTechnician>
        )}
      />

      <Route
        exact
        path={"/review"}
        render={(props) => (
          <ReviewsPerDay
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ReviewsPerDay>
        )}
      />
      <Route
        exact
        path={"/review/records"}
        render={(props) => (
          <ReviewsRecord
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ReviewsRecord>
        )}
      />
      <Route
        exact
        path={"/review/start"}
        render={(props) => (
          <ReviewFirstStep
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ReviewFirstStep>
        )}
      />

      <Route
        exact
        path={"/review/start/:equipmentId"}
        render={(props) => (
          <ReviewSecondStep
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ReviewSecondStep>
        )}
      />

      <Route
        exact
        path={"/review/edit/:reviewId"}
        render={(props) => (
          <ReviewEdit
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></ReviewEdit>
        )}
      />

      <Route
        exact
        path={"/equipments"}
        render={(props) => (
          <Equipments {...props} checkLoggedIn={checkLoggedIn}></Equipments>
        )}
      />
      <Route
        exact
        path={"/equipments/status"}
        render={(props) => (
          <EquipmentStatus
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></EquipmentStatus>
        )}
      />

      <Route
        exact
        path={"/equipments/add"}
        render={(props) => (
          <AddEditEquipment
            {...props}
            userData={userData}
            checkLoggedIn={checkLoggedIn}
          ></AddEditEquipment>
        )}
      />
      <Route
        exact
        path={"/equipments/edit/:id"}
        render={(props) => (
          <AddEditEquipment
            {...props}
            userData={userData}
            checkLoggedIn={checkLoggedIn}
          ></AddEditEquipment>
        )}
      />
      <Route
        exact
        path={"/repairs"}
        render={(props) => (
          <Repairs
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></Repairs>
        )}
      />
      <Route
        exact
        path={"/repairs/record"}
        render={(props) => (
          <RepairsRecord
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></RepairsRecord>
        )}
      />
      <Route
        exact
        path={"/repair/edit/:repairId"}
        render={(props) => (
          <RepairEdit
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></RepairEdit>
        )}
      />
      <Route
        exact
        path={"/cameraStatus"}
        render={(props) => (
          <IndexCameraStatus
            {...props}
            checkLoggedIn={checkLoggedIn}
          ></IndexCameraStatus>
        )}
      />
      <Route
        exact
        path={"/cameraStatus/:id/:type"}
        render={(props) => (
          <AnalyzeCameraStatus
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></AnalyzeCameraStatus>
        )}
      />
      <Route
        exact
        path={"/all/cameraStatus/:municipality"}
        render={(props) => (
          <AnalyzeAllCameras
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          ></AnalyzeAllCameras>
        )}
      />
    </div>
  );

  const VisualizadorReportesContainer = () => (
    <div>
      <Route
        exact
        path={"/home"}
        render={(props) => (
          <HomeSupervisorTechnician {...props}></HomeSupervisorTechnician>
        )}
      />
      <Route
        exact
        path={"/reporte"}
        render={(props) => (
          <ReportsAdmin {...props} checkLoggedIn={checkLoggedIn}></ReportsAdmin>
        )}
      />
    </div>
  );

  const VerificadorVideosContainer = () => (
    <div>
      <Route
        path={"/home"}
        render={(props) => (
          <HomeVerificador
            {...props}
            loggedInStatus={userData.loggedInStatus}
            handleLogout={handleLogout}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          />
        )}
      />

      <Route
        path={"/verificacion"}
        render={(props) => (
          <VideoVerificator
            {...props}
            loggedInStatus={userData.loggedInStatus}
            handleLogout={handleLogout}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          />
        )}
      />

      <Route
        path={"/verificacionIA"}
        render={(props) => (
          <VideoVerificatorIA
            {...props}
            loggedInStatus={userData.loggedInStatus}
            handleLogout={handleLogout}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          />
        )}
      />
      <Route
        path={"/record/:userId"}
        render={(props) => (
          <Record
            {...props}
            checkLoggedIn={checkLoggedIn}
            userData={userData}
          />
        )}
      />
    </div>
  );

  return (
    <div className="App">
      <Router>
        <Layout
          checkLoggedIn={checkLoggedIn}
          handleLogout={handleLogout}
          userData={userData}
        />
        <Switch>
          <Redirect exact from="/" to="/login" />

          <Route
            exact
            path={"/login"}
            render={(props) => (
              <Home
                {...props}
                handleLogin={handleLogin}
                loggedInStatus={userData.loggedInStatus}
              />
            )}
          />

          <Route
            exact
            path={"/account"}
            render={(props) => (
              <MyAccount
                {...props}
                checkLoggedIn={checkLoggedIn}
                userData={userData}
              ></MyAccount>
            )}
          />

          <Route
            exact
            path={"/changePassword"}
            render={(props) => (
              <ChangePassword
                {...props}
                checkLoggedIn={checkLoggedIn}
                userData={userData}
              ></ChangePassword>
            )}
          />
          {userData.roleId === 1 ? (
            <Route component={AdminContainer} />
          ) : userData.roleId === 2 ? (
            <Route component={AnalyzerContainer} />
          ) : userData.roleId === 3 ? (
            <Route component={SupervisorAnalyzerContainer} />
          ) : userData.roleId === 4 ? (
            <Route component={TechnicianContainer} />
          ) : userData.roleId === 5 ? (
            <Route component={SupervisorTechnicianContainer} />
          ) : userData.roleId === 6 ? (
            <Route component={VisualizadorReportesContainer} />
          ) : userData.roleId === 7 ? (
            <Route component={VerificadorVideosContainer} />
          ) : null}
        </Switch>
      </Router>
    </div>
  );
}
