import React from "react";

export default class ImageEditor extends React.Component {
  componentDidMount() {
    this.props.makeCropper();
  }
  componentDidUpdate() {
    this.props.makeCropper();
  }
  render() {
    return (
      <div>
        <div></div>
      </div>
    );
  }
}
