import React from "react";
import Axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Moment from "moment";
import { OnOverflowModal } from "../../Analyzer/Modals/OnOverflowModal.jsx";

import "./style.scss";
import "cropperjs/dist/cropper.css";
import CropperJS from "cropperjs";
import ImageEditor from "./Carrusel/ImageEditor";
import ReactDOM from "react-dom";
import ImageCarousel from "./Carrusel/ImageCarousel.jsx";
import ImageList from "./Carrusel/ImageList";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/esm/Row";
import { Button } from "react-bootstrap";
import Loader from "../../../utils/Loader/Loader";
import EquipmentType from "../../../utils/Constants/EquipmentType.js";
import Select from "react-select";
import BusquedaVideo from "./Components/BusquedaVideo.jsx";
import VideoInfo from "./Components/VideoInfo.jsx";

export default class AnalyzeCameraStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoId: "",
      equipmentSelected: {
        equipmentId: props.match.params.id,
        equipmentType: props.match.params.type,
      },
      equipments: [],
      videoIdSearch: 0,
      images: [],
      videoName: "",
      frames: null,
      cameraMAC: "",
      videoDate: "",
      captures: [],
      noVideos: "",
      location: "",
      serialNumber: "",
      data: null,
      framesToPost: [],
      dateFrom: Moment().startOf("month").format("YYYY-MM-DDTHH:mm"),
      dateTo: Moment(Date.now()).format("YYYY-MM-DDTHH:mm"),
      option: "0",
      number: 0, //para ver qué video tiene que traer, si el primero más reciente, el segundo, etc
      curImageSelected: 0,
      modalActive: false,
      curImageSrc: "",
      scrollPos: 0,
      errorDate: "",
      //Modals data
      textOverflowModal: "",
      validated: false,
      onOverflowModal: false,
    };
    this.addOnOverflowModal = this.addOnOverflowModal.bind(this);
    this.resetQueue = this.resetQueue.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.makeCropper = this.makeCropper.bind(this);
    this.requestImages = this.requestImages.bind(this);
    this.removeIndexFromQueue = this.removeIndexFromQueue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  addOnOverflowModal = () => {
    this.setState({
      onOverflowModal: false,
    });
  };

  handleReset = () => {
    this.setState({ resetTimer: false });
  };

  //init cropper js and add event listeners for cropping buttons
  makeCropper(imageSrc) {
    let cropper = new CropperJS(document.querySelector(".selected-img"), {
      initialAspectRatio:
        document.querySelector(".selected-img").width /
        document.querySelector(".selected-img").height,
      viewMode: 2,
      zoomable: true,
      preview: ".crop-preview-container",
      modal: true,
      autoCropArea: 0.1,
    });
    document.getElementById("crop-img").addEventListener("click", () => {
      let croppedCanvas = cropper.getCroppedCanvas();

      let url = croppedCanvas.toDataURL("image/jpeg");

      let newFramesToPost = [...this.state.framesToPost, url];
      this.setState(
        {
          modalActive: false,
        },
        () => {
          this.updateComponents(newFramesToPost);
        }
      );
    });
  }
  //fires after user clicks on image in slideshow
  onImageSelected(imageSrc, imageIndex) {
    if (this.state.framesToPost.length < 1) {
      this.updateComponents([...this.state.framesToPost, imageSrc]);
    } else if (this.state.framesToPost.length < 2) {
      this.setState(
        {
          curImageSelected: imageIndex,
          modalActive: true,
          curImageSrc: imageSrc,
        },
        () => {
          this.updateComponents(this.state.framesToPost);
          if (document.querySelector(".croppr-container")) {
            document
              .querySelector(".croppr-container")
              .parentNode.removeChild(
                document.querySelector(".croppr-container")
              );
          }
          ReactDOM.render(
            <ImageEditor
              imageSrc={imageSrc}
              makeCropper={() => {
                this.makeCropper(this.state.curImageSrc);
              }}
            />,
            document.getElementById("cropped-img-container")
          );
        }
      );
    } else {
      this.setState({
        textOverflowModal: "Solo se pueden seleccionar 2 imagenes.",
        onOverflowModal: true,
      });
    }
  }
  closeModal() {
    this.setState(
      {
        modalActive: false,
      },
      () => {
        this.updateComponents(this.state.framesToPost);
      }
    );
  }

  async getFrameCaptures(callback) {
    let this2 = this;
    let frameCaptures = [];
    for (let i = 0; i < this.state.frames.length; i++) {
      let imgElement = (
        <img
          alt=""
          src={this.state.frames[i]}
          onClick={() => this2.onImageSelected(this.state.frames[i], i)}
        />
      );
      frameCaptures.push(imgElement);
      if (i + 1 === this.state.frames.length) {
        callback(frameCaptures);
      }
    }
  }

  resetQueue() {
    this.updateComponents([]);
  }
  removeIndexFromQueue(i) {
    let newQueue = [...this.state.framesToPost];
    newQueue.splice(i, 1);
    this.updateComponents(newQueue);
  }
  async updateComponents(newFramesToPost = [], callback = () => {}) {
    if (newFramesToPost.length > 4) {
      newFramesToPost = newFramesToPost.slice(0, 4);
    }

    if (this.state.captures.length === 0) {
      await this.getFrameCaptures((frames) => {
        this.setState({
          captures: frames,
          framesToPost: newFramesToPost,
          data: (
            <div id="main-container">
              <ImageCarousel
                removeIndexFromQueue={(i) => {
                  this.removeIndexFromQueue(i);
                }}
                curImageSrc={this.state.curImageSrc}
                closeModal={this.closeModal}
                modalActive={this.state.modalActive}
                curImageSelected={this.state.curImageSelected}
                resetQueue={this.resetQueue}
                framesToPost={this.state.framesToPost}
                getFrameCaptures={this.getFrameCaptures}
                onImageSelected={this.onImageSelected}
                clickToChange
                slides={frames}
              />
            </div>
          ),
        });
      });
    } else {
      this.setState({
        framesToPost: newFramesToPost,
        data: (
          <div id="main-container">
            <ImageCarousel
              removeIndexFromQueue={(i) => {
                this.removeIndexFromQueue(i);
              }}
              curImageSrc={this.state.curImageSrc}
              closeModal={this.closeModal}
              modalActive={this.state.modalActive}
              curImageSelected={this.state.curImageSelected}
              resetQueue={this.resetQueue}
              framesToPost={newFramesToPost}
              getFrameCaptures={this.getFrameCaptures}
              onImageSelected={this.onImageSelected}
              clickToChange
              slides={this.state.captures}
            />
          </div>
        ),
      });
    }
  }

  async getEquipments() {
    const authToken = await this.getAuthToken();

    await Axios.get(`/api/equipment`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      let equipments = [];
      let aux;
      response.data.equipments.forEach((x) => {
        let type = x.equipmentTypeId === EquipmentType.SEMAFORO ? "s" : "v";
        aux = {
          value: x.equipmentId,
          label: x.equipmentId,
          type: type,
        };
        equipments.push(aux);
      });
      this.setState({ equipments: equipments });
    });
  }

  async componentDidMount() {
    this.getEquipments();
    await this.getEquipment();
    if (
      this.state.equipmentSelected.equipmentTypeId === EquipmentType.VELOCIDAD
    )
      await this.getInfraction();
    else await this.getVideo();
  }

  async getEquipment() {
    const authToken = await this.getAuthToken();

    await Axios.get(
      `/api/equipment/${this.state.equipmentSelected.equipmentId}`,
      {
        headers: { "auth-token": authToken },
      }
    ).then((response) => {
      this.setState({ equipmentSelected: response.data.equipment });
    });
  }

  handleChange = (event) => {
    if (event.target.value !== "0") this.setState({ number: 0 });
    this.setState({ [event.target.name]: event.target.value });
  };

  async getAuthToken() {
    const authToken = await this.props.checkLoggedIn();
    if (!authToken) {
      this.props.history.push("/");
      return;
    }
    return authToken;
  }

  download = async () => {
    if (
      this.state.equipmentSelected.equipmentTypeId === EquipmentType.SEMAFORO
    ) {
      // Si es un equipo de semaforo descarga el video
      const authToken = await this.getAuthToken();

      await Axios.get(`/api/user/video/get/${this.state.videoId}`, {
        headers: { "auth-token": authToken },
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", this.state.videoName);
        document.body.appendChild(link);
        link.click();
      });
    } else {
      // Si es cinemometro, descarga la imagen
      const link = document.createElement("a");
      link.href = this.state.frames;
      link.setAttribute("download", "imagen.jpg");
      document.body.appendChild(link);
      link.click();
    }
  };

  //Consumes the API requesting a video
  async getInfraction() {
    const authToken = await this.getAuthToken();

    this.setState({ noVideos: "" });
    Axios.get(
      `/api/equipment/${this.state.equipmentSelected.equipmentId}/infraction`,
      {
        headers: { "auth-token": authToken },
        params: {
          option: this.state.option,
          number: this.state.number,
          from: this.state.dateFrom,
          to: this.state.dateTo,
        },
        responseType: "blob",
      }
    )
      .then(async (res) => {
        if (this.state.option === "0")
          this.setState({ number: this.state.number + 1 });

        if (res.status === 204) {
          this.setState({
            cameraMAC: " - ",
            videoDate: " - ",
            data: true,
            noVideos:
              "No hay infracciones para el rango de fecha seleccionado.",
          });
        } else {
          //Build a URL from the file
          const fileURL = URL.createObjectURL(res.data);
          this.setState({
            data: (
              <div id="main-container">
                <img className="img-vel" alt="Imagen vel" src={fileURL}></img>
              </div>
            ),
          });
          this.setState((state) => {
            return { ...state, frames: fileURL };
          });
        }
      })
      .catch((err) => {
        this.setState({
          cameraMAC: " - ",
          videoDate: " - ",
          data: true,
          noVideos: "Ha ocurrido un error. Por favor, intente nuevamente.",
        });
      });
  }

  saltar10 = async () => {
    this.setState({ number: this.state.number + 10 });

    if (this.state.equipmentSelected.equipmentTypeId === EquipmentType.SEMAFORO)
      await this.getVideo();
    else await this.getInfraction();
  };

  //Consumes the API requesting a video
  async getVideo() {
    this.resetVariables();
    const authToken = await this.getAuthToken();

    Axios.get(
      `/api/user/video/get/equipment/${this.state.equipmentSelected.equipmentId}`,
      {
        headers: { "auth-token": authToken },
        params: {
          option: this.state.option,
          number: this.state.number,
          from: this.state.dateFrom,
          to: this.state.dateTo,
          videoId: this.state.videoIdSearch,
        },
      }
    )
      .then((response) => {
        if (this.state.option === "0")
          this.setState({ number: this.state.number + 1 });

        if (response.status === 204) {
          this.resetVariables();
          this.setState({
            data: true,
            noVideos: "No hay videos disponibles para la opción seleccionada.",
          });
        } else {
          this.setState({
            noVideos: "",
            videoDate: Moment(response.data.videoInfo[0].recordedAt).format(
              "DD-MM-YYYY HH:mm"
            ),
            videoId: response.data.videoInfo[0].videoId,
          });

          this.state.videoName = response.data.videoInfo[0].name;
          this.state.cameraMAC = response.data.videoInfo[0].cameraMAC;
          this.getFrames(response.data.videoInfo[0].videoId, authToken);
        }
      })
      .catch((err) => {
        this.setState({
          cameraMAC: " - ",
          videoDate: " - ",
          data: true,
          noVideos: "Ha ocurrido un error. Por favor, intente nuevamente.",
        });
      });
  }

  resetVariables() {
    this.setState({
      images: [],
      framesToPost: [],
      videoName: " - ",
      frames: null,
      cameraMAC: " - ",
      videoDate: " - ",
      captures: [],
      noVideos: "",
      data: null,
      videoId: " - ",
    });
  }

  getFrames(videoId, authToken) {
    Axios.get(`/api/user/video/frames/${videoId}`, {
      headers: { "auth-token": authToken },
    })
      .then(async (res) => {
        if (res.data.framesURLs.length !== 0) {
          this.state.images = res.data.framesURLs;
          let frames = await this.requestImages(this.state.images);
          this.setState({ frames: frames });
          this.updateComponents(this.state.framesToPost);
        } else {
          this.setState({
            cameraMAC: " - ",
            videoDate: " - ",
            data: true,
            noVideos: "Ha ocurrido un error. Por favor, intente nuevamente.",
          });
        }
      })
      .catch((err) => {
        this.setState({
          cameraMAC: " - ",
          videoDate: " - ",
          data: true,
          noVideos: "Ha ocurrido un error. Por favor, intente nuevamente.",
        });
      });
  }

  async requestImages(images) {
    let frames = [];
    const options = {
      headers: {
        "auth-token": this.props.userData.authToken,
        videoname: this.state.videoName,
      },
    };
    let promises = [];
    for (const frameURL of images) {
      promises.push(
        fetch(frameURL, options)
          .then((response) => response.blob())
          .then((blob) => {
            frames[
              frameURL.slice(frameURL.lastIndexOf("/") + 1, frameURL.length) - 1
            ] = URL.createObjectURL(blob);
          })
      );
    }
    await Promise.all(promises);

    return frames;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.state.errorDate = "";

    if (this.state.option === "1") {
      if (this.dateFromBiggerThanDateTo()) {
        this.setState({
          errorDate: "Fecha desde no puede ser mayor a fecha hasta",
        });
        return;
      }
    }

    this.resetVariables();
    if (this.state.equipmentSelected.equipmentTypeId === EquipmentType.SEMAFORO)
      await this.getVideo();
    else await this.getInfraction();
  };

  dateFromBiggerThanDateTo = () => {
    var isBigger = false;
    const dt = new Date(this.state.dateTo);
    const df = new Date(this.state.dateFrom);
    if (df > dt) {
      isBigger = true;
    }
    return isBigger;
  };

  render() {
    return (
      <div>
        <Loader show={!this.state.data} />
        <div className="main-container">
          <div className="cropper-card">
            <div className="header">
              <h1>
                {" "}
                Vea el estado de la cámara del equipo #
                {this.state.equipmentSelected
                  ? this.state.equipmentSelected.equipmentId
                  : 0}
              </h1>
              <div className="spacer"></div>
              <span style={{ alignSelf: "center" }}>Equipo:</span>
              <div
                style={{
                  minWidth: "11rem",
                  alignSelf: "center",
                  marginLeft: "0.2rem",
                }}
              >
                <Select
                  value={{
                    label: this.state.equipmentSelected.equipmentId,
                    value: this.state.equipmentSelected.equipmentId,
                  }}
                  onChange={(e) => {
                    this.setState({
                      equipmentSelected: { equipmentId: e.value, type: e.type },
                    });
                    this.props.history.push(
                      `/cameraStatus/${e.value}/${e.type}`
                    );
                    window.location.reload(false);
                  }}
                  options={this.state.equipments}
                  placeholder="Equipo..."
                />
              </div>
            </div>
            <div className="header-descripcion">
              <h2>Haga click sobre las imágenes para seleccionarlas</h2>
            </div>
            <Row>
              <Col lg={8} md={7} sm={12}>
                {this.state.noVideos !== "" ? (
                  <div className="no-videos">
                    <img
                      src={require("../../../../assets/images/alert-sign.png")}
                      alt="success"
                    />
                    {this.state.noVideos}
                  </div>
                ) : (
                  <div>{this.state.data}</div>
                )}
              </Col>
              <Col lg={4} md={5} sm={12}>
                <Row>
                  <Col md={12}>
                    <div className="download-control-container analyze-container">
                      <div>
                        <BusquedaVideo
                          handleSubmit={this.handleSubmit}
                          handleChange={this.handleChange}
                          state={this.state}
                          saltar10={this.saltar10}
                        ></BusquedaVideo>
                        <VideoInfo
                          state={this.state}
                          municipalityName={
                            this.state.equipmentSelected.Municipality
                              ? this.state.equipmentSelected.Municipality?.name
                              : null
                          }
                        ></VideoInfo>
                        <div
                          id="cropper-container"
                          style={{ minHeight: "3vh" }}
                        >
                          <div id="cropped-img-container" />
                          {this.state.framesToPost.length === 0 ? (
                            <div></div>
                          ) : (
                            <ImageList
                              removeIndexFromQueue={(i) => {
                                this.removeIndexFromQueue(i);
                              }}
                              images={this.state.framesToPost}
                            />
                          )}
                        </div>
                      </div>
                      <div className="but-container">
                        <Button
                          className="tool-button"
                          id="copy-button"
                          variant="primary"
                          disabled={this.state.noVideos !== ""}
                          onClick={() => this.download()}
                        >
                          {this.state.equipmentSelected &&
                          this.state.equipmentSelected.equipmentTypeId ===
                            EquipmentType.SEMAFORO
                            ? "DESCARGAR VIDEO"
                            : "DESCARGAR IMAGEN"}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <OnOverflowModal
          show={this.state.onOverflowModal}
          onHide={this.addOnOverflowModal}
          text={this.state.textOverflowModal}
        ></OnOverflowModal>
      </div>
    );
  }
}
