import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../Review.scss";
import MaterialTable from "material-table";
import { Button, Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import { ReviewDetailsModal } from "../Modals/ReviewDetailsModal";

export default function ReviewsPerDay(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn } = props;
  const [reviews, setReviews] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);
  const history = useHistory();
  const [showReviewDetailsModal, setShowReviewDetailsModal] = useState(false);
  const [reviewSelected, setReviewSelected] = useState({});

  const setDataTable = (response) => {
    var aux;
    let array = [];
    response.map((review) => {
      let technicians = "";
      if (review.Technicians[0] && review.Technicians[1])
        technicians =
          review.Technicians[0].name +
          " " +
          review.Technicians[0].lastname +
          ", " +
          review.Technicians[1].name +
          " " +
          review.Technicians[1].lastname;
      else if (review.Technicians[0])
        technicians =
          review.Technicians[0].name + " " + review.Technicians[0].lastname;
      else technicians = "Error";
      let finishedAt = Moment(review.finishedAt).utc().format("DD/MM/YYYY");
      if (review.statusId !== 2) finishedAt = "-";

      let postponedAt;
      if (review.postponedAt) {
        postponedAt = Moment(review.postponedAt).utc().format("DD/MM/YYYY");
      } else postponedAt = "-";

      let problemDescription = "";
      review.ReviewProblems.map((problem) => {
        if (problem.description !== "Otros") {
          problemDescription += problem.description + ", ";
        } else {
          problemDescription += review.otherProblemDescription;
        }
        return null;
      });

      let solutionDescription = "";
      review.ReviewSolutions.map((solution) => {
        if (solution.description !== "Otro") {
          solutionDescription += solution.description + ", ";
        } else {
          solutionDescription += review.otherSolutionDescription;
        }
        return null;
      });

      aux = {
        Equipment: review.Equipment,
        nroEquipment: review.Equipment.serialNumber,
        reviewId: review.reviewId,
        id: "#" + review.reviewId,
        ReviewProblems: review.ReviewProblems,
        Technicians: review.Technicians,
        problemDescription: problemDescription,
        reasonPostponement: review.reasonPostponement,
        address: review.Equipment.street + " " + review.Equipment.streetNumber,
        status: review.Status.description,
        technicians: technicians,
        solutionDescription: solutionDescription,
        additionalComment: review.additionalComment,
        startedAt: Moment(review.startedAt).utc().format("DD/MM/YYYY"),
        finishedAt: finishedAt,
        postponedAt: postponedAt,
        municipality: review.Equipment.Municipality?.municipalityId ?? 0,
        viewed: review.viewed,
      };
      array.push(aux);
      return null;
    });

    setReviews(reviews.concat(array));
  };

  const getReviews = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }

    await Axios.put(
      "/api/review/reviewStatus/unrealized",
      {},
      {
        headers: { "auth-token": authToken },
      }
    ).then(async (response) => {
      await Axios.get("/api/review/day", {
        headers: { "auth-token": authToken },
      }).then((response) => {
        setDataTable(response.data.reviews);
        setIsLoading(false);
      });
    });
  };

  const getMunicipalities = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get("/api/municipality", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setMunicipalities(response.data.municipalities);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getReviews();
    getMunicipalities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setShowReviewDetailsModal(false);
  };

  const handleEdit = (rowData) => {
    history.push(`/review/edit/${rowData.reviewId} `);
  };

  const handleMoreDetails = (rowData) => {
    setReviewSelected(rowData);
    setShowReviewDetailsModal(true);
  };

  return (
    <div className="statistics-container">
      <div className="statistics">
        <div className="header-user" style={{ marginBottom: "2rem" }}>
          <div className="text-header">
            <h1>Obtené información sobre las revisiones de hoy</h1>
            <h2>
              Podés dar de alta una nueva orden de revisón, editarla o
              postergarla
            </h2>
          </div>

          <div className="button-header">
            <Button className="btn-celeste" href="/review/start">
              Generar orden
            </Button>
            <Button className="btn-gris" href="/review/records">
              Ver historial
            </Button>
          </div>
        </div>
        <MaterialTable
          columns={[
            { title: "Número de revisión", field: "id", filtering: false },
            {
              title: "Fecha prevista de revisión",
              field: "startedAt",
              filtering: false,
            },
            {
              title: "Fecha de  postergación",
              field: "postponedAt",
              filtering: false,
            },
            { title: "N° equipo", field: "nroEquipment", filtering: false },
            {
              title: "Descripción del problema",
              field: "problemDescription",
              filtering: false,
            },
            {
              title: "Municipalidad",
              field: "municipality",
              lookup: municipalities.reduce(function (acc, cur, i) {
                acc[cur.municipalityId] = cur.name;
                return acc;
              }, {}),
            },
            { title: "Tecnicos a cargo", field: "technicians" },
            {
              title: "Estado",
              field: "status",
              lookup: {
                Pendiente: "Pendiente",
                Finalizada: "Finalizada",
                Postergada: "Postergada",
                "No realizada": "No realizada",
              },
              cellStyle: { minWidth: 180 },
              render: (rowData) => {
                return rowData.status === "Pendiente" ? (
                  <span className="iniciada status">{rowData.status}</span>
                ) : rowData.status === "Postergada" ? (
                  <span className="postergada status">{rowData.status}</span>
                ) : rowData.status === "No realizada" ? (
                  <span className="no-realizada status">{rowData.status}</span>
                ) : rowData.status === "Finalizada" ? (
                  <span className="finalizada status">{rowData.status}</span>
                ) : null;
              },
            },
            {
              title: "Visto por técnico",
              field: "viewed",
              filtering: false,
              lookup: { 0: "No", 1: "Si" },
              render: (rowData) => {
                return rowData.viewed ? (
                  <Icon className="icons" style={{ marginLeft: "1.5rem" }}>
                    done_all
                  </Icon>
                ) : (
                  <Icon className="icons" style={{ marginLeft: "1.5rem" }}>
                    close
                  </Icon>
                );
              },
            },
          ]}
          data={reviews}
          isLoading={isLoading}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
            },
            toolbar: {
              nRowsSelected: "{0} fila(s) seleccionadas",
            },
            header: {
              actions: " ",
            },
            body: {
              emptyDataSourceMessage: "No hay registros para mostrar",
              filterRow: {
                filterTooltip: "Filtro",
              },
            },
          }}
          actions={[
            {
              icon: "info",
              tooltip: "Detalle",
              onClick: (event, rowData) => handleMoreDetails(rowData),
            },
            (rowData) => ({
              icon: "edit",
              tooltip: "Editar",
              hidden: rowData.status === "Finalizada",
              onClick: (event, rowData) => handleEdit(rowData),
            }),
          ]}
          title=" "
          options={{
            actionsColumnIndex: -1,
            filtering: true,
          }}
        />
      </div>
      <img
        className="wave"
        src={require("../../../../assets/images/waves.png")}
        alt=""
      />
      {/* <img
                className='background-top'
                src={require("../../../assets/images/supervisor/review.png")}
                alt=''
            /> */}
      <ReviewDetailsModal
        show={showReviewDetailsModal}
        onHide={closeModal}
        review={reviewSelected}
      />
    </div>
  );
}
