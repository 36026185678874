import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../Repairs.scss";
import MaterialTable from "material-table";
import { Button, Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import RepairDetailsModal from "../Modals/RepairDetailsModal";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

export default function RepairsRecord(props) {

    const [isLoading, setIsLoading] = useState(false);
    const { checkLoggedIn } = props;
    const [repairs, setRepairs] = useState([]);
    const history = useHistory();
    const [showRepairDetailsModal, setShowRepairDetailsModal] = useState(false);
    const [repairSelected, setRepairSelected] = useState({});
    const [errorDate, setErrorDate] = useState(null);
    const [filter, setFilter] = useState({
        dateFrom: '',
        dateTo: '',
    });

    const handleChange = (event) => {
        setFilter({
            ...filter,
            [event.target.name]: event.target.value,
        });
    };


    const handleCleanFilter = () => {
        setFilter({
            dateFrom: '',
            dateTo: ''
        })
    }

    const setDataTable = (response) => {

        var aux;
        let array = [];
        setRepairs(repairs.length = 0)
        response.map((repair) => {
            aux = {
                id: repair.repairId, //se manda a la pagina de editar
                repairId: "#" + repair.repairId,
                status: repair.Status.description,
                startedAt: Moment(repair.startedAt).utc().format("DD/MM/YYYY"),
                finishedAt: Moment(repair.finishedAt).utc().format("DD/MM/YYYY"),
                solution: repair.solution,
                reason: repair.reason,
                repairPlace: repair.repairPlace,
                equipment: repair.Equipment.brand + " - " + repair.Equipment.model,
                serialNumber: repair.Equipment.serialNumber,
                Equipment: repair.Equipment
            }
            array.push(aux)
            return null
        })


        setRepairs(repairs.concat(array));

    };


    const getRepairs = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }

        await Axios.get("/api/repair", {
            headers: { "auth-token": authToken },
        }).then((response) => {

            setDataTable(response.data.repairs)
            setIsLoading(false);
        });

    };


    useEffect(() => {
        setIsLoading(true);
        getRepairs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (event) => {
        setErrorDate(null);
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        event.preventDefault();

        if (dateFromBiggerThanDateTo()) {
            event.preventDefault();
            event.stopPropagation();
            setErrorDate("Fecha desde no puede ser mayor que fecha hasta");
            return;
        }

        if ((filter.dateTo && !filter.dateFrom) || (!filter.dateTo && filter.dateFrom)) {
            event.preventDefault();
            event.stopPropagation();
            setErrorDate("Ambas fechas son obligatorias");
            return;
        }
        search();
    }

    const search = async () => {
        setIsLoading(true)
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get("/api/repair", {
            params: {
                dateFrom: filter.dateFrom, dateTo: filter.dateTo
            },
            headers: { "auth-token": authToken },
        }).then((response) => {
            setDataTable(response.data.repairs);
            setIsLoading(false);
        });

    }

    const dateFromBiggerThanDateTo = () => {
        var isBigger = false;
        const dt = new Date(filter.dateTo);
        const df = new Date(filter.dateFrom);
        if (df > dt) {

            isBigger = true;
        }
        return isBigger;
    }



    const handleMoreDetails = (rowData) => {
        setRepairSelected(rowData);
        setShowRepairDetailsModal(true);
    }

    const closeModal = () => {
        setShowRepairDetailsModal(false);
    }

    const handleEdit = (rowData) => {
        history.push(
            `/repair/edit/${rowData.id} `)
    }


    return (
        <div className='statistics-container'>
            <div className='statistics'>
                <div className="header-repair" style={{ marginBottom: '2rem' }}>
                    <div className="text-header">
                        <h1>Historial de reparaciones</h1>
                    </div>

                    <div className="button-header">
                        <Button className='btn-gris' href="/repairs" >Volver</Button>
                    </div>
                </div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >

                        <div className='header'>
                            <Icon className="filter-icon">search</Icon><h2> Filtros</h2>
                        </div>

                    </AccordionSummary>
                    <AccordionDetails>
                        <Form id='form-filter' onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} md={2}>
                                    <Form.Label>Fecha desde: </Form.Label>
                                    <Form.Control
                                        name='dateFrom'
                                        type='date'
                                        value={filter.dateFrom}
                                        onChange={handleChange}
                                    />

                                </Form.Group>
                                <Form.Group as={Col} md={2} >
                                    <Form.Label>Fecha hasta: </Form.Label>

                                    <Form.Control
                                        name='dateTo'
                                        type='date'
                                        value={filter.dateTo}
                                        onChange={handleChange}
                                    />

                                </Form.Group>


                            </Form.Row>
                            {(errorDate) ? <span className='error'>{errorDate}</span>
                                : null}
                            <Button className='btn-celeste' type='submit'>Buscar</Button>
                            <Button color='secondary' className='btn-gris' onClick={handleCleanFilter}>Limpiar filtro</Button>

                        </Form>
                    </AccordionDetails>
                </Accordion>
                <MaterialTable
                    style={{ marginTop: '2rem' }}
                    columns={[
                        { title: "Número de reparación", field: "repairId", filtering: false },
                        { title: "Fecha dada de alta", field: "startedAt", filtering: false },
                        { title: "Equipo", field: "equipment", filtering: false },
                        { title: "Numero de serie", field: "serialNumber", filtering: false },
                        { title: "Motivo de reparación", field: "reason", filtering: false },
                        { title: "Donde se está reparando", field: "repairPlace", filtering: false },
                        {
                            title: "Estado", field: "status",
                            lookup: {
                                'Pendiente': 'Pendiente', 'Finalizada': 'Finalizada',
                            },
                            cellStyle: { minWidth: 150 },
                            render: rowData => {
                                return (
                                    rowData.status === "Pendiente" ?
                                        <span className="iniciada status">{rowData.status}</span> :
                                        rowData.status === "Postergada" ?
                                            <span className="postergada status">{rowData.status}</span> :
                                            rowData.status === "No realizada" ?
                                                <span className="no-realizada status">{rowData.status}</span> :
                                                rowData.status === "Finalizada" ?
                                                    <span className="finalizada status">{rowData.status}</span> :
                                                    null
                                )
                            },
                        },


                    ]}
                    data={repairs}
                    isLoading={isLoading}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} fila(s) seleccionadas'
                        },
                        header: {
                            actions: ' '
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay registros para mostrar',
                            filterRow: {
                                filterTooltip: 'Filtro'
                            }
                        }
                    }}
                    actions={[
                        {
                            icon: 'info',
                            tooltip: 'Detalle',
                            onClick: (event, rowData) => handleMoreDetails(rowData)
                        },
                        rowData => (
                            {
                                icon: 'edit',
                                tooltip: 'Editar',
                                hidden: rowData.status === "Finalizada",
                                onClick: (event, rowData) => handleEdit(rowData)
                            }),
                    ]}

                    title=' '
                    options={{
                        actionsColumnIndex: -1,
                        filtering: true
                    }}
                />
            </div>
            <img
                className='wave'
                src={require("../../../../assets/images/waves.png")}
                alt=''
            />
            {/* <img
                className='background-top'
                src={require("../../../assets/images/supervisor/review.png")}
                alt=''
            /> */}
            <RepairDetailsModal
                show={showRepairDetailsModal}
                onHide={closeModal}
                repair={repairSelected}
            ></RepairDetailsModal>

        </div>

    );

}
