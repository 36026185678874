import React, { useEffect, useState } from "react";
import Loader from "../../../utils/Loader/Loader";
import Axios from "axios";
import { Row, Col, Button, Form } from "react-bootstrap";
import Moment from "moment";
import { SuccessModal } from "../../../utils/Modals/SuccessModal";
import { AreYouSureModal } from "../../../utils/Modals/AreYouSureModal";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Icon } from "@material-ui/core";

export default function ReviewEdit(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn, history } = props;
  const id = props.match.params.reviewId;
  const [reviewSelected, setReviewSelected] = useState(null);
  const [technicians, setTechnicians] = useState([]);
  const [error, setError] = useState("");
  const [solutions, setSolutions] = useState([]);
  const [solutionsChecked, setSolutionsChecked] = useState([]);
  const [showAreYouSureModal, setAreYouSureModal] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [validated, setValidated] = useState(false);
  const [lastPosition, setLastPosition] = useState(0);
  const [repair, setRepair] = useState({
    reviewId: 0,
    startedAt: Moment(Date.now()).format("YYYY-MM-DD"),
    equipmentId: 0,
    statusId: 1,
    reason: "",
    repairPlace: "",
  });

  const onSolutionChange = (event) => {
    setSolutionsChecked({
      ...solutionsChecked,
      [event.target.name - 1]: event.target.checked,
    });
  };

  const handleChange = (event) => {
    var value = event.target.value;
    var name = event.target.name;

    if (
      name === "technicianOne" ||
      name === "technicianTwo" ||
      name === "statusId"
    ) {
      value = parseInt(value, 10);
    }
    setReviewSelected({
      ...reviewSelected,
      [name]: value,
    });
  };

  const getReview = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/review/${id}`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setReviewSelected(response.data.review);
      setRepair({
        ...repair,
        equipmentId: response.data.review.equipmentId,
        reviewId: response.data.review.reviewId,
      });
      setReviewSelected({
        ...response.data.review,
        oldTechnicianOne: response.data.review.Technicians[0].id,
        oldTechnicianTwo: response.data.review.Technicians[1].id,
        technicianOne: response.data.review.Technicians[0].id,
        technicianTwo: response.data.review.Technicians[1].id,
      });
      setIsLoading(false);
    });
  };

  const getTechnicians = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/user/technicians`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setTechnicians(response.data.users);
      setIsLoading(false);
    });
  };

  const getSolutions = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/review/solutions`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setSolutions(response.data.solutions);
      let array = [];
      response.data.solutions.map(() => array.push(false));
      setSolutionsChecked(array);
      setLastPosition(array.length - 1);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getReview();
    getTechnicians();
    getSolutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    history.push("/review");
  };

  const handleRepairChange = (event) => {
    setRepair({
      ...repair,
      [event.target.name]: event.target.value,
    });
  };

  const closeModal = () => {
    setAreYouSureModal(false);
    if (showConfirmationModal) {
      setConfirmationModal(false);
      history.push("/review");
    }
  };

  const handleConfirmation = () => {
    const id = props.match.params.reviewId;
    setAreYouSureModal(false);
    setIsLoading(true);
    const infoToPost = { reviewSelected, solutionsChecked, repair };
    const updateReview = async () => {
      const authToken = await checkLoggedIn();
      if (!authToken) {
        return history.push("/");
      }
      await Axios.put(`/api/review/${id}`, infoToPost, {
        headers: { "auth-token": authToken },
      })
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            setConfirmationModal(true);
          }
        })
        .catch((err) => {
          setError("Ha ocurrido un error.");
          setIsLoading(false);
        });
    };
    updateReview();
  };

  const handleSubmit = (event) => {
    setError("");
    setValidated(false);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    if (reviewSelected.technicianOne === reviewSelected.technicianTwo) {
      event.preventDefault();
      event.stopPropagation();
      setError("Los técnicos seleccionados deben ser distintos.");
      return;
    }

    if (reviewSelected.statusId === 2 && !atLeastOneSolutionChecked()) {
      event.preventDefault();
      event.stopPropagation();
      setError("Por favor, seleccione aunque sea una solución.");
      return;
    }

    if (reviewSelected.statusId === 2) {
      setReviewSelected({
        ...reviewSelected,
        postponedAt: null,
        reasonPostponement: null,
      });
    }
    if (reviewSelected.statusId === 3) {
      setReviewSelected({
        ...reviewSelected,
        finishedAt: null,
        descriptionSolution: null,
      });

      setSolutionsChecked(null);
    }

    setValidated(true);

    setAreYouSureModal(true);
  };

  const atLeastOneSolutionChecked = () => {
    var checked = false;
    for (var value of Object.values(solutionsChecked)) {
      if (value) {
        checked = true;
        return checked;
      }
    }
    return checked;
  };

  return (
    <div>
      <Loader show={isLoading} />
      <div className="review">
        <div className="review-container">
          {reviewSelected ? (
            <Row style={{ height: "80vh" }}>
              <Col md={5} style={{ display: "flex", maxHeight: "80vh" }}>
                <div className="description-equipment">
                  <Icon className="icon-de">developer_board</Icon>
                  <h2>
                    {reviewSelected.Equipment.brand}{" "}
                    {reviewSelected.Equipment.model}{" "}
                  </h2>
                  <h3>
                    {reviewSelected.Equipment.street}{" "}
                    {reviewSelected.Equipment.streetNumber},{" "}
                    {reviewSelected.Equipment.Municipality?.name ?? " - "}
                  </h3>
                  <p>
                    <Icon className="icons">api</Icon>Estado:{" "}
                    {reviewSelected.Equipment.isWorking ? (
                      <span style={{ color: "#CCFFBA", fontWeight: "bold" }}>
                        Funcionando
                      </span>
                    ) : (
                      <span style={{ color: "#FF7272", fontWeight: "bold" }}>
                        No funciona
                      </span>
                    )}
                  </p>
                  <p>
                    <Icon className="icons">broken_image</Icon>Nro de serie:
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {reviewSelected.Equipment.serialNumber}
                    </span>
                  </p>
                  <p>
                    <Icon className="icons">settings_input_antenna</Icon>Tipo de
                    equipo:
                    <span style={{ fontWeight: "500" }}>
                      {reviewSelected.Equipment.EquipmentType?.description}
                    </span>
                  </p>
                </div>
              </Col>
              <Col md={7}>
                <div className="header">
                  <div className="text-header">
                    <h2>Editá la revisión #{id}</h2>
                  </div>
                </div>
                <div>
                  <Form
                    noValidate
                    id="form"
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Row>
                      <Form.Group as={Col} md={6}>
                        <Form.Label>Técnico a cargo</Form.Label>
                        <Form.Control
                          name="technicianOne"
                          onChange={handleChange}
                          as="select"
                          value={reviewSelected.technicianOne}
                        >
                          <option hidden value="">
                            Selecciona...
                          </option>

                          {technicians.map((technician) => (
                            <option value={technician.id} key={technician.id}>
                              {technician.name} {technician.lastname}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} md={6}>
                        <Form.Label>Técnico a cargo</Form.Label>
                        <Form.Control
                          name="technicianTwo"
                          as="select"
                          onChange={handleChange}
                          value={reviewSelected.technicianTwo}
                        >
                          <option hidden value="">
                            Selecciona...
                          </option>

                          {technicians.map((technician) => (
                            <option value={technician.id} key={technician.id}>
                              {technician.name} {technician.lastname}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group as={Col} md={6}>
                        <Form.Label>Fecha de revisión</Form.Label>
                        <Form.Control
                          name="startedAt"
                          type="date"
                          readOnly={
                            !(
                              reviewSelected.statusId === 1 &&
                              Moment(reviewSelected.startedAt)
                                .utc()
                                .format("YYYY-MM-DD") >
                                Moment(Date.now()).utc().format("YYYY-MM-DD")
                            )
                          }
                          min={Moment(Date.now()).utc().format("YYYY-MM-DD")}
                          value={Moment(reviewSelected.startedAt)
                            .utc()
                            .format("YYYY-MM-DD")}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={6}>
                        <Form.Label>Estado de la revisión</Form.Label>
                        <Form.Control
                          name="statusId"
                          as="select"
                          value={reviewSelected.statusId}
                          onChange={handleChange}
                        >
                          <option value="1" hidden>
                            Pendiente
                          </option>
                          <option value="2">Finalizada</option>
                          <option value="3">Postergada</option>
                          <option value="4" hidden>
                            No realizada
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      {reviewSelected.statusId === 2 ? (
                        <Form.Group as={Col} md={6}>
                          <Form.Label>Fecha de finalización</Form.Label>
                          <Form.Control
                            name="finishedAt"
                            type="date"
                            min={Moment(reviewSelected.startedAt)
                              .utc()
                              .format("YYYY-MM-DD")}
                            // max={Moment(Date(Date.now()).getDate() + 1).utc().format("YYYY-MM-DD")}
                            required={reviewSelected.statusId === 2}
                            value={reviewSelected.finishedAt}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      ) : null}
                      {reviewSelected.statusId === 3 ? (
                        <Form.Group as={Col} md={6}>
                          <Form.Label>Fecha de postergación</Form.Label>
                          <Form.Control
                            name="postponedAt"
                            type="date"
                            min={Moment(reviewSelected.startedAt)
                              .utc()
                              .format("YYYY-MM-DD")}
                            required={reviewSelected.statusId === 3}
                            value={reviewSelected.postponedAt}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      ) : null}
                    </Form.Row>
                    {reviewSelected.statusId === 3 ? (
                      <Row>
                        <Form.Group as={Col} md="12">
                          <Form.Label>Motivo de postergación</Form.Label>
                          <Form.Control
                            name="reasonPostponement"
                            as="textarea"
                            required={reviewSelected.statusId === 3}
                            rows="3"
                            type="text"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Row>
                    ) : null}
                    {reviewSelected.statusId === 2 ? (
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Label>Solución</Form.Label>
                        <Row className="checkbox">
                          {solutions.map((solution, index) => (
                            <Col md={4} sm={6} key={index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={onSolutionChange}
                                    disabled={
                                      solutionsChecked[0] &&
                                      !solutionsChecked[index]
                                    }
                                    name={solution.reviewSolutionsId.toString()}
                                    color="primary"
                                  />
                                }
                                label={solution.description}
                              />
                            </Col>
                          ))}
                        </Row>
                        {solutionsChecked[lastPosition] === true ? (
                          <Row>
                            <Form.Group as={Col} md="12">
                              <Form.Label>¿Cuál fue la solución? *</Form.Label>
                              <Form.Control
                                name="descriptionSolution"
                                as="textarea"
                                required={solutionsChecked[lastPosition]}
                                rows="3"
                                type="text"
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Row>
                        ) : null}

                        {solutionsChecked[0] === true ? (
                          <Row>
                            <Form.Group as={Col} md={6} sm={12}>
                              <Form.Label>
                                ¿Por qué va a retirar el equipo? *
                              </Form.Label>
                              <Form.Control
                                name="reason"
                                as="textarea"
                                required={solutionsChecked[0]}
                                rows="3"
                                type="text"
                                onChange={handleRepairChange}
                              />
                            </Form.Group>
                            <Form.Group as={Col} md={6} sm={12}>
                              <Form.Label>
                                ¿A dónde se va a llevar el equipo? *
                              </Form.Label>
                              <Form.Control
                                name="repairPlace"
                                as="textarea"
                                required={solutionsChecked[0]}
                                rows="3"
                                type="text"
                                onChange={handleRepairChange}
                              />
                            </Form.Group>
                          </Row>
                        ) : null}
                      </Form.Group>
                    ) : null}
                    {error ? (
                      <Form.Row>
                        <span className="error">{error}</span>
                      </Form.Row>
                    ) : null}
                    <Form.Row style={{ marginTop: "2rem" }}>
                      <div className="btn-container">
                        <Button className="btn-celeste" type="submit">
                          Finalizar
                        </Button>
                        <Button
                          variant="secondary"
                          className="btn-gris"
                          onClick={handleCancel}
                        >
                          Cancelar
                        </Button>
                      </div>
                    </Form.Row>
                  </Form>
                </div>
              </Col>
            </Row>
          ) : null}
          <AreYouSureModal
            show={showAreYouSureModal}
            handleConfirmation={handleConfirmation}
            onHide={closeModal}
            text={"¿Está seguro que desea editar esta revisión?"}
          />
          <SuccessModal
            show={showConfirmationModal}
            onHide={closeModal}
            text={"La revisión se ha editado con éxito"}
          ></SuccessModal>
        </div>
        <img
          className="wave-left"
          src={require("../../../../assets/images/supervisor/wave-left.png")}
          alt=""
        />
      </div>
    </div>
  );
}
