import React, { useEffect, useState } from "react";
import Axios from "axios";
import Loader from "../../utils/Loader/Loader";
import {
  FormControl,
  Icon,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./ConfigMunicipalities.scss";
import AddUserMunicipalityModal from "./Modals/AddUserMunicipalityModal";
import { AreYouSureModal } from "../../utils/Modals/AreYouSureModal";

export default function ConfigMunicipalities(props) {
  const [municipalities, setMunicipalities] = useState([]);
  const [analyzers, setAnalyzers] = useState([]);
  const { checkLoggedIn, history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [selectedMunicipality, setSelectedMunicipality] = useState({});
  const [busqueda, setBusqueda] = useState("");
  const getMunicipalities = async () => {
    const authToken = await getAuthToken();
    await Axios.get("/api/municipality/users", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      tratarMunicipalidades(response.data.municipalidades);
    });
  };

  const tratarMunicipalidades = (dataOriginal) => {
    let dataFiltrada = [];
    dataOriginal.map((x) => {
      x = {
        municipalityId: x.municipalityId,
        name: x.name,
        Users: x.Users,
        UsersFiltrados: x.Users.sort(ordenarAlfabeticamente()),
      };
      dataFiltrada.push(x);
    });

    setMunicipalities(dataFiltrada);
  };

  const getUsers = async () => {
    const authToken = await getAuthToken();
    await Axios.get("/api/user/analyzers", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setAnalyzers(response.data.users.sort(ordenarAlfabeticamente()));
      setIsLoading(false);
    });
  };

  const closeModal = () => {
    setShowAddUserModal(false);
    getMunicipalities();
  };

  const filtrar = (value, users) => {
    users = users.filter(
      (x) =>
        x.name.toLowerCase().includes(value.toLowerCase()) ||
        x.lastname.toLowerCase().includes(value.toLowerCase())
    );
  };

  const openModal = async (municipality) => {
    setSelectedMunicipality(municipality);
    setShowAddUserModal(true);
  };

  const getAuthToken = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      history.push("/");
      return;
    }
    return authToken;
  };

  useEffect(() => {
    getUsers();
    getMunicipalities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="equipment-container">
      <Loader show={isLoading} />
      <div className="equipment-status">
        <div className="es-header">
          <div className="d-flex justify-content-between">
            <div>
              <h1>Configuración de municipios</h1>
              <h2>Configurá a qué municipios va a tener acceso cada editor.</h2>
            </div>
            <div className="search-input">
              <FormControl style={{ width: "20rem" }}>
                <InputLabel htmlFor="search">Buscar</InputLabel>
                <Input
                  id="search"
                  onChange={(e) => setBusqueda(e.target.value.toString())}
                  value={busqueda}
                  endAdornment={
                    <InputAdornment position="end">
                      <Icon>search</Icon>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </div>
        </div>
        <Accordion>
          {municipalities.map((municipality, index) => (
            <Card key={index}>
              <Accordion.Toggle
                as={Card.Header}
                eventKey={municipality.municipalityId}
              >
                <div className="accordion-header">
                  <span className="tittle-accordion">{municipality.name}</span>
                  <span className="icon-header">
                    <Icon>expand_more</Icon>
                  </span>
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={municipality.municipalityId}>
                <Card.Body>
                  <div>
                    <div className="body-header">
                      <button
                        className="btn-icon"
                        onClick={() => openModal(municipality)}
                      >
                        <Icon>mode</Icon>
                      </button>
                    </div>
                    <div className="analizadores">
                      <Row style={{ width: "100%" }}>
                        {municipality.UsersFiltrados.filter(
                          (x) =>
                            x.name
                              .toLowerCase()
                              .includes(busqueda.toLowerCase()) ||
                            x.lastname
                              .toLowerCase()
                              .includes(busqueda.toLowerCase())
                        ).length > 0 ? (
                          municipality.UsersFiltrados.filter(
                            (x) =>
                              x.name
                                .toLowerCase()
                                .includes(busqueda.toLowerCase()) ||
                              x.lastname
                                .toLowerCase()
                                .includes(busqueda.toLowerCase())
                          ).map((user) => (
                            <Col key={user.id} md={3}>
                              <div className="analizador">
                                {user.name} {user.lastname}
                              </div>
                            </Col>
                          ))
                        ) : (
                          <div>No hay editores asociados a este muncipio.</div>
                        )}
                      </Row>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </div>

      <img
        className="wave"
        src={require("../../../assets/images/waves.png")}
        alt=""
      />
      <AddUserMunicipalityModal
        show={showAddUserModal}
        onHide={closeModal}
        checkLoggedIn={checkLoggedIn}
        municipality={selectedMunicipality}
        users={analyzers}
      ></AddUserMunicipalityModal>
    </div>
  );

  function ordenarAlfabeticamente() {
    return function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    };
  }
}
