import React from "react";

import Cropper from "./Cropper";

import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

export default class ImageCarousel extends React.Component {
  constructor(props) {
    super(props);
    let thumbs = this.makeThumbnails(0);
    this.state = {
      value: 0,
      slides: this.props.slides,
      thumbnails: thumbs,
      framesToPost: this.props.framesToPost,
    };
    this.onchange = this.onchange.bind(this);
    this.resetQueue = this.resetQueue.bind(this);
    this.closeModal = this.closeModal.bind(this);

    //on scroll advance carousel
    window.addEventListener("wheel", (e) => {
      if (e.deltaY < 0) {
        let newImageSelected = Math.min(
          this.state.value + 1,
          this.state.slides.length - 1
        );
        this.onchange(newImageSelected);
      } else {
        let newImageSelected = Math.max(this.state.value - 1, 0);
        this.onchange(newImageSelected);
      }
    });
  }

  makeThumbnails(index) {
    let thumbs = [];
    let adjustedIndex = index;

    if (index - 2 < 0) {
      adjustedIndex = 2;
    } else if (index + 2 >= this.props.slides.length - 1) {
      adjustedIndex = this.props.slides.length - 3;
    }
    //if the number of slides < 3, the for loop will not work, so we add in a modified one
    if (this.props.slides.length > 3) {
      for (let i = adjustedIndex - 2; i < adjustedIndex + 3; i++) {
        thumbs[i] = <img alt="" src={this.props.slides[i].props.src} />;
      }
    } else {
      for (let i = 0; i < this.props.slides.length; i++) {
        thumbs[i] = <img alt="" src={this.props.slides[i].props.src} />;
      }
    }
    return thumbs;
  }

  onchange(value) {
    this.setState(
      {
        value,
      },
      () => {
        let thumbs = this.makeThumbnails(this.state.value);
        this.setState({
          thumbnails: thumbs,
        });
      }
    );
  }

  resetQueue() {
    this.setState({
      framesToPost: [],
    });
    this.props.resetQueue();
  }
  closeModal() {
    this.props.closeModal();
  }
  removeIndexFromQueue(i) {
    this.props.removeIndexFromQueue(i);
  }

  render() {
    return (
      <div>
        <Carousel
          arrows
          centered
          value={this.state.value}
          slides={this.state.slides}
          onChange={this.onchange}
          slidesPerPage={1}
          animationSpeed={0}
        />
        <Dots
          thumbnails={this.state.thumbnails}
          number={this.state.thumbnails.length}
          value={this.state.value}
          onChange={this.onchange}
        />
        <Cropper
          removeIndexFromQueue={(i) => {
            this.removeIndexFromQueue(i);
          }}
          imageSrc={this.props.curImageSrc}
          closeModal={this.closeModal}
          modalActive={this.props.modalActive}
          cropImage={this.props.cropImage}
          onClick={this.resetQueue}
          framesToPost={this.props.framesToPost}
          clickedImages={this.state.clickedImages}
        />
      </div>
    );
  }
}
