import React, { useEffect, useState } from 'react';
import Axios from "axios";
import "./Payments.scss";
import { Button } from "@material-ui/core";
import Loader from "../../utils/Loader/Loader";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from 'react-bootstrap/InputGroup'
import { AreYouSureModal } from '../../utils/Modals/AreYouSureModal';
import { SuccessModal } from '../../utils/Modals/SuccessModal';

export default function Payments(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [validated, setValidated] = useState(false);
    const { checkLoggedIn, history } = props;
    const [showAreYouSureModal, setAreYouSureModal] = useState(false);
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [paymentData, setPaymentData] = useState({
        amountPerApproval: 0,
        amountPerDisapproval: 0,
        amountPerDiscard: 0
    });

    useEffect(() => {
        const getCurrentAmounts = async () => {
            const authToken = await checkLoggedIn();
            if (!authToken) {
                return history.push("/");
            }
            await Axios.get("/api/payment", {
                headers: { "auth-token": authToken },
            }).then((response) => {
                if (response.data.amounts[0])
                setPaymentData({
                    amountPerApproval: response.data.amounts[0].amountPerApproval,
                    amountPerDisapproval: response.data.amounts[0].amountPerDisapproval,
                    amountPerDiscard: response.data.amounts[0].amountPerDiscard,
                });
                else 
                    setPaymentData({
                        amountPerApproval: 0,
                        amountPerDisapproval:0,
                        amountPerDiscard:0,
                    });
                setIsLoading(false);

            });
        };
        setIsLoading(true);
        getCurrentAmounts();
    }, [history, checkLoggedIn]);

    const zeroValues = () => {
        if (paymentData.amountPerApproval < 0 || paymentData.amountPerDisapproval < 0 || paymentData.amountPerDiscard < 0)
            return true;
        return false;
    }

    const closeModal = () => {
        setAreYouSureModal(false);
        setConfirmationModal(false);
    }

    const handleSubmit = (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (zeroValues()) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return;

        }
        event.preventDefault();
        setValidated(true);
        setAreYouSureModal(true); 
    }


    const handleConfirmation = () => {
        setAreYouSureModal(false); 
        setIsLoading(true);
        const updateAmounts = async () => {
            const authToken = await checkLoggedIn();
            if (!authToken) {
                return history.push("/");
            }
            await Axios.post("/api/payment",
                { amounts: paymentData },
                {
                    headers: { "auth-token": authToken },

                }).then((response) => {
                    setIsLoading(false);
                    setIsUpdating(false);
                    setConfirmationModal(true);
                });
        };

        updateAmounts();
    }

    const updatePayment = () => {
        setValidated(false);
        setIsUpdating(true);
    }

    const handleChange = (event) => {
        setPaymentData({
            ...paymentData,
            [event.target.name]: event.target.value,
        });
    };

    const handleCancel = () => {
        setIsUpdating(false);
    };


    return (
        <div>
            <Loader show={isLoading} />
            <div className="payments-container">
                <div className="payments-wrapper">
                    <div className="payment">
                        <h1>Actualizá los montos de pago</h1>


                        <div className="form-payment">
                            <div className="actual-payment">
                                <div className='header'>
                                    <h1>{(isUpdating) ? "Ingresa los nuevos montos" : "Montos actuales"}</h1>
                                </div>
                            </div>
                            <Form noValidate validated={validated} id='form' onSubmit={handleSubmit}>

                                <Form.Row>
                                    <Form.Group as={Col} md="3" controlId='formGridName'>
                                        <Form.Label>Infracciones imputables: </Form.Label>
                                        <InputGroup className="mb-2">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                name='amountPerApproval'
                                                type='number'
                                                value={paymentData.amountPerApproval}
                                                readOnly={!isUpdating}
                                                min="0"
                                                required
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback tooltip={true} type='invalid'>
                                              No se pueden ingresar números negativos
                                             </Form.Control.Feedback>
                                        </InputGroup>

                                    </Form.Group>

                                    <Form.Group as={Col} md="3" controlId='formGridLastname'>
                                        <Form.Label>Infracciones no imputables:</Form.Label>
                                        <InputGroup className="mb-2">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                name='amountPerDiscard'
                                                type='number'
                                                value={paymentData.amountPerDiscard}
                                                readOnly={!isUpdating}
                                                required
                                                min="0"
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback tooltip={true} type='invalid'>
                                            No se pueden ingresar números negativos
                                             </Form.Control.Feedback>
                                        </InputGroup>


                                    </Form.Group>
                                    <Form.Group as={Col} md="3" controlId='formGridLastname'>
                                        <Form.Label>Presunciones desaprobadas: </Form.Label>
                                        <InputGroup className="mb-2">
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                name='amountPerDisapproval'
                                                type='number'
                                                value={paymentData.amountPerDisapproval}
                                                readOnly={!isUpdating}
                                                required
                                                min="0"
                                                onChange={handleChange}
                                            />
                                            <Form.Control.Feedback tooltip={true} type='invalid'>
                                            No se pueden ingresar números negativos
                                             </Form.Control.Feedback>
                                        </InputGroup>

                                    </Form.Group>
                                </Form.Row>
                                {(isUpdating) ?
                                    <Form.Row>
                                        <Button className="btn-gris"
                                            onClick={handleCancel}
                                        >
                                            Cancelar
                                </Button>
                                        <Button className="btn-celeste" type="submit" >
                                            Aceptar
                                        </Button>
                                    </Form.Row>
                                    : null}

                            </Form>
                            {(!isUpdating) ?
                                <Button className="btn-celeste" type="submit"
                                    onClick={updatePayment}>
                                    Modificar montos
                                </Button> : null}
                        </div>


                    </div>
                    <img className="illustration" src={require("../../../assets/images/supervisor/payments.png")} alt="wallet" />
                </div>



            </div>

            <AreYouSureModal
                show={showAreYouSureModal}
                handleConfirmation={handleConfirmation}
                onHide={closeModal}
                text={"¿Está seguro que desea actualizar los montos de pago?"}
            />
            <SuccessModal
                show={showConfirmationModal}
                onHide={closeModal}
                text={"Se han actualizado con éxito los montos de pago"}
            ></SuccessModal>
        </div>);



}