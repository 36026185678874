import React, { useEffect, useState } from "react";
import "../Analyzer/style.scss";
import Button from "react-bootstrap/Button";

export default function HomeVerificador(props) {
  const { checkLoggedIn, history } = props;

  return (
    <div className="analyzerhome-container">
      <div className="home-wrapper">
        <img
          src={require("../../../assets/images/analyzer/home.png")}
          alt="car"
        />
        <div className="bienvenido">
          <h1> ¡Bienvenido!</h1>
          <h4>Empiece a analizar haciendo click en el botón de abajo</h4>
          <Button
            className="btn-celeste"
            onClick={() => history.push(`/verificacion`)}
          >
            ANALIZAR
          </Button>
          <Button
            className="btn-celeste"
            onClick={() => history.push(`/verificacionIA`)}
          >
            ANALIZAR IA
          </Button>
        </div>
      </div>
    </div>
  );
}
