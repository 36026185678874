import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Icon } from "@material-ui/core";
import Moment from 'moment';

export default function EquipmentModal(props) {
    return (
        <div>
            <Modal
                size="lg"
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <div className="review-details">
                        <div className="header-rd">
                            <h3>Equipo</h3>
                        </div>
                        <div className="body-rd">
                            {props.equipment ?
                                <div>
                                    <Row>
                                        <Col md={6} sm={12}>

                                            <p><Icon className="icons">api</Icon>Estado:
                                            {props.equipment.isWorking ?
                                                    <span style={{ color: 'green' }}><b> Funcionando </b></span>
                                                    :
                                                    <span style={{ color: 'red' }}><b> No funciona </b></span>
                                                }</p>


                                        </Col>
                                        <Col md={6} sm={12}> 
                                            <p><Icon className="icons">location_on</Icon>Ubicación:
                                            <b> {props.equipment.address}, {props.equipment.municipality}</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={12}>
                                            <p><Icon className="icons">settings_system_daydream</Icon>Tipo de sistema:
                                            <b> {props.equipment.typeOfSystem === "new" ? "Nuevo" : "Híbrido"}</b></p>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <p><Icon className="icons">nfc</Icon>Tipo de equipo:
                                            <b> {props.equipment.equipmentType}</b></p>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6} sm={12}>
                                            <p><Icon className="icons">wb_auto</Icon>IP:
                                            <b> {props.equipment.ip}</b></p>
                                        </Col>
                                        <Col md={6} sm={12}>
                                            <p><Icon className="icons">broken_image</Icon>Número de serie:
                                            <b> {props.equipment.serialNumber}</b></p>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col md={12} sm={12}>
                                            <p><Icon className="icons">location_searching</Icon>Coordenadas:
                                            <b> {props.equipment.coordinates}</b></p>
                                        </Col>
                                    </Row>


                                </div>
                                : null}

                        </div>
                    </div>
                    {props.equipment.reviews && props.equipment.reviews.length !== 0 ?
                        <div className="review-details">
                            <div className="header-rd">
                                <h3>Revisiones pendientes</h3>
                            </div>

                            <div className="body-rd">
                                {props.equipment.reviews.map((review, index) => (
                                    <div className="border-bottom" key={index}>
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <p><Icon className="icons">assignment</Icon>Revisión:
                                                <b> #{review.reviewId}</b></p>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <p><Icon className="icons">event</Icon>Fecha:
                                                {review.statusId === 1 ?
                                                        <b> {Moment(review.startedAt).utc().format("DD/MM/YYYY")}</b>
                                                        :
                                                        <b> {Moment(review.postponedAt).utc().format("DD/MM/YYYY")}</b>
                                                    }</p>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12} sm={12}>
                                                <p><Icon className="icons">supervised_user_circle</Icon>Técnicos a cargo:
                                                <b> {review.Technicians[0].name} {review.Technicians[0].lastname},
                                                     <span> {review.Technicians[1].name} {review.Technicians[1].lastname}</span>
                                                    </b></p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} sm={12}>
                                                <p><Icon className="icons">bug_report</Icon>Problema:
                                                <b>
                                                        {review.ReviewProblems.map(problem => (
                                                            <span> {problem.description}, </span>
                                                        ))}
                                                    </b></p>
                                            </Col>
                                        </Row>
                                    </div>

                                ))}
                            </div>
                        </div>
                        : null}

                    {!props.equipment.isWorking && props.equipment.repairs ?
                        <div className="review-details">
                            <div className="header-rd">
                                <h3>Reparaciones pendientes</h3>
                            </div>

                            <div className="body-rd">
                                {props.equipment.repairs.map((repair, index) => (
                                    <div className="border-bottom" key={index}>
                                        <Row>
                                            <Col md={6} sm={12}>
                                                <p><Icon className="icons">assignment</Icon>Reparación:
                                                <b> #{repair.repairId}</b></p>
                                            </Col>
                                            <Col md={6} sm={12}>
                                                <p><Icon className="icons">event</Icon>Fecha:
                                                    <b> {Moment(repair.startedAt).utc().format("DD/MM/YYYY")}</b>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col  sm={12}>
                                                <p><Icon className="icons">help</Icon>Motivo:
                                                    <b> {repair.reason}</b>
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col  sm={12}>
                                                <p><Icon className="icons">location_on</Icon>¿Donde está el equipo?:
                                                    <b> {repair.repairPlace}</b>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>

                                ))}
                            </div>
                        </div>
                        : null}
                </Modal.Body>

            </Modal>
        </div>
    );

}
