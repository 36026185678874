import React, { useState } from "react";
import "../TechnicianReview.scss";
import Button from "react-bootstrap/Button";
import { Row } from "react-bootstrap";
import { Icon, Switch, FormControlLabel } from "@material-ui/core";
import Dropdown from "react-bootstrap/Dropdown";
import { RepairEquipmentModal } from "../../Modals/RepairEquipmentModal";


export default function SpeedThirdStep(props) {
    const { checkLoggedIn, history } = props;
    const [repairModal, setRepairModal] = useState(false)
    const id = props.match.params.id;
    const [checkList, setCheckList] = useState({
        checkedA: false,
        checkedB: false,
    });

    const handleChange = (event) => {
        setCheckList({ ...checkList, [event.target.name]: event.target.checked });
    };


    const handleNext = () => {

        history.push(`/review/speed/fourth/${id} `)

    }


    const handleCancel = () => {
        history.push(`/review/speed/second/${id} `)
    }

    const handleRemoveEquipment = () => {
        setRepairModal(true);
    }

    const closeModal = () => {
        setRepairModal(false)
    }


    const successRedirect = () => {
        setRepairModal(false);
        history.push(`/reviews/today`)
    }

    const errorRedirect = () => {
        setRepairModal(false);
        history.push(`/error`)

    }

    return (
        <div className="review">
        <div className='review-technician '>
            <div className="header-rtp">
                <h1>Revisión #{id}</h1>
            </div>
            <div className="step-description">
                <p>3. Chequeo de cableado</p>
            </div>
            <div className="checklist">
                <div className="checklist-item">
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={handleChange}
                                name="checkedA"
                                color="primary"
                            />
                        }
                        label="Cables coaxiles"
                    />

                    <Dropdown className='display-drop' alignRight>
                        <Dropdown.Toggle id='dropdown-basic' className='drop-title'>
                            <Icon className='info-icon'>info</Icon>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item className='drop-item'>
                                <ul>
                                    <li>
                                        Realizar mediciones sobre los cables coaxiles
                                    </li>
                                </ul>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div className="checklist-item">
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={handleChange}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Cables de espiras"
                    />

                    <Dropdown className='display-drop' alignRight>
                        <Dropdown.Toggle id='dropdown-basic' className='drop-title'>
                            <Icon className='info-icon'>info</Icon>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item className='drop-item'>

                                <ul>
                                    <li>
                                        Realizar mediciones sobre los cables de espiras
                                    </li>
                                </ul>

                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>


            </div>
            <div className="retirar-equipo">
                <p>¿No puede solucionar el problema?</p>
                <Button className="btn-rojo"
                    variant="danger"
                    onClick={handleRemoveEquipment}>Retirar equipo</Button>
            </div>
            <Row>
                <div className="btn-container">
                    <Button className="btn-celeste"
                        onClick={handleNext}
                        disabled={!checkList.checkedA || !checkList.checkedB}>
                        Siguiente</Button>
                    <Button variant='secondary' className="btn-gris"
                        onClick={handleCancel}>Atras</Button>
                </div>
            </Row>
            <RepairEquipmentModal
                reviewId={id}
                onHide={closeModal}
                successRedirect={successRedirect}
                errorRedirect={errorRedirect}
                show={repairModal}
                checkLoggedIn={checkLoggedIn}
                history={props.history}
            ></RepairEquipmentModal>
        </div>
        </div>
    );
}

