import React, { useEffect, useState } from "react";
import Loader from "../../../utils/Loader/Loader";
import Axios from "axios";
import { Row, Col, Button, Form } from "react-bootstrap";
import Moment from "moment";
import { SuccessModal } from "../../../utils/Modals/SuccessModal";
import { AreYouSureModal } from "../../../utils/Modals/AreYouSureModal";
import { Icon } from "@material-ui/core";

export default function RepairEdit(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn, history } = props;
  const id = props.match.params.repairId;
  const [repairSelected, setRepairSelected] = useState(null);
  const [error, setError] = useState("");
  const [showAreYouSureModal, setAreYouSureModal] = useState(false);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleChange = (event) => {
    var value = event.target.value;
    var name = event.target.name;

    if (name === "statusId") {
      value = parseInt(value, 10);
    }
    setRepairSelected({
      ...repairSelected,
      [name]: value,
    });
  };

  const getRepair = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/repair/${id}`, {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setRepairSelected(response.data.repair);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getRepair();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    history.push("/repairs");
  };

  const closeModal = () => {
    setAreYouSureModal(false);
    if (showConfirmationModal) {
      setConfirmationModal(false);
      history.push("/repairs");
    }
  };

  const handleConfirmation = () => {
    setAreYouSureModal(false);
    setIsLoading(true);
    const infoToPost = { repairSelected };
    const updateRepair = async () => {
      const authToken = await checkLoggedIn();
      if (!authToken) {
        return history.push("/");
      }
      await Axios.put(`/api/repair/${id}`, infoToPost, {
        headers: { "auth-token": authToken },
      })
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            setConfirmationModal(true);
          }
        })
        .catch((err) => {
          setError("Ha ocurrido un error.");
          setIsLoading(false);
        });
    };
    updateRepair();
  };

  const handleSubmit = (event) => {
    setError("");
    setValidated(false);
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);

    setAreYouSureModal(true);
  };

  return (
    <div>
      <Loader show={isLoading} />
      <div className="review">
        <div className="review-container">
          {repairSelected ? (
            <Row style={{ height: "80vh" }}>
              <Col md={5} style={{ display: "flex", maxHeight: "80vh" }}>
                <div className="description-equipment">
                  <Icon className="icon-de">developer_board</Icon>
                  <h2>
                    {repairSelected.Equipment.brand}{" "}
                    {repairSelected.Equipment.model}{" "}
                  </h2>
                  <h3>
                    {repairSelected.Equipment.street}{" "}
                    {repairSelected.Equipment.streetNumber},{" "}
                    {repairSelected.Equipment.Municipality?.name ?? " - "}
                  </h3>
                  <p>
                    <Icon className="icons">api</Icon>Estado:{" "}
                    {repairSelected.Equipment.isWorking ? (
                      <span style={{ color: "#CCFFBA", fontWeight: "bold" }}>
                        Funcionando
                      </span>
                    ) : (
                      <span style={{ color: "#FF7272", fontWeight: "bold" }}>
                        No funciona
                      </span>
                    )}
                  </p>
                  <p>
                    <Icon className="icons">broken_image</Icon>Nro de serie:
                    <span style={{ fontWeight: "500" }}>
                      {" "}
                      {repairSelected.Equipment.serialNumber}
                    </span>
                  </p>
                  <p>
                    <Icon className="icons">settings_input_antenna</Icon>Tipo de
                    equipo:
                    <span style={{ fontWeight: "500" }}>
                      {repairSelected.Equipment.EquipmentType?.description}
                    </span>
                  </p>
                </div>
              </Col>
              <Col md={7}>
                <div className="header">
                  <div className="text-header">
                    <h2 style={{ marginTop: "12%" }}>
                      Editá la reparación #{id}
                    </h2>
                  </div>
                </div>
                <div>
                  <Form
                    noValidate
                    id="form"
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Row>
                      <Form.Group as={Col} md={6}>
                        <Form.Label>Fecha de inicio de reparación</Form.Label>
                        <Form.Control
                          name="startedAt"
                          type="date"
                          readOnly={
                            !(
                              repairSelected.statusId === 1 &&
                              Moment(repairSelected.startedAt)
                                .utc()
                                .format("YYYY-MM-DD") >
                                Moment(Date.now()).utc().format("YYYY-MM-DD")
                            )
                          }
                          min={Moment(Date.now()).utc().format("YYYY-MM-DD")}
                          value={Moment(repairSelected.startedAt)
                            .utc()
                            .format("YYYY-MM-DD")}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md={6}>
                        <Form.Label>Estado de la revisión</Form.Label>
                        <Form.Control
                          name="statusId"
                          as="select"
                          value={repairSelected.statusId}
                          onChange={handleChange}
                        >
                          <option value="1" hidden>
                            Pendiente
                          </option>
                          <option value="2">Finalizada</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      {repairSelected.statusId === 2 ? (
                        <Form.Group as={Col} md={6}>
                          <Form.Label>Fecha de finalización</Form.Label>
                          <Form.Control
                            name="finishedAt"
                            type="date"
                            min={Moment(repairSelected.startedAt)
                              .utc()
                              .format("YYYY-MM-DD")}
                            required={repairSelected.statusId === 2}
                            value={repairSelected.finishedAt}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      ) : null}
                    </Form.Row>

                    {repairSelected.statusId === 2 ? (
                      <Row>
                        <Form.Group as={Col} md="12">
                          <Form.Label>¿Qué se le hizo al equipo?</Form.Label>
                          <Form.Control
                            name="solution"
                            as="textarea"
                            required={repairSelected.statusId === 2}
                            rows="3"
                            type="text"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Row>
                    ) : null}
                    {error ? (
                      <Form.Row>
                        <span className="error">{error}</span>
                      </Form.Row>
                    ) : null}
                    <Form.Row style={{ marginTop: "2rem" }}>
                      <div className="btn-container">
                        <Button
                          className="btn-celeste"
                          disabled={repairSelected.statusId === 1}
                          type="submit"
                        >
                          Finalizar
                        </Button>
                        <Button
                          variant="secondary"
                          className="btn-gris"
                          onClick={handleCancel}
                        >
                          Cancelar
                        </Button>
                      </div>
                    </Form.Row>
                  </Form>
                </div>
              </Col>
            </Row>
          ) : null}
          <AreYouSureModal
            show={showAreYouSureModal}
            handleConfirmation={handleConfirmation}
            onHide={closeModal}
            text={"¿Está seguro que desea editar esta revisión?"}
          />
          <SuccessModal
            show={showConfirmationModal}
            onHide={closeModal}
            text={"La revisión se ha editado con éxito"}
          ></SuccessModal>
        </div>
        <img
          className="wave-left"
          src={require("../../../../assets/images/supervisor/wave-left.png")}
          alt=""
        />
      </div>
    </div>
  );
}
