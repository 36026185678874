import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Component } from "react";
import { Icon } from "@material-ui/core";

export class OnOverflowModal extends Component {
    render() {
        return (
            <div>
                <Modal
                    {...this.props}
                    
                    centered
                    aria-labelledby='contained-modal-title-vcenter'
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="titulo">Atención</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><Icon className="icons">warning</Icon>{this.props.text}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' className="btn-gris" onClick={this.props.onHide}>
                            Volver
            </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
