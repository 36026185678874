import EquipmentType from "../../../../utils/Constants/EquipmentType.js";
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/esm/Row";
import { Icon } from "@material-ui/core";

export default function VideoInfo(props) {
  return (
    <>
      {props.state.equipmentSelected &&
      props.state.equipmentSelected.equipmentTypeId ===
        EquipmentType.SEMAFORO ? (
        <div className="info-video-equipment">
          <Row style={{ margin: 0 }}>
            <Col sm={12}>
              <p style={{ marginLeft: 0 }}>
                <Icon className="icons">event</Icon>Fecha:
                <b> {props.state.videoDate}</b>
              </p>
            </Col>
            <Col sm={6} style={{ paddingRight: "0" }}>
              <p style={{ marginLeft: 0 }}>
                <Icon className="icons">broken_image</Icon>N° de serie:
                <b> {props.state.equipmentSelected.serialNumber}</b>
              </p>
            </Col>
            <Col sm={6} style={{ paddingLeft: "0" }}>
              <p style={{ marginLeft: 0 }}>
                <Icon className="icons">ondemand_video</Icon>
                ID del video:
                <b> {props.state.videoId}</b>
              </p>
            </Col>
            <Col sm={12}>
              <p style={{ marginLeft: 0 }}>
                <Icon className="icons">location_on</Icon>
                Ubicación:
                <b>
                  {" "}
                  {props.state.equipmentSelected.street} <span>&nbsp;</span>{" "}
                  {props.state.equipmentSelected.streetNumber
                    ? props.state.equipmentSelected.streetNumber
                    : ""}
                  , {props.municipalityName}{" "}
                </b>
              </p>
            </Col>
          </Row>
        </div>
      ) : props.state.equipmentSelected &&
        props.state.equipmentSelected.Municipality ? (
        <div className="info-video-equipment">
          <Col sm={12}>
            <p style={{ marginLeft: 0 }}>
              <Icon className="icons">location_on</Icon>
              Ubicación:
              <b>
                {" "}
                {props.state.equipmentSelected.street} <span>&nbsp;</span>{" "}
                {props.state.equipmentSelected.streetNumber
                  ? props.state.equipmentSelected.streetNumber
                  : ""}
                , {props.state.equipmentSelected.Municipality?.name ?? " - "}{" "}
              </b>
            </p>
          </Col>
        </div>
      ) : null}
    </>
  );
}
