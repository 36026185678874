import React from "react";

import Modal from "react-bootstrap/Modal";

export default class ImageListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalActive: false,
    };

    this.onClick = this.onClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }
  onClick(i) {
    this.props.removeIndexFromQueue(this.props.imageIndex);
  }
  handleClose() {
    this.setState({
      modalActive: false,
    });
  }
  handleOpen() {
    this.setState({
      modalActive: true,
    });
  }
  render() {
    if (this.state.modalActive) {
      return (
        <Modal show={this.state.modalActive} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Vista previa</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img alt="" src={this.props.src} className="cropped-img-preview" />
          </Modal.Body>
        </Modal>
      );
    } else {
      return this.props.imageIndex === 3 ? (
        <div className="img-infraccion">
          <div
            className={`image-list-item-${this.props.imageIndex} image-list-item-container cont-prev`}
          >
            <img
              className="img-list-preview"
              onClick={this.handleOpen}
              src={this.props.src}
              alt="patente"
            />

            <div onClick={this.onClick} className="image-list-close-button">
              {this.props.imageIndex === this.props.totalImages - 1 ? (
                <span role="img" aria-labelledby="delete">
                  <img
                    src={require("../../../../../assets/images/analyzer/delete.png")}
                    className="delete-img-list"
                    alt="delete"
                  ></img>
                </span>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={`img-infraccion`}>
          <div
            className={`image-list-item-${this.props.imageIndex} image-list-item-container cont-prev`}
          >
            <img
              className="img-list-preview"
              onClick={this.handleOpen}
              src={this.props.src}
              alt="imagen infraccion"
            />

            <div onClick={this.onClick} className="image-list-close-button">
              {this.props.imageIndex === this.props.totalImages - 1 ? (
                <span role="img" aria-labelledby="delete">
                  <img
                    src={require("../../../../../assets/images/analyzer/delete.png")}
                    className="delete-img-list"
                    alt="delete"
                  ></img>
                </span>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}
