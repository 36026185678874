import React, { useState } from "react";
import "../TechnicianReview.scss";
import Button from "react-bootstrap/Button";
import { Row } from "react-bootstrap";
import { Switch, FormControlLabel } from "@material-ui/core";
import { RepairEquipmentModal } from "../../Modals/RepairEquipmentModal";

export default function SemaphSecondStep(props) {
    const { checkLoggedIn, history } = props;
    const [repairModal, setRepairModal] = useState(false)
    const id = props.match.params.id;

    const [checkList, setCheckList] = useState({
        checkedA: false,
        checkedB: false,
        checkedC: false,

    });

    const handleChange = (event) => {
        setCheckList({ ...checkList, [event.target.name]: event.target.checked });
    };

    const handleNext = () => {

        history.push(`/review/last/${id} `)

    }

    const handleCancel = () => {
        history.push(`/review/semaphore/first/${id} `)
    }

    const handleRemoveEquipment = () => {
        setRepairModal(true);
    }

    const closeModal = () => {
        setRepairModal(false)
    }


    const successRedirect = () => {
        setRepairModal(false);
        history.push(`/reviews/today`)
    }

    const errorRedirect = () => {
        setRepairModal(false);
        history.push(`/error`)

    }

    return (
        <div className="review">
            <div className='review-technician '>
                <div className="header-rtp">
                    <h1>Revisión #{id}</h1>
                </div>
                <div className="step-description">
                    <p>2. Chequear video</p>
                </div>
                <div className="checklist">
                    <div className="checklist-item">
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={handleChange}
                                    name="checkedA"
                                    color="primary"
                                />
                            }
                            label="Chequear pulso"
                        />

                    </div>

                    <div className="checklist-item">
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Chequear que el programa se inicie correctamente"
                        />
                    </div>

                    <div className="checklist-item">
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={handleChange}
                                    name="checkedC"
                                    color="primary"
                                />
                            }
                            label="Chequear horarios"
                        />
                    </div>


                </div>
                <div className="retirar-equipo">
                    <p>¿No puede solucionar el problema?</p>
                    <Button className="btn-rojo"
                        variant="danger"
                        onClick={handleRemoveEquipment}>Retirar equipo</Button>
                </div>
                <Row>
                    <div className="btn-container">
                        <Button className="btn-celeste"
                            onClick={handleNext}
                            disabled={!checkList.checkedA || !checkList.checkedB || !checkList.checkedC}>
                            Siguiente</Button>
                        <Button variant='secondary' className="btn-gris"
                            onClick={handleCancel}>Atrás</Button>
                    </div>
                </Row>
                <RepairEquipmentModal
                    reviewId={id}
                    onHide={closeModal}
                    successRedirect={successRedirect}
                    errorRedirect={errorRedirect}
                    show={repairModal}
                    checkLoggedIn={checkLoggedIn}
                    history={props.history}
                ></RepairEquipmentModal>
            </div>
        </div>
    );
}

