import React from "react";
import { Modal, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";

export default function ChangeConfigModal(props) {
  const { history, onChangeMunicipality, municipalityId, ...rest } = props;

  return (
    <div>
      <Modal {...rest} centered aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="review-details">
            <h3>¿Qué municipio desea editar?</h3>

            <div className="body-rd">
              <Form style={{ minWidth: "370px" }}>
                <Form.Group as={Col} controlId="municipality" className="mb-0">
                  <Form.Label style={{ alignSelf: "flex-end" }}>
                    Municipio seleccionado:
                  </Form.Label>
                  <Form.Control
                    required
                    name="municipality"
                    as="select"
                    value={props.municipalityId}
                    onChange={(e) => {
                      props.onChangeMunicipality(e);
                      props.onHide();
                    }}
                  >
                    <option hidden value="">
                      Seleccione...
                    </option>
                    {props.municipalities.map((municipality, index) => (
                      <option key={index} value={municipality.municipalityId}>
                        {municipality.name}
                      </option>
                    ))}
                    {/* <option disabled style={{ color: '#dedede' }}>──────────</option>
                                        <option value={0}>Sin especificar</option> */}
                  </Form.Control>
                </Form.Group>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
