import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../Review.scss";
import MaterialTable from "material-table";
import { Button, Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ReviewDetailsModal } from "../Modals/ReviewDetailsModal";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Moment from "moment";

export default function ReviewRecordsT(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { checkLoggedIn } = props;
  const [reviews, setReviews] = useState([]);
  const history = useHistory();
  const [reviewSelected, setReviewSelected] = useState({});
  const [showReviewDetailsModal, setShowReviewDetailsModal] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [municipalities, setMunicipalities] = useState([]);

  const [filter, setFilter] = useState({
    dateFrom: "",
    dateTo: "",
  });

  const handleChange = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  const setDataTable = (response) => {
    var aux;
    let array = [];
    setReviews((reviews.length = 0));
    response.map((review) => {
      let technicians = "";
      if (review.Technicians[0] && review.Technicians[1])
        technicians =
          review.Technicians[0].name +
          " " +
          review.Technicians[0].lastname +
          ", " +
          review.Technicians[1].name +
          " " +
          review.Technicians[1].lastname;
      else if (review.Technicians[0])
        technicians =
          review.Technicians[0].name + " " + review.Technicians[0].lastname;
      else technicians = "Error";
      let finishedAt = Moment(review.finishedAt).utc().format("DD/MM/YYYY");
      if (review.statusId !== 2) finishedAt = "-";

      let problemDescription = "";
      review.ReviewProblems.map((problem) => {
        if (problem.description !== "Otros") {
          problemDescription += problem.description + ", ";
        } else {
          problemDescription += review.otherProblemDescription;
        }
        return null;
      });

      let solutionDescription = "";
      review.ReviewSolutions.map((solution) => {
        if (solution.description !== "Otro") {
          solutionDescription += solution.description + ", ";
        } else {
          solutionDescription += review.otherSolutionDescription;
        }
        return null;
      });

      let postponedAt;
      if (review.postponedAt) {
        postponedAt = Moment(review.postponedAt).utc().format("DD/MM/YYYY");
      } else postponedAt = "-";

      aux = {
        Equipment: review.Equipment,
        ReviewProblems: review.ReviewProblems,
        Technicians: review.Technicians,
        id: "#" + review.reviewId,
        problemDescription: problemDescription,
        reasonPostponement: review.reasonPostponement,
        reviewId: review.reviewId,
        address: review.Equipment.street + " " + review.Equipment.streetNumber,
        status: review.Status.description,
        technicians: technicians,
        solutionDescription: solutionDescription,
        additionalComment: review.additionalComment,
        startedAt: Moment(review.startedAt).utc().format("DD/MM/YYYY"),
        finishedAt: finishedAt,
        postponedAt: postponedAt,
        municipality: review.Equipment.Municipality?.municipalityId ?? 0,
      };
      array.push(aux);
      return null;
    });

    setReviews(reviews.concat(array));
  };

  const handleCleanFilter = () => {
    setFilter({
      dateFrom: "",
      dateTo: "",
      statusId: "",
    });
  };

  const getMunicipalities = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get("/api/municipality", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setMunicipalities(response.data.municipalities);
    });
  };

  const getReviews = async () => {
    const id = props.userData.id;
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.put(
      "/api/review/reviewStatus/unrealized",
      {},
      {
        headers: { "auth-token": authToken },
      }
    ).then(async () => {
      await Axios.get(`/api/review/technician/all/${id}`, {
        headers: { "auth-token": authToken },
      }).then((response) => {
        setDataTable(response.data.reviews[0].Reviews);
        setIsLoading(false);
      });
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getReviews();
    getMunicipalities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event) => {
    setErrorDate(false);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();

    if (dateFromBiggerThanDateTo()) {
      event.preventDefault();
      event.stopPropagation();
      setErrorDate(true);
      return;
    }
    search();
  };

  const search = () => {
    const id = props.userData.id;
    const searchRecord = async () => {
      const authToken = await checkLoggedIn();
      if (!authToken) {
        return history.push("/");
      }
      await Axios.get(`/api/review/technician/${id}/searchReviews`, {
        params: {
          dateFrom: filter.dateFrom,
          dateTo: filter.dateTo,
        },
        headers: { "auth-token": authToken },
      }).then((response) => {
        setIsLoading(false);
        setDataTable(response.data.reviews[0].Reviews);
      });
    };
    setIsLoading(true);
    searchRecord();
  };

  const dateFromBiggerThanDateTo = () => {
    var isBigger = false;
    const dt = new Date(filter.dateTo);
    const df = new Date(filter.dateFrom);
    if (df > dt) {
      isBigger = true;
    }
    return isBigger;
  };

  const closeModal = () => {
    setShowReviewDetailsModal(false);
  };

  const handleMoreDetails = (rowData) => {
    setReviewSelected(rowData);
    setShowReviewDetailsModal(true);
  };

  return (
    <div className="statistics-container">
      <div className="statistics">
        <div className="header-user" style={{ marginBottom: "2rem" }}>
          <div className="text-header">
            <h1>Historial de revisiones</h1>
          </div>
          <div className="button-header">
            <Button className="btn-gris" href="/reviews/today">
              Volver
            </Button>
          </div>
        </div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="header">
              <Icon className="filter-icon">search</Icon>
              <h2> Filtros</h2>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Form id="form-filter" onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Fecha desde: </Form.Label>
                  <Form.Control
                    name="dateFrom"
                    type="date"
                    required
                    value={filter.dateFrom}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Fecha hasta: </Form.Label>

                  <Form.Control
                    name="dateTo"
                    type="date"
                    required
                    value={filter.dateTo}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              {errorDate ? (
                <span className="error">
                  'Fecha desde' no puede ser mayor a 'Fecha hasta'.
                </span>
              ) : null}
              <Button className="btn-celeste" type="submit">
                Buscar
              </Button>
              <Button
                color="secondary"
                className="btn-gris"
                onClick={handleCleanFilter}
              >
                Limpiar filtro
              </Button>
            </Form>
          </AccordionDetails>
        </Accordion>

        <MaterialTable
          style={{ marginTop: "2rem" }}
          columns={[
            { title: "Número de revisión", field: "id", filtering: false },
            { title: "Fecha propuesta", field: "startedAt", filtering: false },
            {
              title: "Fecha de postergación",
              field: "postponedAt",
              filtering: false,
            },
            {
              title: "Descripción del problema",
              field: "problemDescription",
              filtering: false,
            },
            {
              title: "Municipalidad",
              field: "municipality",
              lookup: municipalities.reduce(function (acc, cur, i) {
                acc[cur.municipalityId] = cur.name;
                return acc;
              }, {}),
            },
            { title: "Tecnicos a cargo", field: "technicians" },
            {
              title: "Estado",
              field: "status",
              lookup: {
                Pendiente: "Pendiente",
                Finalizada: "Finalizada",
                Postergada: "Postergada",
                "No realizada": "No realizada",
              },
              render: (rowData) => {
                return rowData.status === "Pendiente" ? (
                  <span className="iniciada status">{rowData.status}</span>
                ) : rowData.status === "Postergada" ? (
                  <span className="postergada status">{rowData.status}</span>
                ) : rowData.status === "No realizada" ? (
                  <span className="no-realizada status">{rowData.status}</span>
                ) : rowData.status === "Finalizada" ? (
                  <span className="finalizada status">{rowData.status}</span>
                ) : null;
              },
            },
          ]}
          data={reviews}
          isLoading={isLoading}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
            },
            toolbar: {
              nRowsSelected: "{0} fila(s) seleccionadas",
            },
            header: {
              actions: " ",
            },
            body: {
              emptyDataSourceMessage: "No hay registros para mostrar",
              filterRow: {
                filterTooltip: "Filtro",
              },
            },
          }}
          actions={[
            {
              icon: "info",
              tooltip: "Detalle",
              onClick: (event, rowData) => handleMoreDetails(rowData),
            },
          ]}
          title=" "
          options={{
            actionsColumnIndex: -1,
            filtering: true,
          }}
        />
      </div>
      <img
        className="wave"
        src={require("../../../../assets/images/waves.png")}
        alt=""
      />
      {/* <img
                className='background-top'
                src={require("../../../assets/images/supervisor/review.png")}
                alt=''
            /> */}
      <ReviewDetailsModal
        show={showReviewDetailsModal}
        onHide={closeModal}
        review={reviewSelected}
      />
    </div>
  );
}
