import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Users.scss";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AreYouSureModal } from "../../utils/Modals/AreYouSureModal";
import { SuccessModal } from "../../utils/Modals/SuccessModal";

export default function Users(props) {

    const [isLoading, setIsLoading] = useState(false);
    const { checkLoggedIn } = props;
    const [users, setUsers] = useState([]);
    const history = useHistory();
    const [showAreYouSureModal, setAreYouSureModal] = useState(false);
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [userSelected, setUserSelected] = useState({});
    const [roles, setRoles] = useState([]);

    const setDataTable =(response) => {
        var aux;
        let array = [];
        response.users.forEach((user) => {
            aux = {
                userId: user.id,
                name: user.name + " " + user.lastname,
                email: user.email,
                phone: user.phone,
                address: user.address,
                city: user.city,
                CBU: user.CBU,
                CUIL: user.CUIL,
                roleId: user.roleId
            };

            array.push(aux);
        })
        setUsers(users.concat(array));
    };

    const getUsers = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get("/api/user", {
            headers: { "auth-token": authToken },
        }).then((response) => {
            setDataTable(response.data);
            setIsLoading(false);
        });
    };

    const getRoles = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get("/api/roles", {
            headers: { "auth-token": authToken },
        }).then((response) => {
            setRoles(response.data.roles);
            setIsLoading(false);
        });
    };

    useEffect(() => {      
        setIsLoading(true);
        getUsers();
        getRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleConfirmation = () => {
        setAreYouSureModal(false);

        const deleteUser = async () => {
            const authToken = await checkLoggedIn();
            if (!authToken) {
                return history.push("/");
            }
            await Axios.delete(`/api/user/${userSelected.userId}`, {

                headers: { "auth-token": authToken },
            }).then((response) => {
                setConfirmationModal(true);


            }).catch((err) => {
                setIsLoading(false);

            });
        };
        deleteUser();
    }

    const closeModal = () => {

        setAreYouSureModal(false);
        if (showConfirmationModal) {
            setConfirmationModal(false);
            window.location.reload(false)
        }

    }
    const handleEdit = (rowData) => {
        history.push(
            `/edit/${rowData.userId} `)

    }
    const handleDelete = (rowData) => {
        setAreYouSureModal(true);
        setUserSelected(rowData);
    }

    const handleStatistics = (rowData) => {
        history.push(`/statistics/${rowData.userId} `);
    }


    return (
        <div className='statistics-container' >
            <div className='statistics'>
                <div className="header-user">
                    <div className="text-header">
                        <h1>Obtené información sobre los usuarios del sistema</h1>
                        <h2>Podés agregar, editar y/o eliminar un usuario</h2>
                    </div>

                    <div className="button-header">
                        <Button className='btn-celeste' href="/register" >Registrá un usuario</Button>
                    </div>
                </div>

                <div className='header'>
                    <h1>Usuarios</h1>
                </div>
                <MaterialTable
                    columns={[
                        { title: "Nombre", field: "name", filtering: false },
                        { title: "Email", field: "email" , filtering: false, cellStyle: { minWidth: 290 }, },
                        { title: "Teléfono", field: "phone", filtering: false },
                        { title: "Dirección", field: "address" , filtering: false },
                        { title: "CUIL", field: "CUIL" , filtering: false },
                        { title: "Rol", field: "roleId", 
                        lookup: roles.reduce(function (acc, cur, i) {
                            acc[cur.roleId] = cur.roleName;
                            return acc;
                        }, {})
                    },
                    ]}
                    data={users}
                    isLoading={isLoading}
                    localization={{
                        pagination: {
                            labelDisplayedRows: '{from}-{to} de {count}'
                        },
                        toolbar: {
                            nRowsSelected: '{0} fila(s) seleccionadas'
                        },
                        header: {
                            actions: ' '
                        },
                        body: {
                            emptyDataSourceMessage: 'No hay registros para mostrar',
                            filterRow: {
                                filterTooltip: 'Filtro'
                            }
                        }
                    }}
                    actions={[

                        {
                            icon: 'edit',
                            tooltip: 'Editar',
                            onClick: (event, rowData) => handleEdit(rowData)
                        },

                        {
                            icon: 'delete',
                            tooltip: 'Eliminar',
                            onClick: (event, rowData) => handleDelete(rowData)
                        },
                        rowData => (
                            {
                                icon: 'insights',
                                tooltip: 'Ver estadísticas',
                                hidden: rowData.roleId !== 2,
                                onClick: (event, rowData) => handleStatistics(rowData)
                            }),   

                    ]}

                    title=' '
                    options={{
                        actionsColumnIndex: -1,
                        filtering: true,
                    }}
                />
            </div>
            <img
                className='wave'
                src={require("../../../assets/images/waves.png")}
                alt=''
            />
            <img
                className='background-top'
                src={require("../../../assets/images/usuariosFondo.png")}
                alt=''
            />
            <AreYouSureModal
                show={showAreYouSureModal}
                handleConfirmation={handleConfirmation}
                onHide={closeModal}
                text={"Esta seguro que desea eliminar este usuario?"}
            />
            <SuccessModal
                show={showConfirmationModal}
                onHide={closeModal}
                text={"Se ha eliminado con exito el usuario"}
            ></SuccessModal>
        </div>

    );

}
