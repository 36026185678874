import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import Moment from 'moment';
import Axios from "axios";
import LoaderModal from "../../../utils/Loader/LoaderModal";

export function RepairEquipmentModal(props) {
    const [isLoading, setIsLoading] = useState(false);
    const { checkLoggedIn, history, reviewId } = props;
    const [repair, setRepair] = useState({
        reviewId: reviewId,
        startedAt: Moment(Date.now()).format("YYYY-MM-DD"),
        equipmentId: 0,
        statusId: 1,
        reason: "",
        repairPlace: "",
    })
    
    const reviewSelected = {
        statusId: 2,
        finishedAt: Moment(Date.now()).format("YYYY-MM-DD"),
        reviewId:reviewId
    };



    const getReview = async () => {
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        await Axios.get(`/api/review/${reviewId}`, {
            headers: { "auth-token": authToken },
        }).then((response) => {
            setRepair({
                ...repair,
                equipmentId: response.data.review.equipmentId
            })

        });
    };

    const updateReview = async () => {
        setIsLoading(true)
        const authToken = await checkLoggedIn();
        if (!authToken) {
            return history.push("/");
        }
        const solutionsChecked = {'0': true}
        const info = { reviewSelected, solutionsChecked, repair }
        await Axios.put(`/api/review/${reviewId}`, info, {
            headers: { "auth-token": authToken },
        }).then(() => {
            setIsLoading(false)
            props.successRedirect();
        }).catch((err) => {
            setIsLoading(false)
            history.errorRedirect()
        });

    };

    const handleRepairChange = (event) => {
        setRepair({
            ...repair,
            [event.target.name]: event.target.value,
        })

    }

    useEffect(() => {
        getReview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <LoaderModal show={isLoading}></LoaderModal>
                    <Row>
                                <Form.Group as={Col} sm={12}>
                                    <Form.Label>¿Por qué va a retirar el equipo? *</Form.Label>
                                    <Form.Control
                                        name='reason'
                                        as='textarea'
                                        required
                                        rows='3'
                                        type='text'
                                        onChange={handleRepairChange}
                                    />

                                </Form.Group>
                                <Form.Group as={Col}  sm={12}>
                                    <Form.Label>¿A dónde se va a llevar el equipo? Incluir dirección completa *</Form.Label>
                                    <Form.Control
                                        name='repairPlace'
                                        as='textarea'
                                        required
                                        rows='3'
                                        type='text'
                                        onChange={handleRepairChange}
                                    />

                                </Form.Group>
                            </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        className='btn-gris'
                        onClick={props.onHide}
                    >
                        Cancelar
                         </Button>
                    <Button
                        variant='danger'
                        className='btn-rojo'
                        onClick={updateReview}
                    >
                        Aceptar
                         </Button>

                </Modal.Footer>
            </Modal>
        </div>
    );

}
