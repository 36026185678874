import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../Review.scss";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Icon } from "@material-ui/core";
import Loader from "../../../utils/Loader/Loader";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GoogleApiWrapper from "../../../utils/Maps/GoogleApiWrapper";

export default function ReviewFirstStep(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [municipalities, setMunicipalities] = useState([]);
  const { checkLoggedIn, history } = props;
  const [equipments, setEquipments] = useState([]);
  const [equipmentSelected, setEquipmentSelected] = useState("");
  const [firstVisit, setFirstVisit] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [filter, setFilter] = useState({
    municipalityId: "",
    equipmentTypeId: "",
    isWorking: "",
  });

  const setMarkersData = (equipments) => {
    let aux;
    let array = [];
    setMarkers((markers.length = 0));
    equipments.forEach((equipment) => {
      const coordinates = equipment.coordinates.split(",");

      aux = {
        lat: coordinates[0],
        long: coordinates[1],
        title: equipment.equipmentId,
      };
      array.push(aux);
    });
    setMarkers(markers.concat(array));
  };

  const handleCancel = () => {
    history.push("/review");
  };

  const handleNext = () => {
    history.push(`/review/start/${equipmentSelected}`);
  };

  const handleChange = (event) => {
    setFilter({
      ...filter,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const getEquipments = async () => {
      const authToken = await checkLoggedIn();
      if (!authToken) {
        return history.push("/");
      }
      await Axios.get("/api/equipment/searchEquipment", {
        params: {
          municipalityId: filter.municipalityId,
          equipmentTypeId: filter.equipmentTypeId,
          isWorking: filter.isWorking,
        },

        headers: { "auth-token": authToken },
      }).then((response) => {
        setEquipments(response.data.equipments);
        setMarkersData(response.data.equipments);
        setFirstVisit(false);
        setIsLoading(false);
      });
    };
    setIsLoading(true);
    getEquipments();
  };

  const handleSelectEquipment = (event, equipment) => {
    setEquipmentSelected(equipment.equipmentId);
  };

  const handleCleanFilter = () => {
    setFilter({
      municipalityId: "",
      equipmentTypeId: "",
      isWorking: "",
    });
  };

  const getMunicipalities = async () => {
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get("/api/municipality/", {
      headers: { "auth-token": authToken },
    }).then((response) => {
      setMunicipalities(response.data.municipalities);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getMunicipalities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="review">
      <div className="review-container">
        <Loader show={isLoading} />
        <div className="header">
          <div className="text-header">
            <h1>Dar de alta una revisión</h1>
          </div>
        </div>
        <div className="step-description">
          <p>1. Seleccioná un equipo para hacerle una revisión</p>
        </div>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="header-filter">
              <Icon className="filter-icon">search</Icon>
              <h2> Filtros</h2>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Form id="form-filter" onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Tipo de equipo</Form.Label>
                  <Form.Control
                    name="equipmentTypeId"
                    as="select"
                    value={filter.equipmentTypeId}
                    onChange={handleChange}
                  >
                    <option hidden value="">
                      Selecciona...
                    </option>
                    <option value="1">Velocidad</option>
                    <option value="2">De semáforo</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} md={2}>
                  <Form.Label>Municipalidad</Form.Label>
                  <Form.Control
                    name="municipalityId"
                    as="select"
                    value={filter.municipalityId}
                    onChange={handleChange}
                  >
                    <option hidden value="">
                      Selecciona...
                    </option>

                    {municipalities.map((municipality, index) => (
                      <option key={index} value={municipality.municipalityId}>
                        {municipality.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Estado del equipo</Form.Label>
                  <Form.Control
                    name="isWorking"
                    as="select"
                    value={filter.isWorking}
                    onChange={handleChange}
                  >
                    <option hidden value="">
                      Selecciona...
                    </option>
                    <option value="1">Funciona</option>
                    <option value="0">No funciona</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Button className="btn-celeste" type="submit">
                Buscar
              </Button>
              <Button
                variant="secondary"
                className="btn-gris"
                onClick={handleCleanFilter}
              >
                Limpiar filtro
              </Button>
            </Form>
          </AccordionDetails>
        </Accordion>
        {equipments.length !== 0 ? (
          <Row>
            <Col md={6} sm={12}>
              <div className="equipment-wrapper">
                <div className="equipment-list">
                  {equipments.map((equipment) => (
                    <div
                      className="equipment-item"
                      key={equipment.equipmentId}
                      style={{
                        backgroundColor:
                          equipmentSelected === equipment.equipmentId
                            ? "#9FB9FF"
                            : "",
                      }}
                      onClick={(event) =>
                        handleSelectEquipment(event, equipment)
                      }
                    >
                      <Icon
                        className="icon-equip"
                        style={{
                          color: equipment.isWorking ? "#43A047" : "#E53935",
                        }}
                      >
                        location_on
                      </Icon>
                      <div>
                        <h3>
                          {equipment.brand} {equipment.model}{" "}
                        </h3>
                        <p>
                          {equipment.street} {equipment.streetNumber},{" "}
                          {equipment.Municipality?.name ?? " - "}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col md={6} sm={12}>
              {markers.length !== 0 ? (
                <div style={{ minHeight: "22rem" }}>
                  <GoogleApiWrapper
                    markers={markers}
                    zoom={10}
                    width={"95%"}
                    setEquipmentSelected={setEquipmentSelected}
                  ></GoogleApiWrapper>
                </div>
              ) : null}
            </Col>
            <Col md={6}>
              <div className="references-equipment">
                <div
                  className="circle"
                  style={{ backgroundColor: "#43A047" }}
                ></div>
                <span className="description">Equipo funcionando</span>
                <div
                  className="circle"
                  style={{ backgroundColor: "#E53935" }}
                ></div>
                <span className="description"> Equipo no funciona</span>
              </div>
            </Col>
          </Row>
        ) : firstVisit ? (
          <div className="missing-info">
            <img
              className="fs-r"
              src={require("../../../../assets/images/supervisor/search.png")}
              alt=""
            />
            Obtenga el listado de equipos seleccionando los distintos criterios
            de búsqueda.
          </div>
        ) : (
          <div className="missing-info">
            <img
              className="fs-r"
              src={require("../../../../assets/images/admin/sadface.png")}
              alt=""
            />
            No hay equipos según los criterios de búsqueda seleccionados.
          </div>
        )}

        <Row>
          <div className="btn-container">
            <Button
              className="btn-celeste"
              onClick={handleNext}
              disabled={!equipmentSelected}
            >
              Siguiente
            </Button>
            <Button
              variant="secondary"
              className="btn-gris"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
          </div>
        </Row>
      </div>
      <img
        className="wave-right"
        src={require("../../../../assets/images/supervisor/waves-right.png")}
        alt=""
      />
    </div>
  );
}
