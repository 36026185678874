import React, { useEffect, useState } from "react";
import Axios from "axios";
import "./Record.scss";
import MaterialTable from "material-table";
import Moment from "moment";
import Loader from "../../../utils/Loader/Loader";
import { Button, Icon } from "@material-ui/core";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Pie } from "react-chartjs-2";

export default function Record(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState({
    dateFrom: Moment().startOf("month").format("YYYY-MM-DD"),
    dateTo: Moment(Date.now()).format("YYYY-MM-DD"),
  });
  const options = {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      position: "right",
      labels: {
        boxWidth: 10,
      },
    },
  };

  const [dailyData, setDailyData] = useState({
    imputables: 0,
    notImputables: 0,
    money: 0,
    disapproved: 0,
  });

  const [dataInfractions, setDataInfractions] = useState([]);
  const [dataChartInfractions, setDataChartInfractions] = useState({});
  const [minTime, setMinTime] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const { checkLoggedIn, history } = props;
  const [selectedRow, setSelectedRow] = useState(null);
  const [errorDate, setErrorDate] = useState(false);

  const setDataTable = (response) => {
    setDataInfractions((dataInfractions.length = 0));
    var aux;
    let array = [];
    response.historialPerDay = JSON.parse(response.historialPerDay);

    response.historialPerDay.forEach((infraction) => {
      aux = {
        date: Moment(infraction[0]).format("DD/MM/YYYY"),
        imputables: infraction[1].imputable,
        notImputables: infraction[1].notImputable,
        timeAverage: Math.round(infraction[1].timeAverage).toFixed(0),
        disapproval: infraction[1].disapproval,
        total: Math.round(infraction[1].total).toFixed(0),
      };

      array.push(aux);
    });

    setDataInfractions(dataInfractions.concat(array));
  };

  const setTimeData = (response) => {
    var maxTime, minTime;
    response.times.maxTime.forEach((time) => {
      maxTime = Math.round(time.maxTime).toFixed(0);
    });

    response.times.minTime.forEach((time) => {
      minTime = Math.round(time.minTime).toFixed(0);
    });

    getTimeInSegOrMin(minTime, maxTime);
  };

  const getInfractions = async () => {
    const id = props.match.params.userId;
    const authToken = await checkLoggedIn();
    if (!authToken) {
      return history.push("/");
    }
    await Axios.get(`/api/statistics/record/${id}`, {
      params: {
        dateFrom: date.dateFrom,
        dateTo: date.dateTo,
      },
      headers: { "auth-token": authToken },
    }).then((response) => {
      setIsLoading(false);

      setDataTable(response.data);

      setTimeData(response.data);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getInfractions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTimeInSegOrMin = (min, max) => {
    if (min >= 60) {
      min = Math.round(min / 60).toFixed(0);
      setMinTime(min + " min");
    } else {
      setMinTime(min + " seg");
    }

    if (max >= 60) {
      max = Math.round(max / 60).toFixed(0);
      setMaxTime(max + " min");
    } else {
      setMaxTime(max + " seg");
    }
  };

  const handleOnRowClick = (event, rowData) => {
    setSelectedRow(rowData.tableData.id);

    let array = [];
    array.push(rowData.imputables);
    array.push(rowData.notImputables);
    array.push(rowData.disapproval);
    setDataChartInfractions({
      labels: ["Imputables", "No Imputables", "Presunciones desaprobadas"],
      datasets: [
        {
          data: array,
          backgroundColor: ["#E53935", "#FFB74D", "#AB47BC"],
          hoverBackgroundColor: ["#EF5350", "#FFA726", "#AB47BC"],
        },
      ],
    });
    let timeAverage;
    let timeReferenceAverage = "";
    if (rowData.timeAverage >= 60) {
      timeAverage = Math.round(rowData.timeAverage / 60).toFixed(0);
      timeReferenceAverage = " min";
    } else {
      timeAverage = Math.round(rowData.timeAverage).toFixed(0);
      timeReferenceAverage = " seg";
    }

    setDailyData({
      timeAverage: timeAverage + timeReferenceAverage,
      imputables: rowData.imputables,
      notImputables: rowData.notImputables,
      money: rowData.total,
      day: rowData.date,
      disapproval: rowData.disapproval,
    });
  };

  const handleSubmit = (event) => {
    setErrorDate(false);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();

    if (dateFromBiggerThanDateTo()) {
      event.preventDefault();
      event.stopPropagation();
      setErrorDate(true);
      return;
    }
    search();
  };

  const dateFromBiggerThanDateTo = () => {
    var isBigger = false;
    const dt = new Date(date.dateTo);
    const df = new Date(date.dateFrom);
    if (df > dt) {
      isBigger = true;
    }
    return isBigger;
  };

  const search = () => {
    const id = props.match.params.userId;
    const searchRecord = async () => {
      const authToken = await checkLoggedIn();
      if (!authToken) {
        return history.push("/");
      }
      await Axios.get(`/api/statistics/record/${id}`, {
        params: {
          dateFrom: date.dateFrom,
          dateTo: date.dateTo,
        },
        headers: { "auth-token": authToken },
      }).then((response) => {
        setIsLoading(false);
        setDataTable(response.data);
      });
    };
    setIsLoading(true);
    searchRecord();
  };

  const handleChange = (event) => {
    setDate({
      ...date,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="statistics-container">
      <Loader show={isLoading} />

      <div className="statistics">
        <div className="header-record">
          <h1>Historial</h1>
          <p>
            Hacé click en las filas de las tablas para ver estadísticas sobre
            ese día
          </p>
        </div>
        {selectedRow !== null ? (
          <div>
            <div className="header">
              <h1>{dailyData.day}</h1>
            </div>
            <div className="cards">
              <div className="card">
                <h5>Infracciones</h5>

                <Pie
                  data={dataChartInfractions}
                  width={300}
                  options={options}
                />
              </div>
              <div className="card">
                <div className="money-card">
                  <img
                    src={require("../../../../assets/images/analyzer/money.png")}
                    alt="money"
                  />
                  <div className="money-description">
                    <h5> $ {dailyData.money}</h5>
                    <div className="desc">
                      <p>{dailyData.imputables} imputables </p>
                      <p>{dailyData.notImputables} no imputables </p>
                      <p>{dailyData.disapproval} pres. desaprobadas </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card ">
                <h5>Tiempo promedio de analisis</h5>
                <div className="timer-card">
                  <img
                    className="time"
                    src={require("../../../../assets/images/analyzer/time.png")}
                    alt="time"
                  />
                  <div className="money-description">
                    <h5> {dailyData.timeAverage}</h5>
                    <div className="desc">
                      <p>Tiempo menor*: {minTime} </p>
                      <p>Tiempo mayor*: {maxTime}</p>
                    </div>
                  </div>
                </div>
                <p>*Con respecto a todos los analizadores</p>
              </div>
            </div>
          </div>
        ) : null}

        <Accordion style={{ marginBottom: "2rem" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="header">
              <Icon className="filter-icon">search</Icon>
              <h2> Filtros</h2>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Form id="form" onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Fecha desde: </Form.Label>
                  <Form.Control
                    name="dateFrom"
                    type="date"
                    required
                    value={date.dateFrom}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} md={2}>
                  <Form.Label>Fecha hasta: </Form.Label>

                  <Form.Control
                    name="dateTo"
                    type="date"
                    required
                    value={date.dateTo}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              {errorDate ? (
                <span className="error">
                  'Fecha desde' no puede ser mayor a 'Fecha hasta'.
                </span>
              ) : null}
              <Button className="btn-celeste" type="submit">
                Buscar
              </Button>
            </Form>
          </AccordionDetails>
        </Accordion>

        <MaterialTable
          columns={[
            { title: "Fecha", field: "date" },
            { title: "Cantidad imputables", field: "imputables" },
            { title: "Cantidad no imputables", field: "notImputables" },
            {
              title: "Cantidad presunciones desaprobadas",
              field: "disapproval",
            },
            {
              title: "Tiempo promedio de analisis (seg)",
              field: "timeAverage",
            },
          ]}
          data={dataInfractions}
          title="Infracciones"
          onRowClick={handleOnRowClick}
          options={{
            rowStyle: (rowData) => ({
              backgroundColor:
                selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
            }),
          }}
          localization={{
            pagination: {
              labelDisplayedRows: "{from}-{to} de {count}",
            },
            toolbar: {
              nRowsSelected: "{0} fila(s) seleccionadas",
            },
            header: {
              actions: " ",
            },
            body: {
              emptyDataSourceMessage: "No hay registros para mostrar",
              filterRow: {
                filterTooltip: "Filtro",
              },
            },
          }}
        />
      </div>

      <img
        className="wave"
        src={require("../../../../assets/images/waves.png")}
        alt=""
      />
    </div>
  );
}
